import React, {
  memo, useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import invariant from 'tiny-invariant';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import styles from './DroppableChecklist.module.css';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';

const DroppableChecklist = ({
  children, index, checklistId,
  droppableType,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [{ currentChecklists }] = useContext(GlobalContext);
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [shouldShowBorderTop, setShouldShowBorderTop] = useState(false);
  const [shouldShowBorderBottom, setShouldShowBorderBottom] = useState(false);

  useEffect(() => {
    const el = ref.current;
    invariant(el, 'Element ref not set');

    return combine(dropTargetForElements({
      element: el,
      onDragEnter: ({ source }) => {
        const isDraggedChecklistItem = source.data.type === 'checklistItem';

        if (isDraggedChecklistItem) {
          const isDroppableChecklistTop = droppableType === 'checklistTop';
          const isDroppableChecklistBottom = droppableType === 'checklistBottom';
          if (isDroppableChecklistTop) {
            setShouldShowBorderBottom(true);
            setShouldShowBorderTop(false);
          }
          if (isDroppableChecklistBottom) {
            setShouldShowBorderTop(true);
            setShouldShowBorderBottom(false);
          }
        }

        if (!isDraggedChecklistItem) {
          const isSourceIndexHigherThanDestIndex = source.data.index > index;
          const isSuperTop = droppableType === 'checklistSuperTop';
          const isSuperBottom = droppableType === 'checklistSuperBottom';

          const isSelfDrag = source.data.index === index;

          if (isSuperTop) {
            setShouldShowBorderBottom(true);
            setShouldShowBorderTop(false);
          }

          if (isSuperBottom) {
            setShouldShowBorderTop(true);
            setShouldShowBorderBottom(false);
          }
          if (isSelfDrag === false) {
            if (isSourceIndexHigherThanDestIndex && !isSuperBottom && !isSuperTop) {
              setShouldShowBorderTop(true);
              setShouldShowBorderBottom(false);
            }
            if (!isSourceIndexHigherThanDestIndex && !isSuperBottom && !isSuperTop) {
              setShouldShowBorderBottom(true);
              setShouldShowBorderTop(false);
            }
          }
        }
        setIsDraggedOver(true);
      },
      getData: ({ source }) => {
        const isSourceChecklistItem = source.data.type === 'checklistItem';
        const isDroppableTypeTop = droppableType === 'checklistTop';

        let finalIndex = index;

        if (isSourceChecklistItem && isDroppableTypeTop) finalIndex = 0;
        if (isSourceChecklistItem && !isDroppableTypeTop) {
          const checklist = currentChecklists.data.find((elem) => elem._id === checklistId);
          finalIndex = checklist.checklistItems.length;
        }
        return { index: finalIndex, type: droppableType, checklistId };
      },
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => {
        setIsDraggedOver(false);
      },
    }));
  }, [JSON.stringify(currentChecklists.data)]);

  const isBorderTop = isDraggedOver && shouldShowBorderTop;
  const isBorderBottom = isDraggedOver && shouldShowBorderBottom;
  return (
    <div
      ref={ref}
      style={{
        borderTop: isBorderTop && '2px solid black',
        borderBottom: isBorderBottom && '2px solid black',
      }}
      className={styles.container}
    >
      {children}
    </div>
  );
};

DroppableChecklist.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  checklistId: PropTypes.string.isRequired,
  droppableType: PropTypes.string.isRequired,
};

DroppableChecklist.defaultProps = {
  children: <></>,
};

export default memo(DroppableChecklist);
