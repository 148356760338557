import axios from 'axios';
import { cloneDeep } from 'lodash';
import { ApiConstants, ChecklistItemConstants } from '../constants';
import apiUtil, { getNextPage } from '../utilities/apiUtil';
import { ErrorException } from '../utilities/handleError';
import {
  getIndexItemByIDFromList, mergeObjectListAndRemoveDuplicate, updateListProperty, updateListSocket,
} from '../utilities/arrayUtil';
import { dispatchUpdateChecklists, setCurrentChecklists } from './ChecklistActions';
import { handleAxiosDeleteRefreshToken } from './CheckLoginActions';

const loadMoreChecklistItems = async ({
  companyId,
  cardId,
  teamId,
  checklistId,
  setCurrentChecklistItems,
  setPreviousChecklistItems,
  currentChecklistItems,
  limit = ChecklistItemConstants.limitChecklistItem,
}, dispatch) => {
  try {
    const page = getNextPage({
      data: currentChecklistItems.data,
      limitPerPage: limit,
    });

    const result = await apiUtil.get(ApiConstants.URL_V1.CHECKLIST_ITEMS(
      { cardId, checklistId },
    ), {
      params: {
        companyId,
        limit,
        page,
        teamId,
      },
    });

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentChecklistItems,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    // const mergedListData = {
    //   ...result?.data,
    //   data: mergedDataListData?.data,
    // };

    setPreviousChecklistItems(result?.data);
    // setCurrentChecklistItems(mergedListData);

    const updateChecklists = (currentChecklists) => {
      let checklist = currentChecklists.data.find((elem) => elem._id === checklistId);
      if (!checklist) return currentChecklists;

      checklist = {
        ...checklist,
        checklistItems: [...mergedDataListData?.data],
      };

      return updateListProperty({
        keyProperty: 'data',
        newData: checklist,
        currentList: currentChecklists,
        typeAction: ChecklistItemConstants.typeCallback.EDIT,
        reverse: false,
      });
    };

    dispatchUpdateChecklists({ updateChecklists }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

async function createChecklistItemApi({
  cardId, checklistId, companyId, teamId, checklistItemName,
}) {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.CHECKLIST_ITEMS({ cardId, checklistId }),
      {
        name: checklistItemName,
      },
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function editChecklistItemNameApi({
  cardId, checklistId, checklistItemId, companyId, teamId, checklistItemName,
}) {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.CHECKLIST_ITEM_NAME({ cardId, checklistId, checklistItemId }),
      {
        name: checklistItemName,
      },
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function updateCompleteStatusChecklistItemApi({
  cardId, checklistId, checklistItemId, companyId, teamId, status,
}) {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.CHECKLIST_ITEM_COMPLETE({ cardId, checklistId, checklistItemId }),
      {
        status,
      },
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function updateDateChecklistItemApi({
  cardId, checklistId, checklistItemId, companyId, teamId, payload,
}) {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.CHECKLIST_ITEM_DATE({ cardId, checklistId, checklistItemId }),
      payload,
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function moveChecklistItemApi({
  cardId, checklistItemId, sourceChecklistId, companyId, teamId, position, destChecklistId,
}) {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.CHECKLIST_ITEM_MOVE(
        { cardId, checklistId: sourceChecklistId, checklistItemId },
      ),
      {
        position,
        destCardChecklistId: destChecklistId,
      },
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const reorderChecklistItemPosition = ({
  sourceChecklistId, destChecklistId,
  checklistItemId,
  destPosition, sourcePosition,
}, dispatch) => {
  const updateChecklists = (currentChecklists) => {
    let newChecklists = currentChecklists.data;

    if (!sourceChecklistId
      || (destPosition === null || destPosition === undefined)
      || (sourcePosition === null || sourcePosition === undefined)
      || !destChecklistId
      || !checklistItemId
    ) {
      return currentChecklists || [];
    }

    const isSourceAndDestChecklistTheSame = sourceChecklistId === destChecklistId;

    let sourceChecklist = newChecklists.find((elem) => elem._id === sourceChecklistId);
    let destChecklist = newChecklists.find((elem) => elem._id === destChecklistId);

    const isSourceChecklistExist = sourceChecklist !== null || sourceChecklist !== undefined;
    const isDestChecklistExist = destChecklist !== null || destChecklist !== undefined;

    const checklistItem = sourceChecklist.checklistItems[sourcePosition];

    if (isSourceChecklistExist) {
      if (checklistItem) {
        const removedChecklistItems = updateListSocket({
          newData: checklistItem,
          currentList: sourceChecklist.checklistItems,
          typeAction: ChecklistItemConstants.typeCallback.DELETE,
          reverse: false,
        });

        sourceChecklist = {
          ...sourceChecklist,
          checklistItems: [...removedChecklistItems],
        };
      }
    }

    if (isDestChecklistExist) {
      const isDestPositionCanBeAdded = destPosition <= destChecklist.checklistItems.length;

      if (isDestPositionCanBeAdded && checklistItem) {
        const addedChecklistItems = updateListSocket({
          newData: checklistItem,
          currentList: isSourceAndDestChecklistTheSame
            ? sourceChecklist.checklistItems : destChecklist.checklistItems,
          typeAction: ChecklistItemConstants.typeCallback.NEW,
          reverse: false,
          destIndex: destPosition,
        });

        if (isSourceAndDestChecklistTheSame) {
          sourceChecklist = {
            ...sourceChecklist,
            checklistItems: [...addedChecklistItems],
          };
        } else {
          destChecklist = {
            ...destChecklist,
            checklistItems: [...addedChecklistItems],
          };
        }
      }
    }

    if (isSourceChecklistExist) {
      const editedListFromCurrentChecklists = updateListSocket({
        newData: sourceChecklist,
        currentList: newChecklists,
        typeAction: ChecklistItemConstants.typeCallback.EDIT,
        reverse: false,
      });
      newChecklists = [...editedListFromCurrentChecklists];
    }

    if (isDestChecklistExist && !isSourceAndDestChecklistTheSame) {
      const editedListToCurrentChecklists = updateListSocket({
        newData: destChecklist,
        currentList: newChecklists,
        typeAction: ChecklistItemConstants.typeCallback.EDIT,
        reverse: false,
      });
      newChecklists = [...editedListToCurrentChecklists];
    }

    const finalChecklists = {
      ...currentChecklists,
      data: newChecklists,
    };
    return cloneDeep(finalChecklists);
  };

  dispatchUpdateChecklists({ updateChecklists }, dispatch);
};

async function reorderChecklistItemThenMoveChecklistItemApi({
  cardId, sourceChecklistId,
  destChecklistId, checklistItemId,
  companyId, teamId, destPosition,
  sourcePosition, currentChecklists,
}, dispatch) {
  try {
    reorderChecklistItemPosition({
      sourceChecklistId,
      destChecklistId,
      checklistItemId,
      destPosition,
      sourcePosition,
    }, dispatch);

    // reorder api
    const result = await apiUtil.post(
      ApiConstants.URL_V1.CHECKLIST_ITEM_MOVE({
        cardId,
        checklistId: sourceChecklistId,
        checklistItemId,
      }),
      {
        position: destPosition,
        destCardChecklistId: destChecklistId,
      },
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    // reset position if error
    setCurrentChecklists({ currentChecklists }, dispatch);
    throw new ErrorException(error);
  }
}

async function deleteChecklistItemApi({
  cardId, checklistId, checklistItemId, companyId, teamId,
}) {
  try {
    const result = await axios.delete(`${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V1.CHECKLIST_ITEM({ cardId, checklistId, checklistItemId })}`, {
      withCredentials: true,
      headers: {
        Authorization: `jwt ${localStorage.getItem('token')}`,
      },
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    const newAuthToken = await handleAxiosDeleteRefreshToken(error);
    if (newAuthToken) {
      try {
        const result = await axios.delete(`${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V1.CHECKLIST_ITEM({ cardId, checklistId, checklistItemId })}`, {
          withCredentials: true,
          headers: {
            Authorization: `jwt ${localStorage.getItem('token')}`,
          },
          params: {
            companyId,
            teamId,
          },
        });

        return result;
      } catch (err) {
        throw new ErrorException(err);
      }
    }
    throw new ErrorException(error);
  }
}

/* Socket Stuff */

function incomingChecklistItem({
  checklistId, checklistItem, typeAction, keyProperty = 'data',
}, dispatch) {
  if (!checklistId || !checklistItem) return;

  const updateChecklists = (currentChecklists) => {
    let checklist = currentChecklists.data.find((elem) => elem._id === checklistId);
    if (!checklist) return currentChecklists;

    const isNew = typeAction === ChecklistItemConstants.typeCallback.NEW;
    const isUpdate = typeAction === ChecklistItemConstants.typeCallback.EDIT;
    const isDelete = typeAction === ChecklistItemConstants.typeCallback.DELETE;

    if (isNew) {
      const updatedItems = [...checklist.checklistItems, checklistItem];

      checklist = {
        ...checklist,
        checklistItems: [...updatedItems],
      };
    }

    if (isUpdate) {
      const newChecklist = updateListProperty({
        keyProperty: 'checklistItems',
        newData: checklistItem,
        currentList: checklist,
        typeAction: ChecklistItemConstants.typeCallback.EDIT,
        reverse: false,
      });

      checklist = newChecklist;
    }

    if (isDelete) {
      const updatedItems = checklist.checklistItems.filter(
        (elem) => elem._id !== checklistItem._id,
      );
      checklist = {
        ...checklist,
        checklistItems: [...updatedItems],
      };
    }

    return updateListProperty({
      keyProperty,
      newData: checklist,
      currentList: currentChecklists,
      typeAction: ChecklistItemConstants.typeCallback.EDIT,
      reverse: false,
    });
  };

  dispatchUpdateChecklists({ updateChecklists }, dispatch);
}

function incomingChecklistItemMove({
  checklistItem,
  sourceChecklistId,
  destChecklistId,
  position,
}, dispatch) {
  if (!sourceChecklistId || !checklistItem || !destChecklistId) return;
  const updateChecklists = (currentChecklists) => {
    let newChecklists = cloneDeep(currentChecklists);

    const indexSourceChecklist = getIndexItemByIDFromList(
      newChecklists.data, { _id: sourceChecklistId },
    );
    const indexDestChecklist = getIndexItemByIDFromList(
      newChecklists.data, { _id: destChecklistId },
    );

    const isSourceAndDestChecklistTheSame = indexSourceChecklist === indexDestChecklist;

    if (isSourceAndDestChecklistTheSame) {
      let sourceChecklist = cloneDeep(newChecklists.data[indexSourceChecklist]);
      const isDestPositionInsideChecklistItems = position <= sourceChecklist.checklistItems.length;
      if (sourceChecklist) {
        const isChecklistItemExist = sourceChecklist.checklistItems.find(
          (elem) => elem._id === checklistItem._id,
        );
        if (isChecklistItemExist) {
          const removedChecklistItems = updateListSocket({
            newData: checklistItem,
            currentList: sourceChecklist.checklistItems,
            typeAction: ChecklistItemConstants.typeCallback.DELETE,
            reverse: false,
          });

          sourceChecklist = {
            ...sourceChecklist,
            checklistItems: [...removedChecklistItems],
          };
        }
        if (isDestPositionInsideChecklistItems) {
          const addedChecklistItems = updateListSocket({
            newData: checklistItem,
            currentList: sourceChecklist.checklistItems,
            typeAction: ChecklistItemConstants.typeCallback.NEW,
            destIndex: position,
            reverse: false,
          });

          sourceChecklist = {
            ...sourceChecklist,
            checklistItems: [...addedChecklistItems],
          };

          const updatedChecklists = updateListProperty({
            keyProperty: 'data',
            newData: sourceChecklist,
            currentList: newChecklists,
            typeAction: ChecklistItemConstants.typeCallback.EDIT,
            reverse: false,
          });
          newChecklists = cloneDeep(updatedChecklists);
        }
      }
    } else {
      let sourceChecklist = cloneDeep(newChecklists.data[indexSourceChecklist]);
      let destChecklist = cloneDeep(newChecklists.data[indexDestChecklist]);

      if (sourceChecklist) {
        const isChecklistItemExist = sourceChecklist.checklistItems.find(
          (elem) => elem._id === checklistItem._id,
        );
        if (isChecklistItemExist) {
          const removedChecklistItems = updateListSocket({
            newData: checklistItem,
            currentList: sourceChecklist.checklistItems,
            typeAction: ChecklistItemConstants.typeCallback.DELETE,
            reverse: false,
          });

          sourceChecklist = {
            ...sourceChecklist,
            checklistItems: [...removedChecklistItems],
          };
          const updatedChecklists = updateListProperty({
            keyProperty: 'data',
            newData: sourceChecklist,
            currentList: newChecklists,
            typeAction: ChecklistItemConstants.typeCallback.EDIT,
            reverse: false,
          });
          newChecklists = cloneDeep(updatedChecklists);
        }
      }
      if (destChecklist) {
        const isDestPositionInsideChecklistItems = position <= destChecklist.checklistItems.length;

        if (isDestPositionInsideChecklistItems) {
          const addedChecklistItems = updateListSocket({
            newData: checklistItem,
            currentList: destChecklist.checklistItems,
            typeAction: ChecklistItemConstants.typeCallback.NEW,
            destIndex: position,
            reverse: false,
          });

          destChecklist = {
            ...destChecklist,
            checklistItems: [...addedChecklistItems],
          };

          const updatedChecklists = updateListProperty({
            keyProperty: 'data',
            newData: destChecklist,
            currentList: newChecklists,
            typeAction: ChecklistItemConstants.typeCallback.EDIT,
            reverse: false,
          });
          newChecklists = cloneDeep(updatedChecklists);
        }
      }
    }

    return cloneDeep(newChecklists);
  };

  dispatchUpdateChecklists({ updateChecklists }, dispatch);
}

export {
  loadMoreChecklistItems,
  createChecklistItemApi,
  incomingChecklistItem,
  editChecklistItemNameApi,
  deleteChecklistItemApi,
  updateCompleteStatusChecklistItemApi,
  moveChecklistItemApi,
  reorderChecklistItemThenMoveChecklistItemApi,
  incomingChecklistItemMove,
  updateDateChecklistItemApi,
};
