import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ModalRoute, ModalSwitch } from 'react-router-modal-gallery';
import { Modal } from 'react-bootstrap';
import styles from './ListCards.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import CardListItem from '../../../components/CardList/CardListItem/CardListItem';
import { useInfinityScrollHooks } from '../../../hooks/InfinityScrollHooks';
import handleLoadings from '../../../utilities/handleLoadings';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { MyTaskCardConstants } from '../../../constants';
import { MyTaskCardActions } from '../../../actions';
import { FeedbackLoadingMain } from '../../../components/UI_V2/Feedback/Loading';
import InfinityScroll from '../../../components/UI/InfinityScroll/InfinityScroll';
import CardDetailContainer from '../../CardDetailContainer/CardDetailContainer';
import ModalLinkNoDecor from '../../../components/ModalLinkNoDecor/ModalLinkNoDecor';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';
import { GlobalContext, initialState } from '../../../contexts/GlobalStateProvider';
import { useMyTaskCardSocket } from '../../../hooks/MyTaskCardHooks';
import FilterBar from '../../../components/Container/FilterBar/FilterBar';
import ListCardsSkeleton from './ListCardsSkeleton/ListCardsSkeleton';
import { getStoreFilterMyTaskCard, setStoreFilterMyTaskCard } from '../../../utilities/localStorage';
import { FeedbackDialogGeneral } from '../../../components/UI_V2/Feedback/Dialog';

const ListCards = ({
  showFilters,
  handleHideFilters,
  handleShowFilterActive,
  handleHideFilterActive,
  handleSetIsOverflow,
}) => {
  const [{
    user,
    currentMyTaskCardListAll,
    previousMyTaskCardListAll,
    teams,
    currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    companyId,
    cardId,
  } = params;

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loadings, setLoadings] = useState([]);

  const initialFilterMyTaskCard = getStoreFilterMyTaskCard(
    { companyId, userId: user._id },
  );

  const initialFilterTeams = initialFilterMyTaskCard?.filterTeams || teams.map((team) => team._id);
  const initialFilterSubscribers = initialFilterMyTaskCard?.filterSubscribers || [];
  const initialFilterLabels = initialFilterMyTaskCard?.filterLabels || [];
  const initialFilterTitle = initialFilterMyTaskCard?.filterTitle || '';
  const initialFilterDueDate = initialFilterMyTaskCard?.filterDueDate || null;

  const [filters, setFilters] = useState({
    filterTeams: initialFilterTeams,
    filterSubscribers: initialFilterSubscribers,
    filterLabels: initialFilterLabels,
    filterTitle: initialFilterTitle,
    filterDueDate: initialFilterDueDate,
  });

  useMyTaskCardSocket({
    companyId,
    userId: user?._id,
    view: 'listAll',
  }, dispatch);

  const isCardOpen = cardId !== undefined;
  const [itemSelected, setItemSelected] = useState(isCardOpen ? cardId : undefined);

  const { enqueueSnackbar } = useSnackbar();

  const getMoreLists = async () => {
    const result = await MyTaskCardActions.loadMoreMyTaskCardListAll({
      companyId,
      currentMyTaskCardListAll,
      filters,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    MyTaskCardActions.setPreviousMyTaskCardListAll({
      previousMyTaskCardListAll: initialState.previousMyTaskCardListAll,
    }, dispatch);
    MyTaskCardActions.setCurrentMyTaskCardListAll({
      currentMyTaskCardListAll: initialState.currentMyTaskCardListAll,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentMyTaskCardListAll,
    previousLists: previousMyTaskCardListAll?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: MyTaskCardConstants.limitMyTaskCardList,
    dontSorting: true,
  });

  const initiateMyTaskCardListByAllApi = async (filtersValue) => {
    try {
      const startLoadings = handleLoadings('initiateMyTaskCardListByAll', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await MyTaskCardActions.initiateMyTaskCardListAll({
        companyId,
        filters: filtersValue,
      }, dispatch);

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }

      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateMyTaskCardListByAll', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (!user?._id) return;
    initiateMyTaskCardListByAllApi(filters);
  }, []);

  const [
    showEditMenu,
    handleShowEditMenu,
    handleHideEditMenu,
  ] = useDelayShowHideHandler();

  const [
    showQuickComment,
    handleShowQuickComment,
    handleHideQuickComment,
  ] = useDelayShowHideHandler();

  const [
    showNote,
    handleShowNote,
    handleHideNote,
  ] = useDelayShowHideHandler();

  const [
    showName,
    handleShowName,
    handleHideName,
  ] = useDelayShowHideHandler();

  // const [
  //   showMove,
  //   handleShowMove,
  //   handleHideMove,
  // ] = useDelayShowHideHandler();

  // const [
  //   showMember,
  //   handleShowMember,
  //   handleHideMember,
  // ] = useDelayShowHideHandler();

  // const [
  //   showLabel,
  //   handleShowLabel,
  //   handleHideLabel,
  // ] = useDelayShowHideHandler();
  // const [
  //   showDueDate,
  //   handleShowDueDate,
  //   handleHideDueDate,
  // ] = useDelayShowHideHandler();

  const onApplyFilters = (filtersValue) => {
    setFilters(filtersValue);
    initiateMyTaskCardListByAllApi(filtersValue);
    setStoreFilterMyTaskCard({
      companyId,
      userId: user._id,
      filter: filtersValue,
    });
  };

  useEffect(() => {
    const {
      filterDueDate,
      filterLabels,
      filterSubscribers,
      filterTeams,
      filterTitle,
    } = filters;
    const isSearchFilterActive = filterTitle.length > 0;
    const isTeamsFilterActive = filterTeams.length !== teams.length;
    const isLabelsFilterActive = filterLabels.length > 0;
    const isMembersFilterActive = filterSubscribers.length > 0;
    const isDueDateFilterActive = filterDueDate;

    let isActive = false;
    if (isSearchFilterActive) isActive = true;
    if (isTeamsFilterActive) isActive = true;
    if (isLabelsFilterActive) isActive = true;
    if (isMembersFilterActive) isActive = true;
    if (isDueDateFilterActive) isActive = true;

    if (isActive) {
      handleShowFilterActive(true);
    } else {
      handleHideFilterActive(false);
    }
  }, [filters]);

  useEffect(() => {
    if (lists?.length > 3) {
      handleSetIsOverflow(true);
    } else {
      handleSetIsOverflow(false);
    }
  }, [lists]);

  return (
    <>
      <FeedbackLoadingMain
        wait="initiateMyTaskCardListByAll"
        loadings={loadings}
        loadingComponent={<ListCardsSkeleton />}
      >
        <InfinityScroll
          scrollableTarget="listMyTaskCardList"
          dataLength={lists?.length || 0}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          emptyMessage="Belum ada tugas disini..."
          marginBottomEndMessage={lists?.length < 14 ? '20rem' : undefined}
        >
          {lists.map((item) => (
            <CardListItem
              item={item}
              isSelected={itemSelected === item._id}
              setSelected={setItemSelected}
              showEditMenu={showEditMenu}
              handleShowEditMenu={handleShowEditMenu}
              handleHideEditMenu={handleHideEditMenu}
              showName={showName}
              handleShowName={handleShowName}
              handleHideName={handleHideName}
              showNote={showNote}
              handleShowNote={handleShowNote}
              handleHideNote={handleHideNote}
              showQuickComment={showQuickComment}
              handleShowQuickComment={handleShowQuickComment}
              handleHideQuickComment={handleHideQuickComment}
              enableCreateList={false}
              // showLabel={showLabel}
              // handleShowLabel={handleShowLabel}
              // handleHideLabel={handleHideLabel}
              // showMember={showMember}
              // handleShowMember={handleShowMember}
              // handleHideMember={handleHideMember}
              // showDueDate={showDueDate}
              // handleShowDueDate={handleShowDueDate}
              // handleHideDueDate={handleHideDueDate}
              // showMove={showMove}
              // handleShowMove={handleShowMove}
              // handleHideMove={handleHideMove}
            />
          ))}
        </InfinityScroll>
      </FeedbackLoadingMain>
      <ModalSwitch renderModal={({ open, redirectToBack }) => (
        <FeedbackDialogGeneral
          open={open}
          onClose={
          () => history.push(`/companies/${companyId}/my-tasks/list/all`)
          }
          maxWidth="md"
          disableCloseIcon
        >
          <ModalRoute
            defaultParentPath={`/companies/${companyId}/my-tasks/list/all`}
            path="/companies/:companyId/my-tasks/list/all/teams/:teamId/cards/:cardId"
            component={CardDetailContainer}
          />
        </FeedbackDialogGeneral>
      )}
      >
        <ModalRoute
          defaultParentPath={`/companies/${companyId}/my-tasks/list/all`}
          path="/companies/:companyId/my-tasks/list/all/teams/:teamId/cards/:cardId"
          component={CardDetailContainer}
        />
      </ModalSwitch>
      {showFilters
      && (
      <FilterBar
        showLabelSection={false}
        listTeams={teams}
        initialSelectedTeams={filters.filterTeams}
        listMembers={currentCompany.members}
        initialSelectedMembers={filters.filterSubscribers}
        showMemberSection
        showDueDateSection
        initialDueDate={filters.filterDueDate}
        showTeamSection
        onClose={handleHideFilters}
        onApplyFilters={onApplyFilters}
        loadingsOnApplyFilters={loadings}
        waitOnApplyFilters="initiateMyTaskCardListByAll"
        initialSearch={filters.filterTitle}
        barTitle="Filter Tugas"
        topPositionVariant="withBottomBar"
        invertResetTeamCondition
      />
      )}
    </>
  );
};

ListCards.propTypes = {
  showFilters: PropTypes.bool.isRequired,
  handleHideFilters: PropTypes.func.isRequired,
  handleShowFilterActive: PropTypes.func.isRequired,
  handleHideFilterActive: PropTypes.func.isRequired,
  handleSetIsOverflow: PropTypes.func.isRequired,
};

ListCards.defaultProps = {};

export default memo(ListCards);
