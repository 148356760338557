// eslint-disable-next-line operator-linebreak
const testerEmail =
  // eslint-disable-next-line no-useless-escape
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

const validateEmail = (email) => {
  if (!email) return false;

  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) return false;
  if (address.length > 255) return false;

  const domainParts = address.split('.');
  if (domainParts.some((part) => part.length > 63)) return false;

  if (!testerEmail.test(email)) return false;

  return email.toLowerCase();
};

function validatePassword(password) {
  // Minimum length of 8 characters
  const isLengthValid = password.length >= 8;

  // Maximum length of 30 characters
  const isMaxLengthValid = password.length < 31;

  // At least one number
  const hasNumber = /\d/.test(password);

  // At least one special character
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  // Check if all conditions are met
  // Check conditions and return result object
  if (isLengthValid && isMaxLengthValid && hasNumber && hasSpecialChar) {
    return { isValid: true, error: null };
  }
  const errors = [];
  if (!isLengthValid) errors.push('Password harus minimal 8 karakter');
  if (!isMaxLengthValid) errors.push('Password maksimal 30 karakter');
  if (!hasNumber) errors.push('Password harus mengandung minimal 1 angka');
  if (!hasSpecialChar) errors.push('Password harus mengandung karakter spesial (@!* dll)');

  return { isValid: false, errorMsg: errors.join(', ') };
}

function validateFullName(fullName) {
  // Check if each part contains only letters
  const isValidName = fullName.split(' ').every((part) => /^[a-zA-Z]+$/.test(part));

  const hasTrailingSpace = /\s+$/.test(fullName);

  // Maximum length of 30 characters
  const isMaxLengthValid = fullName.length < 31;

  if (isValidName && isMaxLengthValid) {
    return { isValid: true, error: null };
  }

  const errors = [];
  if (!isValidName && !hasTrailingSpace) errors.push('Nama hanya boleh huruf');
  if (!isValidName && hasTrailingSpace) errors.push('Nama gaboleh diakhiri spasi, tapi boleh diisi nama lengkap');
  if (!isMaxLengthValid) errors.push('Nama maksimal 30 karakter');

  return { isValid: false, errorMsg: errors.join(', ') };
}

function validateTitle(title) {
  // Allow any characters, but trim leading and trailing spaces
  const trimmedTitle = title.trim();

  // Maximum length of 50 characters
  const isMaxLengthValid = trimmedTitle.length <= 50;

  if (isMaxLengthValid) {
    return { isValid: true, error: null };
  }

  const errors = [];
  if (!isMaxLengthValid) errors.push('Title should be 50 characters or less');

  return { isValid: false, errorMsg: errors.join(', ') };
}

module.exports = {
  validateEmail,
  validatePassword,
  validateFullName,
  validateTitle,
};
