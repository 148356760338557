import React, {
  memo, useContext, useState, useEffect,
  useCallback,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useInView } from 'react-intersection-observer';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import invariant from 'tiny-invariant';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import styles from './ListChecklists.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import Checklist from './Checklist/Checklist';
import { ChecklistActions } from '../../../actions';
import { GlobalContext, initialState } from '../../../contexts/GlobalStateProvider';
import { ChecklistConstants } from '../../../constants';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { useInfinityScrollHooks } from '../../../hooks/InfinityScrollHooks';
import { FeedbackSpinnerGeneral } from '../../../components/UI_V2/Feedback/Spinner';
import InfinityScroll from '../../../components/UI/InfinityScroll/InfinityScroll';
import DroppableChecklist from './DroppableChecklist/DroppableChecklist';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../../components/UI_V2/Display/Text';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';
import CreateChecklist from '../CreateChecklist/CreateChecklist';
import DeleteChecklist from './Checklist/DeleteChecklist/DeleteChecklist';
import DeleteChecklistItem from './Checklist/ListChecklistItems/DeleteChecklistItem/DeleteChecklistItem';
import handleLoadings from '../../../utilities/handleLoadings';
import { reorderChecklistThenMoveChecklistApi } from '../../../actions/ChecklistActions';
import { reorderChecklistItemThenMoveChecklistItemApi } from '../../../actions/ChecklistItemActions';

const ListChecklists = ({
  setIsChecklistEmpty,
  isCheckListEmpty,
}) => {
  const [{
    currentChecklists, currentCard, previousChecklists, user,
  }, dispatch] = useContext(GlobalContext);
  const [allowedToExpandChecklistItems, setAllowedToExpandChecklistItems] = useState(true);
  const isMobile = useMediaQuery('(max-width:720px)');
  const [loadings, setLoadings] = useState([]);
  const [deletedChecklistId, setDeletedChecklistId] = useState();
  const [movedChecklistId, setMovedChecklistId] = useState();
  const [selectedChecklistIdForDeletedChecklistItemId,
    setSelectedChecklistIdForDeletedChecklistItemId] = useState();
  const [deletedChecklistItemId, setDeletedChecklistItemId] = useState();
  const [movedChecklistItemId, setMovedChecklistItemId] = useState();
  const [
    showCreateChecklist,
    handleShowCreateChecklist,
    handleHideCreateChecklist,
  ] = useDelayShowHideHandler();
  const [
    showDeleteChecklist,
    handleShowDeleteChecklist,
    handleHideDeleteChecklist,
  ] = useDelayShowHideHandler();
  const [
    showDeleteChecklistItem,
    handleShowDeleteChecklistItem,
    handleHideDeleteChecklistItem,
  ] = useDelayShowHideHandler();

  const params = useParams();

  const { companyId, cardId, teamId } = params;

  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteChecklist = (value) => {
    setDeletedChecklistId(value);
    handleShowDeleteChecklist();
  };
  const handleDeleteChecklistItem = (valueChecklistItemId, valueChecklistId) => {
    setDeletedChecklistItemId(valueChecklistItemId);
    setSelectedChecklistIdForDeletedChecklistItemId(valueChecklistId);
    handleShowDeleteChecklistItem();
  };

  const getMoreLists = async () => {
    const result = await ChecklistActions.loadMoreChecklists({
      currentChecklists,
      cardId,
      teamId,
      companyId,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    ChecklistActions.setPreviousChecklists({
      previousChecklists: initialState.previousChecklists,
    }, dispatch);
    ChecklistActions.setCurrentChecklists({
      currentChecklists: initialState.currentChecklists,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentChecklists,
    previousLists: previousChecklists?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: ChecklistConstants.limitChecklist,
    dontSorting: true,
    forceTriggerUpdateOnDataIndexOrderChange: true,
  });

  const initiateChecklistsApi = async () => {
    try {
      const result = await ChecklistActions.initiateChecklists({
        cardId,
        teamId,
        companyId,
      }, dispatch);

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (!user._id || !companyId || (currentCard?._id !== cardId)) {
      return;
    }

    initiateChecklistsApi();
  }, [user, companyId, currentCard?._id]);

  const handleMoveChecklistApi = async (source, destination) => {
    try {
      const { draggedChecklistId } = source.data;
      const sourcePosition = source.data.index;
      const destPosition = destination.data.index;

      setMovedChecklistId(draggedChecklistId);

      const result = await reorderChecklistThenMoveChecklistApi({
        cardId,
        companyId,
        teamId,
        checklistId: draggedChecklistId,
        sourcePosition,
        destPosition,
        currentChecklists,
      }, dispatch);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      setMovedChecklistId();
    }
  };

  const handleMoveChecklistItemApi = async (source, destination) => {
    try {
      const { draggedChecklistItemId } = source.data;
      const sourcePosition = source.data.index;
      const sourceChecklistId = source.data.checklistId;
      const destPosition = destination.data.index;
      const destChecklistId = destination.data.checklistId;

      setMovedChecklistItemId(draggedChecklistItemId);

      const result = await reorderChecklistItemThenMoveChecklistItemApi({
        cardId,
        companyId,
        teamId,
        sourceChecklistId,
        destChecklistId,
        destPosition,
        sourcePosition,
        checklistItemId: draggedChecklistItemId,
        currentChecklists,
      }, dispatch);

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      setMovedChecklistItemId();
    }
  };

  // need using observerintersection
  // for infinite scroll comment inside modal dialog
  const { ref, inView, entry } = useInView();
  useEffect(() => {
    if (inView) {
      if (checkIfListNeedToLoad()) {
        handleLoadMoreLists();
      }
    }
  }, [inView]);

  const isListEmpty = lists.length < 1;

  setIsChecklistEmpty(isListEmpty);

  useEffect(() => monitorForElements({
    onDrop({ source, location }) {
      const destination = location.current.dropTargets[0];
      if (!destination) {
        // if dropped outside of any drop targets
        return;
      }

      const isChecklist = source.data.type === 'checklistTop';

      if (isChecklist) handleMoveChecklistApi(source, destination);
      const isChecklistItem = source.data.type === 'checklistItem';

      if (isChecklistItem) handleMoveChecklistItemApi(source, destination);
    },
  }), []);

  return (
    <>
      {!isCheckListEmpty && (
        <DroppableChecklist
          index={0}
          checklistId={lists?.[0]?._id}
          droppableType="checklistSuperTop"
        >
          <div className={styles.titleAndAction}>
            <div className={styles.titleChecklist}>
              <DisplayTextSubHeadline mode="16">
                Ceklis
              </DisplayTextSubHeadline>
            </div>
            <div className={styles.titleAction}>
              <div className={styles.iconAction} onClick={handleShowCreateChecklist}>
                <AddIcon />
              </div>
              {showCreateChecklist && (
                <CreateChecklist
                  onClose={handleHideCreateChecklist}
                  cardId={cardId}
                  teamId={teamId}
                  companyId={companyId}
                  position="topLeft"
                />
              )}
            </div>
          </div>
        </DroppableChecklist>
      )}
      <div className={styles.container}>
        <InfinityScroll
                // scrollableTarget="listSidebar"
          dataLength={lists?.length}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          hideEndMessage
          loader={(<></>)}
        >
          {!isListEmpty && lists.map((checklist, index) => (
            <Checklist
              checklist={checklist}
              index={index}
              allowedToExpandChecklistItems={allowedToExpandChecklistItems}
              setAllowedToExpandChecklistItems={setAllowedToExpandChecklistItems}
              cardId={cardId}
              teamId={teamId}
              companyId={companyId}
              handleDeleteChecklist={handleDeleteChecklist}
              handleDeleteChecklistItem={handleDeleteChecklistItem}
              movedChecklistId={movedChecklistId}
              movedChecklistItemId={movedChecklistItemId}
            />
          ))}
          {checkIfListNeedToLoad() && (
          <div ref={ref} className={styles.infiniteScroll}>
            <FeedbackSpinnerGeneral size={30} color={Color.yellowAccentCicle} />
          </div>
          )}
        </InfinityScroll>
      </div>
      {!allowedToExpandChecklistItems && (
      <DroppableChecklist
        index={lists?.length - 1}
        checklistId={lists?.[lists?.length - 1]?._id}
        droppableType="checklistSuperBottom"
      >
        <div className={styles.footerChecklist} />
      </DroppableChecklist>
      )}
      <DeleteChecklist
        showDeleteChecklist={showDeleteChecklist}
        handleShowDeleteChecklist={handleShowDeleteChecklist}
        handleHideDeleteChecklist={handleHideDeleteChecklist}
        cardId={cardId}
        teamId={teamId}
        deletedChecklistId={deletedChecklistId}
        companyId={companyId}
      />
      <DeleteChecklistItem
        showDeleteChecklistItem={showDeleteChecklistItem}
        handleShowDeleteChecklistItem={handleShowDeleteChecklistItem}
        handleHideDeleteChecklistItem={handleHideDeleteChecklistItem}
        cardId={cardId}
        teamId={teamId}
        checklistId={selectedChecklistIdForDeletedChecklistItemId}
        deletedChecklistItemId={deletedChecklistItemId}
        companyId={companyId}
      />
    </>
  );
};

ListChecklists.propTypes = {
  setIsChecklistEmpty: PropTypes.func.isRequired,
  isCheckListEmpty: PropTypes.bool.isRequired,
};

ListChecklists.defaultProps = {};

export default memo(ListChecklists);
