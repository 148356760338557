import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import {
  useHistory, useParams, useLocation,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import TextareaAutosize from 'react-textarea-autosize';
import EmojiPicker from 'emoji-picker-react';
import MoodIcon from '@mui/icons-material/Mood';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TimePicker from '@mui/lab/TimePicker';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { actionTypes } from '../../reducers/reducer';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './CreateBlastPage.module.css';
import Title from '../../components/Title/Title';
import ManageSubscribersContainer from '../ManageSubscribersContainer/ManageSubscribersContainer';
import EditorFull from '../FroalaEditor/EditorFull/EditorFull';
import extractElementBySelectorFromString from '../../utilities/extractElementBySelectorFromString';
import handleLoadings from '../../utilities/handleLoadings';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import { BlastActions, SearchJumpToActions, TeamActions } from '../../actions';
import { PageConstants } from '../../constants';
import apiUtil from '../../utilities/apiUtil';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import { LayoutMain } from '../../components/UI_V2/Layout';
import { FeedbackLoadingMain } from '../../components/UI_V2/Feedback/Loading';
import { SurfacePaperMain } from '../../components/UI_V2/Surface/Paper';
import { InputSwitchMain } from '../../components/UI_V2/Input/Switch';
import { DisplayDividerMain } from '../../components/UI_V2/Display/Divider';
import Color from '../../themes/colors';
import { InputButtonMain } from '../../components/UI_V2/Input/Button';
import EditBlastPostPageSkeleton from '../EditBlastPostPage/Skeleton/Skeleton';
import { DisplayTextBody, DisplayTextSubHeadline } from '../../components/UI_V2/Display/Text';

function CreateBlastPage() {
  const [{
    user, currentTeam, currentBlast, currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const [subscribers, setSubscribers] = useState([]);
  const [newMessage, setNewMessage] = useState({ value: '' });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [postMessage, setPostMessage] = useState();
  const [loadings, setLoadings] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [editorModel, setEditorModel] = useState();
  const [trigger, setTrigger] = useState();
  const [isAccessPrivate, setAccessPrivate] = useState(false);
  const [dueDateOption, setDueDateOption] = useState('auto');
  const [autoCompleteCode, setAutoCompleteCode] = useState('1');

  const initialDueDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
  initialDueDate.setHours(initialDueDate.getHours() + 1);
  initialDueDate.setMinutes(0);
  const [selectedDueDate, setSelectedDueDate] = useState(initialDueDate);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { companyId, teamId, blastId } = params;

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    const isBlastIdTheSame = currentBlast?._id === blastId;
    const handleEndLoadings = () => {
      const endLoadings = handleLoadings('createBlastPostPage', [...loadings], 'end');
      setLoadings([...endLoadings]);
    };
    const startLoadings = handleLoadings('createBlastPostPage', [...loadings], 'start');
    setLoadings([...startLoadings]);

    const fetchApiBlastDetail = async () => {
      try {
        if (!isBlastIdTheSame) {
          await BlastActions.initiateBlast({ blastId, companyId, teamId }, dispatch);
          await TeamActions.initiateTeam({
            teamId,
            companyId,
            currentTeam,
          }, dispatch);
        }
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });

        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        if (!isBlastIdTheSame) {
          handleEndLoadings();
        } else {
          setTimeout(() => {
            handleEndLoadings();
          }, [100]);
        }
      }
    };

    fetchApiBlastDetail();
  }, []);

  const handleCancel = () => {
    history.push(`/companies/${companyId}/teams/${teamId}/blasts/${blastId}`);
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (postMessage === undefined) {
      return;
    }

    const postApiMessage = async () => {
      try {
        const startLoadings = handleLoadings('createBlastPost', [...loadings], 'start');
        setLoadings([...startLoadings]);

        const result = await apiUtil.post(`/api/v1/blasts/${blastId}/posts`, postMessage, {
          params: {
            companyId,
            teamId,
          },
        });

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        setTimeout(() => {
          handleCancel();
          const endLoadings = handleLoadings('createBlastPost', [...loadings], 'end');
          setLoadings([...endLoadings]);
        }, 1000);
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });

        const endLoadings = handleLoadings('createBlastPost', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    postApiMessage();
  }, [postMessage]);

  const onEmojiClick = (code, emoji) => {
    setNewMessage((prevValue) => {
      let newValue;
      if (prevValue.value !== '') {
        newValue = prevValue.value + emoji.emoji;
      } else {
        newValue = emoji.emoji;
      }
      return ({
        ...prevValue,
        value: newValue,
      });
    });
  };

  const handleChanged = (event) => {
    const { value } = event.target;

    setNewMessage((prevValue) => ({
      ...prevValue,
      value,
    }));
  };

  const handleShowEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const disableShowEmojiPicker = () => {
    setShowEmojiPicker(false);
  };

  const handleTriggerPostBlast = () => {
    setTrigger(Math.random());
  };

  const handlePostBlast = () => {
    const mentionedUsers = extractElementBySelectorFromString(editorModel, '#mentioned-user');

    const subscriberIds = [];

    subscribers.forEach((subscriber) => {
      subscriberIds.push(subscriber._id);
    });

    let autoCompleteData = {
      status: false,
    };
    let manualDueDateData = selectedDueDate;
    if (dueDateOption === 'auto') {
      const today = new Date();
      const autoCompleteDate = new Date(
        today.setUTCDate(today.getUTCDate() + Number(autoCompleteCode)),
      );
      autoCompleteData = {
        status: true,
        date: autoCompleteDate,
      };
      manualDueDateData = null;
    }

    setPostMessage({
      title: newMessage.value,
      content: editorModel,
      subscribers: [...subscriberIds],
      mentionedUsers,
      dueDate: manualDueDateData,
      isPublic: !isAccessPrivate,
      autoComplete: autoCompleteData,
    });
  };

  useEffect(() => {
    if (trigger === undefined) return;
    handlePostBlast();
  }, [trigger]);

  useEffect(() => {
    if (currentTeam.members === undefined) return;

    // set initial subscribers default is all team member
    const tempSubscribers = [];
    currentTeam.members.forEach((subscriber) => {
      tempSubscribers.push(subscriber);
    });

    setSubscribers([...tempSubscribers]);
  }, [currentTeam]);

  const handleEditorModelChange = (model) => {
    setEditorModel(model);
  };

  const handleSetSubscribers = (incomingSubscribers) => {
    setSubscribers([...incomingSubscribers]);
  };

  const handleChangeAccess = useCallback((access) => {
    setAccessPrivate(access);
  }, []);

  const handleDateChangeDueDate = (date) => {
    setSelectedDueDate(date);
  };

  const handleChangeDueDateOption = (event) => {
    setDueDateOption(event.target.value);
  };

  const handleChangeAutoCompleteCode = (event) => {
    setAutoCompleteCode(event.target.value);
  };

  const customConfig = {
    heightMin: 200,
  };
  const pageType = PageConstants.pageType.blastsCreate;
  const breadcrumb = generateRoutesAndTitle(
    { type: pageType, data: { companyId, currentTeam, blastId } },
  );

  SearchJumpToActions.saveRecentPage({
    company: currentCompany,
    team: currentTeam,
    userId: user?._id,
    itemId: `${blastId}-create`,
    url: breadcrumb.url,
    routes: breadcrumb.routes,
    name: breadcrumb.title,
    type: pageType,
  });
  return (
    <>
      <Title title="Pengumuman baru" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        bottomBarOverviewOptionId="blasts"
        withBottomNavBar={false}
      />
      <LayoutMain>
        <SurfacePaperMain>
          <div className={styles.container}>
            <FeedbackLoadingMain
              wait="createBlastPostPage"
              loadings={loadings}
              loadingComponent={<EditBlastPostPageSkeleton />}
            >
              <div className={styles.headerSection}>
                <div className={styles.inputTitleSection}>
                  <TextareaAutosize placeholder="Ketik judul..." onClick={disableShowEmojiPicker} onChange={(event) => handleChanged(event)} minRows={1} maxRows={2} value={newMessage.value} />
                  <MoodIcon onClick={handleShowEmojiPicker} />
                  {showEmojiPicker
                    ? (
                      <div className={styles.emojiPicker}>
                        <EmojiPicker onEmojiClick={onEmojiClick} />
                      </div>
                    ) : null}
                </div>
              </div>
              <DisplayDividerMain marginTop="0px" borderColor={Color.gray4} />
              <div className={styles.postsSection}>
                <div>
                  {currentTeam.members ? <EditorFull companyId={companyId} model={editorModel} onModelChange={handleEditorModelChange} triggerLists={currentTeam.members} type="blast" handleAction={handleTriggerPostBlast} customConfig={customConfig} /> : null }
                </div>
              </div>

              <div className={styles.selectDueDateOption}>
                <div className={styles.selectDueDateOption__title}>
                  <DisplayTextSubHeadline
                    mode="14"
                    color={Color.deepGray2}
                  >
                    Kapan tenggat waktu Pengumuman ini selesai?
                  </DisplayTextSubHeadline>
                </div>
                <div className={styles.selectDueDateOption__chooseOption}>
                  <div className={styles.selectDueDateOption__choose}>
                    <FormControl variant="standard">
                      <Select
                        value={dueDateOption}
                        onChange={handleChangeDueDateOption}
                      >
                        <MenuItem value="auto">Selesai otomatis</MenuItem>
                        <MenuItem value="manual">Atur tenggat waktu sendiri</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <DisplayDividerMain orientation="vertical" flexItem marginRight="1rem" marginLeft="1rem" />
                  {dueDateOption === 'auto' ? (
                    <div className={styles.selectDueDateOption__item}>
                      <div className={styles.selectDueDateOption__item__text}>
                        <DisplayTextBody
                          color={Color.deepGray2}
                        >
                          Atur selesai otomatis pada :
                        </DisplayTextBody>
                      </div>
                      <FormControl variant="standard">
                        <Select
                          value={autoCompleteCode}
                          onChange={handleChangeAutoCompleteCode}
                        >
                          <MenuItem value="1">1 Hari dari sekarang</MenuItem>
                          <MenuItem value="3">3 Hari dari sekarang</MenuItem>
                          <MenuItem value="7">7 Hari dari sekarang</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )
                    : (
                      <div className={styles.selectDueDateOption__item}>
                        <div className={styles.selectDueDateOption__item__text}>
                          <DisplayTextBody
                            color={Color.deepGray2}
                          >
                            Tenggat :
                          </DisplayTextBody>
                        </div>
                        <div className={styles.dueDate}>
                          <div className={styles.datePicker}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                            >
                              <DesktopDatePicker
                                inputFormat="yyyy/MM/dd"
                                value={selectedDueDate}
                                onChange={handleDateChangeDueDate}
                                renderInput={(pickerParams) => <TextField variant="standard" {...pickerParams} />}
                              />
                            </LocalizationProvider>
                          </div>
                          {/* <div className={styles.timePicker}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                          >
                            <TimePicker
                              value={selectedDueDate}
                              onChange={handleDateChangeDueDate}
                              renderInput={
                                (pickerParams) => <TextField variant="standard" {...pickerParams} />
                              }
                            />
                          </LocalizationProvider>
                        </div> */}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className={styles.manageSubscribers}>
                <ManageSubscribersContainer
                  type="post"
                  initialSubscribers={subscribers}
                  handleSetSubscribers={handleSetSubscribers}
                  companyId={companyId}
                />
              </div>

              <InputSwitchMain
                title="Apakah pengumuman ini Rahasia untuk Penerima aja?"
                labelSwitch="Rahasia"
                handleChange={handleChangeAccess}
              />

              <div className={styles.actionSection}>
                <div className={styles.actionSection__cancel}>
                  <InputButtonMain handleClick={handleCancel} border="rounded" wide="wider" variant="outlinedDanger">Batal</InputButtonMain>
                </div>
                <InputButtonMain handleClick={handlePostBlast} border="rounded" wide="wider" wait="createBlastPost" spinnerSize="sm" loadings={loadings}>Publikasikan</InputButtonMain>
              </div>
            </FeedbackLoadingMain>
          </div>
        </SurfacePaperMain>
      </LayoutMain>
    </>
  );
}

export default CreateBlastPage;
