import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import RemoveIcon from '@mui/icons-material/Remove';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TextField, useMediaQuery } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import styles from './WithDueDateSection.module.css';
import Color from '../../../../../themes/colors';
import { InputButtonMain } from '../../../../UI_V2/Input/Button';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';

const WithDueDateSection = ({
  dueDate,
  handleSetDueDate,
  handleHideDueDateSection,
  startDate,
  handleSetStartDate,
}) => {
  const [isStartChecked, setIsStartChecked] = useState(startDate);
  const handleStartDateChecked = () => {
    if (isStartChecked) {
      handleSetStartDate();
      setIsStartChecked(false);
    } else {
      setIsStartChecked(true);
      handleSetStartDate(startDate || new Date());
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.checklistAndTitle}>
          <div className={styles.checklist} onClick={handleStartDateChecked}>
            {isStartChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </div>
          <DisplayTextBody>Tanggal Mulai</DisplayTextBody>
        </div>
        {isStartChecked && (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
        >
          <DesktopDatePicker
            inputFormat="yyyy/MM/dd"
            value={startDate}
            onChange={handleSetStartDate}
            renderInput={(pickerParams) => <TextField variant="standard" {...pickerParams} />}
          />
        </LocalizationProvider>
        )}
        {!isStartChecked && <div className={styles.disabledDate}><DisplayTextBody mode="16" color={Color.gray5}>YYYY/MM/DD</DisplayTextBody></div>}
      </div>
      <div className={styles.text}>
        <div className={styles.checklistAndTitle}>
          <div className={styles.checklist}>
            <CheckBoxIcon />
          </div>
          <DisplayTextBody>Tenggat</DisplayTextBody>
        </div>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
        >
          <DesktopDatePicker
            inputFormat="yyyy/MM/dd"
            value={dueDate}
            onChange={handleSetDueDate}
            renderInput={(pickerParams) => <TextField variant="standard" {...pickerParams} />}
          />
        </LocalizationProvider>
      </div>
      <div onClick={handleHideDueDateSection} className={styles.button}>
        <RemoveIcon />
      </div>
    </div>
  );
};

WithDueDateSection.propTypes = {
  dueDate: PropTypes.object.isRequired,
  handleSetDueDate: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  handleSetStartDate: PropTypes.func.isRequired,
  handleHideDueDateSection: PropTypes.func.isRequired,
};

WithDueDateSection.defaultProps = {};

export default memo(WithDueDateSection);
