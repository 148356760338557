import { pageProperties } from './PageConstants';

const featureTypeList = [
  {
    _id: 'team',
    name: 'Aktifitas Tim',
  },
  {
    _id: 'company',
    name: 'Aktifitas Perusahaan',
  },
  // {
  //   _id: 'board',
  //   name: 'Aktifitas Tugas',
  // },
  // {
  //   _id: 'docFile',
  //   name: 'Aktifitas Dokumen & File',
  // },
  // {
  //   _id: 'schedule',
  //   name: 'Aktifitas Jadwal',
  // },
  // {
  //   _id: 'blast',
  //   name: 'Aktifitas Pengumuman',
  // },
  // {
  //   _id: 'checkIn',
  //   name: 'Aktifitas Pertanyaan',
  // },
  // {
  //   _id: 'groupChat',
  //   name: 'Aktifitas Chat Grup',
  // },
];

const serviceTypeRef = {
  card: 'card',
  cardMember: 'cardMember',
  cardMention: 'cardMention',
  cardMoved: 'cardMoved',
  cardAddAttachmentActivity: 'cardAddAttachmentActivity',
  cardUpdateNameAttachmentActivity: 'cardUpdateNameAttachmentActivity',
  cardDeleteAttachmentActivity: 'cardDeleteAttachmentActivity',
  cardArchivedActivity: 'cardArchivedActivity',
  cardUnarchivedActivity: 'cardUnarchivedActivity',
  cardUpdateDueDateActivity: 'cardUpdateDueDateActivity',
  cardUpdateStartDateAndDueDateActivity: 'cardUpdateStartDateAndDueDateActivity', // blm
  cardUpdateUnsetDueDateActivity: 'cardUpdateUnsetDueDateActivity',
  cardUpdateUnsetStartDateButSetDueDateActivity: 'cardUpdateUnsetStartDateButSetDueDateActivity', // blm
  cardUpdateUnsetStartDateActivity: 'cardUpdateUnsetStartDateActivity', // blm
  cardUpdateStartDateActivity: 'cardUpdateStartDateActivity', // blm
  cardUpdateDescActivity: 'cardUpdateDescActivity',
  cardUpdateNameActivity: 'cardUpdateNameActivity',
  cardUpdateLabelsActivity: 'cardUpdateLabelsActivity',
  cardUpdateSetPublicActivity: 'cardUpdateSetPublicActivity',
  cardUpdateSetPrivateActivity: 'cardUpdateSetPrivateActivity',
  cardRoleUpdateActivity: 'cardRoleUpdateActivity',
  cardComment: 'cardComment',
  cardCommentUpdateActivity: 'cardCommentUpdateActivity',
  cardCommentMention: 'cardCommentMention',
  cardCommentDeleteActivity: 'cardCommentDeleteActivity',
  commentDiscussionCard: 'commentDiscussionCard',
  commentDiscussionMentionCard: 'commentDiscussionMentionCard',
  commentDiscussionCardUpdateActivity: 'commentDiscussionCardUpdateActivity',
  commentDiscussionCardDeleteActivity: 'commentDiscussionCardDeleteActivity',
  cardChecklistNewActivity: 'cardChecklistNewActivity',
  cardChecklistUpdateNameActivity: 'cardChecklistUpdateNameActivity',
  cardChecklistDeleteActivity: 'cardChecklistDeleteActivity',
  cardChecklistMoveActivity: 'cardChecklistMoveActivity',
  cardChecklistItemNewActivity: 'cardChecklistItemNewActivity',
  cardChecklistItemUpdateNameActivity: 'cardChecklistItemUpdateNameActivity',
  cardChecklistItemUpdateCompleteStatusActivity: 'cardChecklistItemUpdateCompleteStatusActivity',
  cardChecklistItemUpdateUnsetCompleteStatusActivity: 'cardChecklistItemUpdateUnsetCompleteStatusActivity',
  cardChecklistItemUpdateStartDateActivity: 'cardChecklistItemUpdateStartDateActivity',
  cardChecklistItemUpdateStartDateAndDueDateActivity: 'cardChecklistItemUpdateStartDateAndDueDateActivity',
  cardChecklistItemUpdateDueDateActivity: 'cardChecklistItemUpdateDueDateActivity',
  cardChecklistItemUpdateUnsetDueDateActivity: 'cardChecklistItemUpdateUnsetDueDateActivity',
  cardChecklistItemUpdateUnsetStartDateActivity: 'cardChecklistItemUpdateUnsetStartDateActivity',
  cardChecklistItemUpdateUnsetStartDateButSetDueDateActivity: 'cardChecklistItemUpdateUnsetStartDateButSetDueDateActivity',
  cardChecklistItemDeleteActivity: 'cardChecklistItemDeleteActivity',
  cardChecklistItemMoveActivity: 'cardChecklistItemMoveActivity',
  post: 'post',
  postMention: 'postMention',
  postSubscription: 'postSubscription',
  postUpdateActivity: 'postUpdateActivity',
  postArchivedActivity: 'postArchivedActivity',
  postUnarchivedActivity: 'postUnarchivedActivity',
  postRoleUpdateActivity: 'postRoleUpdateActivity',
  postComment: 'comment',
  postCommentMention: 'postCommentMention',
  postCommentUpdateActivity: 'postCommentUpdateActivity',
  postCommentDeleteActivity: 'postCommentDeleteActivity',
  commentDiscussionPost: 'commentDiscussionPost',
  commentDiscussionMentionPost: 'commentDiscussionMentionPost',
  commentDiscussionPostDeleteActivity: 'commentDiscussionPostDeleteActivity',
  commentDiscussionPostUpdateActivity: 'commentDiscussionPostUpdateActivity',
  doc: 'doc',
  docMention: 'docMention',
  docSubscription: 'docSubscription',
  docUpdateActivity: 'docUpdateActivity',
  docArchivedActivity: 'docArchivedActivity',
  docUnarchivedActivity: 'docUnarchivedActivity',
  docRoleUpdateActivity: 'docRoleUpdateActivity',
  docComment: 'docComment',
  docCommentUpdateActivity: 'docCommentUpdateActivity',
  docCommentMention: 'docCommentMention',
  docCommentDeleteActivity: 'docCommentDeleteActivity',
  commentDiscussionDoc: 'commentDiscussionDoc',
  commentDiscussionMentionDoc: 'commentDiscussionMentionDoc',
  commentDiscussionDocDeleteActivity: 'commentDiscussionDocDeleteActivity',
  commentDiscussionDocUpdateActivity: 'commentDiscussionDocUpdateActivity',
  question: 'question',
  questionMention: 'questionMention',
  questionSubscription: 'questionSubscription',
  questionUpdateActivity: 'questionUpdateActivity',
  questionArchivedActivity: 'questionArchivedActivity',
  questionUnarchivedActivity: 'questionUnarchivedActivity',
  questionRoleUpdateActivity: 'questionRoleUpdateActivity',
  questionComment: 'questionComment',
  questionCommentMention: 'questionCommentMention',
  questionCommentDeleteActivity: 'questionCommentDeleteActivity',
  questionCommentUpdateActivity: 'questionCommentUpdateActivity',
  commentDiscussionQuestion: 'commentDiscussionQuestion',
  commentDiscussionMentionQuestion: 'commentDiscussionMentionQuestion',
  commentDiscussionQuestionDeleteActivity: 'commentDiscussionQuestionDeleteActivity',
  commentDiscussionQuestionUpdateActivity: 'commentDiscussionQuestionUpdateActivity',
  event: 'event',
  eventMention: 'eventMention',
  eventSubscription: 'eventSubscription',
  eventUpdateActivity: 'eventUpdateActivity',
  eventArchivedActivity: 'eventArchivedActivity',
  eventUnarchivedActivity: 'eventUnarchivedActivity',
  eventRoleUpdateActivity: 'eventRoleUpdateActivity',
  eventComment: 'eventComment',
  eventCommentMention: 'eventCommentMention',
  eventCommentDeleteActivity: 'eventCommentDeleteActivity',
  eventCommentUpdateActivity: 'eventCommentUpdateActivity',
  commentDiscussionEvent: 'commentDiscussionEvent',
  commentDiscussionMentionEvent: 'commentDiscussionMentionEvent',
  commentDiscussionEventDeleteActivity: 'commentDiscussionEventDeleteActivity',
  commentDiscussionEventUpdateActivity: 'commentDiscussionEventUpdateActivity',
  recurringOccurrence: 'recurringOccurrence',
  recurringOccurrenceMention: 'recurringOccurrenceMention',
  recurringOccurrenceSubscription: 'recurringOccurrenceSubscription',
  recurringOccurrenceUpdateActivity: 'recurringOccurrenceUpdateActivity',
  recurringOccurrenceArchivedActivity: 'recurringOccurrenceArchivedActivity',
  recurringOccurrenceUnarchivedActivity: 'recurringOccurrenceUnarchivedActivity',
  recurringOccurrenceRoleUpdateActivity: 'recurringOccurrenceRoleUpdateActivity',
  recurringOccurrenceComment: 'recurringOccurrenceComment',
  recurringOccurrenceCommentMention: 'recurringOccurrenceCommentMention',
  recurringOccurrenceCommentDeleteActivity: 'recurringOccurrenceCommentDeleteActivity',
  recurringOccurrenceCommentUpdateActivity: 'recurringOccurrenceCommentUpdateActivity',
  commentDiscussionRecurringOccurrence: 'commentDiscussionRecurringOccurrence',
  commentDiscussionMentionRecurringOccurrence: 'commentDiscussionMentionRecurringOccurrence',
  commentDiscussionRecurringOccurrenceDeleteActivity: 'commentDiscussionRecurringOccurrenceDeleteActivity',
  commentDiscussionRecurringOccurrenceUpdateActivity: 'commentDiscussionRecurringOccurrenceUpdateActivity',
  file: 'file',
  fileSubscription: 'fileSubscription',
  fileUpdateActivity: 'fileUpdateActivity',
  fileArchivedActivity: 'fileArchivedActivity',
  fileUnarchivedActivity: 'fileUnarchivedActivity',
  fileRoleUpdateActivity: 'fileRoleUpdateActivity',
  fileComment: 'fileComment',
  fileCommentMention: 'fileCommentMention',
  fileCommentDeleteActivity: 'fileCommentDeleteActivity',
  fileCommentUpdateActivity: 'fileCommentUpdateActivity',
  commentDiscussionFile: 'commentDiscussionFile',
  commentDiscussionMentionFile: 'commentDiscussionMentionFile',
  commentDiscussionFileDeleteActivity: 'commentDiscussionFileDeleteActivity',
  commentDiscussionFileUpdateActivity: 'commentDiscussionFileUpdateActivity',
  bucket: 'bucket',
  bucketSubscription: 'bucketSubscription',
  bucketUpdateActivity: 'bucketUpdateActivity',
  bucketArchivedActivity: 'bucketArchivedActivity',
  bucketUnarchivedActivity: 'bucketUnarchivedActivity',
  bucketRoleUpdateActivity: 'bucketRoleUpdateActivity',
  groupChat: 'groupChat',
  groupChatMessage: 'groupChatMessage',
  groupChatMessageDeleteActivity: 'groupChatMessageDeleteActivity',
  groupChatAttachment: 'groupChatAttachment',
  groupChatAttachmentDeleteActivity: 'groupChatAttachmentDeleteActivity',
  groupChatMention: 'groupChatMention',
  chat: 'chat',
  chatMessage: 'chatMessage',
  chatAttachment: 'chatAttachment',
  chatMention: 'chatMention',
  teamCreateActivity: 'teamCreateActivity',
  teamArchivedActivity: 'teamArchivedActivity',
  teamUnarchivedActivity: 'teamUnarchivedActivity',
  teamSubscriptionActivity: 'teamSubscriptionActivity',
  teamUpdateActivity: 'teamUpdateActivity',
  teamRoleUpdateActivity: 'teamRoleUpdateActivity',
  companyInviteMembersActivity: 'companyInviteMembersActivity',
  companyJoinMemberActivity: 'companyJoinMemberActivity',
  companyRemoveMemberActivity: 'companyRemoveMemberActivity',
  companyUpdateActivity: 'companyUpdateActivity',
  companyUpdateLogoActivity: 'companyUpdateLogoActivity',
  companyRoleUpdateActivity: 'companyRoleUpdateActivity',
  cardCheersCreate: 'cardCheersCreate',
  cardCommentCheersCreate: 'cardCommentCheersCreate',
  cardCommentDiscussionCheersCreate: 'cardCommentDiscussionCheersCreate',
  postCheersCreate: 'postCheersCreate',
  postCommentCheersCreate: 'postCommentCheersCreate',
  postCommentDiscussionCheersCreate: 'postCommentDiscussionCheersCreate',
  docCheersCreate: 'docCheersCreate',
  docCommentCheersCreate: 'docCommentCheersCreate',
  docCommentDiscussionCheersCreate: 'docCommentDiscussionCheersCreate',
  fileCheersCreate: 'fileCheersCreate',
  fileCommentCheersCreate: 'fileCommentCheersCreate',
  fileCommentDiscussionCheersCreate: 'fileCommentDiscussionCheersCreate',
  eventCheersCreate: 'eventCheersCreate',
  eventCommentCheersCreate: 'eventCommentCheersCreate',
  eventCommentDiscussionCheersCreate: 'eventCommentDiscussionCheersCreate',
  questionCommentCheersCreate: 'questionCommentCheersCreate',
  questionCommentDiscussionCheersCreate: 'questionCommentDiscussionCheersCreate',
  recurringOccurrenceCheersCreate: 'recurringOccurrenceCheersCreate',
  recurringOccurrenceCommentCheersCreate: 'recurringOccurrenceCommentCheersCreate',
  recurringOccurrenceCommentDiscussionCheersCreate: 'recurringOccurrenceCommentDiscussionCheersCreate',
  cardCheerDeleteActivity: 'cardCheerDeleteActivity',
  cardCommentCheerDeleteActivity: 'cardCommentCheerDeleteActivity',
  cardCommentDiscussionCheerDeleteActivity: 'cardCommentDiscussionCheerDeleteActivity',
  postCheerDeleteActivity: 'postCheerDeleteActivity',
  postCommentCheerDeleteActivity: 'postCommentCheerDeleteActivity',
  postCommentDiscussionCheerDeleteActivity: 'postCommentDiscussionCheerDeleteActivity',
  docCheerDeleteActivity: 'docCheerDeleteActivity',
  docCommentCheerDeleteActivity: 'docCommentCheerDeleteActivity',
  docCommentDiscussionCheerDeleteActivity: 'docCommentDiscussionCheerDeleteActivity',
  fileCheerDeleteActivity: 'fileCheerDeleteActivity',
  fileCommentCheerDeleteActivity: 'fileCommentCheerDeleteActivity',
  fileCommentDiscussionCheerDeleteActivity: 'fileCommentDiscussionCheerDeleteActivity',
  questionCommentCheerDeleteActivity: 'questionCommentCheerDeleteActivity',
  questionCommentDiscussionCheerDeleteActivity: 'questionCommentDiscussionCheerDeleteActivity',
  eventCheerDeleteActivity: 'eventCheerDeleteActivity',
  eventCommentCheerDeleteActivity: 'eventCommentCheerDeleteActivity',
  eventCommentDiscussionCheerDeleteActivity: 'eventCommentDiscussionCheerDeleteActivity',
  recurringOccurrenceCheerDeleteActivity: 'recurringOccurrenceCheerDeleteActivity',
  recurringOccurrenceCommentCheerDeleteActivity: 'recurringOccurrenceCommentCheerDeleteActivity',
  recurringOccurrenceCommentDiscussionCheerDeleteActivity: 'recurringOccurrenceCommentDiscussionCheerDeleteActivity',
  listCreateActivity: 'listCreateActivity',
  listMovedActivity: 'listMovedActivity',
  listArchivedActivity: 'listArchivedActivity',
  listUnarchivedActivity: 'listUnarchivedActivity',
  listUpdateNameActivity: 'listUpdateNameActivity',
  listSetCompleteStatusActivity: 'listSetCompleteStatusActivity',
  listUnsetCompleteStatusActivity: 'listUnsetCompleteStatusActivity',
  listUpdateTypeCompleteStatusActivity: 'listUpdateTypeCompleteStatusActivity',
};

const serviceTypeProperty = {
  card: {
    activityAction: 'membuat Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardMember: {
    activityActionAddInitial: 'menambahkan',
    activityActionRemoveInitial: 'menghapus',
    activityActionAdd: 'sebagai anggota Tugas',
    activityActionRemove: 'dari anggota Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di catatan Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardMoved: {
    activityActionInitial: 'memindahkan tugas',
    activityAction: 'ke List',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardArchivedActivity: {
    activityAction: 'mengarsipkan Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardAddAttachmentActivity: {
    activityActionInitial: 'menambahkan lampiran',
    activityAction: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardDeleteAttachmentActivity: {
    activityActionInitial: 'menghapus lampiran',
    activityAction: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateNameAttachmentActivity: {
    activityActionInitial: 'mengubah nama lampiran menjadi',
    activityAction: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUnarchivedActivity: {
    activityActionInitial: 'mengembalikan Tugas',
    activityAction: 'dari arsip ke List',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateLabelsActivity: {
    activityActionInitial: 'memperbaharui label menjadi',
    activityAction: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateDueDateActivity: {
    activityAction: 'memperbaharui tenggat waktu menjadi',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateStartDateAndDueDateActivity: {
    activityAction: 'memperbaharui tanggal mulai & tenggat waktu menjadi',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateStartDateActivity: {
    activityAction: 'memperbaharui tanggal mulai menjadi',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateUnsetDueDateActivity: {
    activityAction: 'menghapus tenggat waktu Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateUnsetStartDateActivity: {
    activityAction: 'menghapus tanggal mulai Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateUnsetStartDateButSetDueDateActivity: {
    activityAction: 'menghapus tanggal mulai & mengubah tenggat waktu menjadi',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateDescActivity: {
    activityAction: 'mengubah catatan Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateSetPublicActivity: {
    activityAction: 'mengubah status menjadi public pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateSetPrivateActivity: {
    activityAction: 'mengubah status menjadi private pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardUpdateNameActivity: {
    activityAction: 'mengubah nama Tugas menjadi',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardRoleUpdateActivity: {
    activityActionInitial: 'mengubah akses',
    activityAction: 'pada Tugas',
    activityActionEnd: 'menjadi',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardComment: {
    activityAction: 'membuat komentar baru di Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardCommentMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah komentar di Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardCommentDeleteActivity: {
    activityActionInitial: 'menghapus komentar',
    activityAction: 'dari Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardCommentUpdateActivity: {
    activityActionInitial: 'mengubah komentar',
    activityAction: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  commentDiscussionCardDeleteActivity: {
    activityActionInitial: 'menghapus balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  commentDiscussionCardUpdateActivity: {
    activityActionInitial: 'mengubah balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  commentDiscussionCard: {
    activityAction: 'membalas komentar di Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  commentDiscussionMentionCard: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah balasan komentar (diskusi) di Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistNewActivity: {
    activityActionInitial: 'membuat checklist baru',
    activityAction: 'pada tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistUpdateNameActivity: {
    activityActionInitial: 'mengubah nama checklist menjadi',
    activityAction: 'pada tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistMoveActivity: {
    activityActionInitial: 'mengubah posisi checklist',
    activityAction: 'ke posisi',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistDeleteActivity: {
    activityActionInitial: 'menghapus checklist',
    activityAction: 'dari tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemNewActivity: {
    activityActionInitial: 'membuat sub tugas baru',
    activityAction: 'di ceklis',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemUpdateNameActivity: {
    activityActionInitial: 'mengubah nama sub tugas menjadi',
    activityAction: 'pada ceklis',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemUpdateCompleteStatusActivity: {
    activityActionInitial: 'menyelesaikan sub tugas',
    activityAction: 'pada ceklis',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemUpdateUnsetCompleteStatusActivity: {
    activityActionInitial: 'menghapus centang sub tugas',
    activityAction: 'pada ceklis',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemUpdateStartDateActivity: {
    activityActionInitial: 'memperbaharui tanggal mulai sub tugas',
    activityActionInitialSecond: 'menjadi',
    activityAction: 'pada ceklis',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemUpdateStartDateAndDueDateActivity: {
    activityActionInitial: 'memperbaharui tanggal mulai & tenggat waktu sub tugas',
    activityActionInitialSecond: 'menjadi',
    activityAction: 'pada ceklis',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemUpdateDueDateActivity: {
    activityActionInitial: 'memperbaharui tanggal tenggat waktu sub tugas',
    activityActionInitialSecond: 'menjadi',
    activityAction: 'pada ceklis',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemUpdateUnsetDueDateActivity: {
    activityActionInitial: 'menghapus tanggal tenggat waktu dan tanggal mulai sub tugas',
    activityAction: 'pada ceklis',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemUpdateUnsetStartDateActivity: {
    activityActionInitial: 'menghapus tanggal mulai sub tugas',
    activityAction: 'pada ceklis',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemUpdateUnsetStartDateButSetDueDateActivity: {
    activityActionInitial: 'menghapus tanggal mulai sub tugas',
    activityActionInitialSecond: 'dan mengubah tenggat waktu menjadi',
    activityAction: 'pada ceklis',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemMoveActivity: {
    activityActionInitial: 'memindahkan sub tugas',
    activityActionInitialSecond: 'dari ceklis',
    activityActionInitialThird: 'ke ceklis',
    activityAction: 'di posisi',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardChecklistItemDeleteActivity: {
    activityActionInitial: 'menghapus sub tugas',
    activityAction: 'pada ceklis',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  post: {
    activityAction: 'membuat Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di catatan Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postSubscription: {
    activityActionAddInitial: 'menambahkan',
    activityActionRemoveInitial: 'menghapus',
    activityActionAdd: 'sebagai penerima Pengumuman',
    activityActionRemove: 'dari penerima Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postUpdateActivity: {
    activityAction: 'mengubah Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postArchivedActivity: {
    activityAction: 'mengarsipkan Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postUnarchivedActivity: {
    activityAction: 'mengembalikan dari arsip untuk Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postRoleUpdateActivity: {
    activityActionInitial: 'mengubah akses',
    activityAction: 'pada Pengumuman',
    activityActionEnd: 'menjadi',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  comment: {
    activityAction: 'membuat komentar baru di Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postComment: {
    activityAction: 'membuat komentar baru di Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postCommentMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah komentar di Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postCommentDeleteActivity: {
    activityActionInitial: 'menghapus komentar',
    activityAction: 'dari Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postCommentUpdateActivity: {
    activityActionInitial: 'mengubah komentar',
    activityAction: 'pada Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  commentDiscussionPostUpdateActivity: {
    activityActionInitial: 'mengubah balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  commentDiscussionPostDeleteActivity: {
    activityActionInitial: 'menghapus balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  commentDiscussionPost: {
    activityAction: 'membalas komentar di Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  commentDiscussionMentionPost: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah balasan komentar (diskusi) di Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  doc: {
    activityAction: 'membuat Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di catatan Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docSubscription: {
    activityActionAddInitial: 'menambahkan',
    activityActionRemoveInitial: 'menghapus',
    activityActionAdd: 'sebagai penerima Dokumen',
    activityActionRemove: 'dari penerima Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docUpdateActivity: {
    activityAction: 'mengubah Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docArchivedActivity: {
    activityAction: 'mengarsipkan Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docUnarchivedActivity: {
    activityAction: 'mengembalikan dari arsip untuk Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docRoleUpdateActivity: {
    activityActionInitial: 'mengubah akses',
    activityAction: 'pada Dokumen',
    activityActionEnd: 'menjadi',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docComment: {
    activityAction: 'membuat komentar baru di Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docCommentMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah komentar di Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docCommentDeleteActivity: {
    activityActionInitial: 'menghapus komentar',
    activityAction: 'dari Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docCommentUpdateActivity: {
    activityActionInitial: 'mengubah komentar',
    activityAction: 'pada Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  commentDiscussionDocUpdateActivity: {
    activityActionInitial: 'mengubah balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  commentDiscussionDocDeleteActivity: {
    activityActionInitial: 'menghapus balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  commentDiscussionDoc: {
    activityAction: 'membalas komentar di Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  commentDiscussionMentionDoc: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah balasan komentar (diskusi) di Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  question: {
    activityAction: 'membuat Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di catatan Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionSubscription: {
    activityActionAddInitial: 'menambahkan',
    activityActionRemoveInitial: 'menghapus',
    activityActionAdd: 'sebagai penerima Pertanyaan',
    activityActionRemove: 'dari penerima Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionUpdateActivity: {
    activityAction: 'mengubah Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionArchivedActivity: {
    activityAction: 'mengarsipkan Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionUnarchivedActivity: {
    activityAction: 'mengembalikan dari arsip untuk Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionRoleUpdateActivity: {
    activityActionInitial: 'mengubah akses',
    activityAction: 'pada Pertanyaan',
    activityActionEnd: 'menjadi',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionComment: {
    activityAction: 'membuat komentar baru di Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionCommentMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah komentar di Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionCommentDeleteActivity: {
    activityActionInitial: 'menghapus komentar',
    activityAction: 'dari Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionCommentUpdateActivity: {
    activityActionInitial: 'mengubah komentar',
    activityAction: 'pada Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  commentDiscussionQuestionUpdateActivity: {
    activityActionInitial: 'mengubah balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  commentDiscussionQuestionDeleteActivity: {
    activityActionInitial: 'menghapus balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  commentDiscussionQuestion: {
    activityAction: 'membalas komentar di Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  commentDiscussionMentionQuestion: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah balasan komentar (diskusi) di Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  event: {
    activityActionUpdateOccurrence: 'mengubah Jadwal',
    activityAction: 'membuat Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di catatan Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventSubscription: {
    activityActionAddInitial: 'menambahkan',
    activityActionRemoveInitial: 'menghapus',
    activityActionAdd: 'sebagai peserta Jadwal',
    activityActionRemove: 'dari peserta Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventUpdateActivity: {
    activityAction: 'mengubah Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventArchivedActivity: {
    activityAction: 'mengarsipkan Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventUnarchivedActivity: {
    activityAction: 'mengembalikan dari arsip untuk Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventRoleUpdateActivity: {
    activityActionInitial: 'mengubah akses',
    activityAction: 'pada Jadwal',
    activityActionEnd: 'menjadi',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventComment: {
    activityAction: 'membuat komentar baru di Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventCommentMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah komentar di Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventCommentDeleteActivity: {
    activityActionInitial: 'menghapus komentar',
    activityAction: 'dari Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventCommentUpdateActivity: {
    activityActionInitial: 'mengubah komentar',
    activityAction: 'pada Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  commentDiscussionEventUpdateActivity: {
    activityActionInitial: 'mengubah balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  commentDiscussionEventDeleteActivity: {
    activityActionInitial: 'menghapus balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  commentDiscussionEvent: {
    activityAction: 'membalas komentar di Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  commentDiscussionMentionEvent: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah balasan komentar (diskusi) di Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrence: {
    activityActionUpdateThisAndFollowingOccurrenceInitial: 'mengubah Jadwal Berulang dari',
    activityActionUpdateThisAndFollowingOccurrence: 'hingga kedepannya pada',
    activityActionUpdateSingleEventBecomeRecurringOccurrences: 'mengubah Jadwal biasa menjadi jadwal berulang',
    activityAction: 'membuat Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di catatan Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceSubscription: {
    activityActionAddInitial: 'menambahkan',
    activityActionRemoveInitial: 'menghapus',
    activityActionAdd: 'sebagai peserta Jadwal Berulang',
    activityActionRemove: 'dari peserta Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceUpdateActivity: {
    activityAction: 'mengubah Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceArchivedActivity: {
    activityAction: 'mengarsipkan Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceUnarchivedActivity: {
    activityAction: 'mengembalikan dari arsip untuk Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceRoleUpdateActivity: {
    activityActionInitial: 'mengubah akses',
    activityAction: 'pada Jadwal Berulang',
    activityActionEnd: 'menjadi',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceComment: {
    activityAction: 'membuat komentar baru di Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceCommentMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah komentar di Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceCommentDeleteActivity: {
    activityActionInitial: 'menghapus komentar',
    activityAction: 'dari Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceCommentUpdateActivity: {
    activityActionInitial: 'mengubah komentar',
    activityAction: 'pada Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  commentDiscussionRecurringOccurrenceUpdateActivity: {
    activityActionInitial: 'mengubah balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  commentDiscussionRecurringOccurrenceDeleteActivity: {
    activityActionInitial: 'menghapus balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  commentDiscussionRecurringOccurrence: {
    activityAction: 'membalas komentar di Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  commentDiscussionMentionRecurringOccurrence: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah balasan komentar (diskusi) di Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  file: {
    activityAction: 'mengunggah File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileSubscription: {
    activityActionAddInitial: 'menambahkan',
    activityActionRemoveInitial: 'menghapus',
    activityActionAdd: 'sebagai penerima File',
    activityActionRemove: 'dari penerima File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileUpdateActivity: {
    activityAction: 'mengubah File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileArchivedActivity: {
    activityAction: 'mengarsipkan File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileUnarchivedActivity: {
    activityAction: 'mengembalikan dari arsip untuk File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileRoleUpdateActivity: {
    activityActionInitial: 'mengubah akses',
    activityAction: 'pada File',
    activityActionEnd: 'menjadi',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileComment: {
    activityAction: 'membuat komentar baru di File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileCommentMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah komentar di File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileCommentDeleteActivity: {
    activityActionInitial: 'menghapus komentar',
    activityAction: 'dari File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileCommentUpdateActivity: {
    activityActionInitial: 'mengubah komentar',
    activityAction: 'pada File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  commentDiscussionFileUpdateActivity: {
    activityActionInitial: 'mengubah balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  commentDiscussionFileDeleteActivity: {
    activityActionInitial: 'menghapus balasan komentar (diskusi)',
    activityAction: 'di sebuah komentar di File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  commentDiscussionFile: {
    activityAction: 'membalas komentar di File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  commentDiscussionMentionFile: {
    activityActionInitial: 'menyebut',
    activityAction: 'di sebuah balasan komentar (diskusi) di File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  bucket: {
    activityAction: 'membuat Folder',
    activityTitle: 'Folder',
    icon: pageProperties.buckets.icon,
    iconBackgroundColor: '#0C2044',
  },
  bucketSubscription: {
    activityActionAddInitial: 'menambahkan',
    activityActionRemoveInitial: 'menghapus',
    activityActionAdd: 'sebagai penerima Folder',
    activityActionRemove: 'dari penerima Folder',
    activityTitle: 'Folder',
    icon: pageProperties.buckets.icon,
    iconBackgroundColor: '#0C2044',
  },
  bucketUpdateActivity: {
    activityAction: 'mengubah Folder',
    activityTitle: 'Folder',
    icon: pageProperties.buckets.icon,
    iconBackgroundColor: '#0C2044',
  },
  bucketArchivedActivity: {
    activityAction: 'mengarsipkan Folder',
    activityTitle: 'Folder',
    icon: pageProperties.buckets.icon,
    iconBackgroundColor: '#0C2044',
  },
  bucketUnarchivedActivity: {
    activityAction: 'mengembalikan dari arsip untuk Folder',
    activityTitle: 'Folder',
    icon: pageProperties.buckets.icon,
    iconBackgroundColor: '#0C2044',
  },
  bucketRoleUpdateActivity: {
    activityActionInitial: 'mengubah akses',
    activityAction: 'pada Folder',
    activityActionEnd: 'menjadi',
    activityTitle: 'Folder',
    icon: pageProperties.buckets.icon,
    iconBackgroundColor: '#0C2044',
  },
  groupChat: {
    activityAction: 'membuat ruangan Chat Grup baru',
    activityTitle: 'Chat Grup',
    icon: pageProperties.groupChats.icon,
    iconBackgroundColor: '#F0B418',
  },
  groupChatMessage: {
    activityAction: 'mengirim pesan ke Chat Grup',
    activityTitle: 'Chat Grup',
    icon: pageProperties.groupChats.icon,
    iconBackgroundColor: '#F0B418',
  },
  groupChatMessageDeleteActivity: {
    activityActionInitial: 'menghapus pesan',
    activityAction: 'dari Chat Grup',
    activityTitle: 'Chat Grup',
    icon: pageProperties.groupChats.icon,
    iconBackgroundColor: '#F0B418',
  },
  groupChatMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di pesan Chat Grup',
    activityTitle: 'Chat Grup',
    icon: pageProperties.groupChats.icon,
    iconBackgroundColor: '#F0B418',
  },
  groupChatAttachment: {
    activityAction: 'mengirim berkas ke Chat Grup',
    activityTitle: 'Chat Grup',
    icon: pageProperties.groupChats.icon,
    iconBackgroundColor: '#F0B418',
  },
  groupChatAttachmentDeleteActivity: {
    activityActionInitial: 'menghapus berkas',
    activityAction: 'dari Chat Grup',
    activityTitle: 'Chat Grup',
    icon: pageProperties.groupChats.icon,
    iconBackgroundColor: '#F0B418',
  },
  chatMessage: {
    activityAction: 'mengirim pesan ke Chat Pribadi',
    activityTitle: 'Chat Pribadi',
    icon: pageProperties.chats.icon,
  },
  chatMention: {
    activityActionInitial: 'menyebut',
    activityAction: 'di pesan Chat',
    activityTitle: 'Chat Pribadi',
    icon: pageProperties.chats.icon,
  },
  chatAttachment: {
    activityAction: 'mengirim berkas ke Chat Pribadi',
    activityTitle: 'Chat Pribadi',
    icon: pageProperties.chats.icon,
  },
  teamCreateActivity: {
    activityAction: 'membuat',
    activityTitle: 'Tim',
    icon: pageProperties.teams.icon,
    iconBackgroundColor: '#7A7A7A',
  },
  teamArchivedActivity: {
    activityAction: 'mengarsipkan',
    activityTitle: 'Tim',
    icon: pageProperties.teams.icon,
    iconBackgroundColor: '#7A7A7A',
  },
  teamUnarchivedActivity: {
    activityAction: 'mengembalikan dari arsip untuk',
    activityTitle: 'Tim',
    icon: pageProperties.teams.icon,
    iconBackgroundColor: '#7A7A7A',
  },
  teamUpdateActivity: {
    activityAction: 'mengubah',
    activityTitle: 'Tim',
    icon: pageProperties.teams.icon,
    iconBackgroundColor: '#7A7A7A',
  },
  teamRoleUpdateActivity: {
    activityActionInitial: 'mengubah akses',
    activityAction: 'pada Tim',
    activityActionEnd: 'menjadi',
    activityTitle: 'Tim',
    icon: pageProperties.teams.icon,
    iconBackgroundColor: '#7A7A7A',
  },
  teamSubscriptionActivity: {
    activityActionAddInitial: 'menambahkan',
    activityActionRemoveInitial: 'menghapus',
    activityActionAdd: 'sebagai anggota',
    activityActionRemove: 'dari anggota',
    activityTitle: 'Tim',
    icon: pageProperties.teams.icon,
    iconBackgroundColor: '#7A7A7A',
  },
  companyInviteMembersActivity: {
    activityActionInitial: 'mengirimkan undangan ke',
    activityAction: 'orang untuk bergabung ke Perusahaan',
    activityTitle: 'Perusahaan',
    icon: pageProperties.companySettings.icon,
    iconBackgroundColor: '#956042',
  },
  companyJoinMemberActivity: {
    activityAction: 'berhasil bergabung ke Perusahaan',
    activityTitle: 'Perusahaan',
    icon: pageProperties.companySettings.icon,
    iconBackgroundColor: '#956042',
  },
  companyRemoveMemberActivity: {
    activityActionInitial: 'menghapus',
    activityAction: 'dari Perusahaan',
    activityTitle: 'Perusahaan',
    icon: pageProperties.companySettings.icon,
    iconBackgroundColor: '#956042',
  },
  companyUpdateActivity: {
    activityAction: 'mengubah nama atau deskripsi Perusahaan',
    activityTitle: 'Perusahaan',
    icon: pageProperties.companySettings.icon,
    iconBackgroundColor: '#956042',
  },
  companyUpdateLogoActivity: {
    activityAction: 'mengubah Logo Perusahaan',
    activityTitle: 'Perusahaan',
    icon: pageProperties.companySettings.icon,
    iconBackgroundColor: '#956042',
  },
  companyRoleUpdateActivity: {
    activityActionInitial: 'mengubah akses default perusahaan',
    activityAction: 'menjadi',
    activityTitle: 'Perusahaan',
    icon: pageProperties.companySettings.icon,
    iconBackgroundColor: '#956042',
  },
  cardCheersCreate: {
    activityAction: 'memberi Cheers di Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardCommentCheersCreate: {
    activityActionInitial: 'memberi Cheers di komentar',
    activityAction: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardCommentDiscussionCheersCreate: {
    activityActionInitial: 'memberi Cheers di diskusi',
    activityAction: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  postCheersCreate: {
    activityAction: 'memberi Cheers di Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postCommentCheersCreate: {
    activityActionInitial: 'memberi Cheers di komentar',
    activityAction: 'pada Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postCommentDiscussionCheersCreate: {
    activityActionInitial: 'memberi Cheers di diskusi',
    activityAction: 'pada Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  docCheersCreate: {
    activityAction: 'memberi Cheers di Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docCommentCheersCreate: {
    activityActionInitial: 'memberi Cheers di komentar',
    activityAction: 'pada Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docCommentDiscussionCheersCreate: {
    activityActionInitial: 'memberi Cheers di diskusi',
    activityAction: 'pada Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  fileCheersCreate: {
    activityAction: 'memberi Cheers di File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileCommentCheersCreate: {
    activityActionInitial: 'memberi Cheers di komentar',
    activityAction: 'pada File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileCommentDiscussionCheersCreate: {
    activityActionInitial: 'memberi Cheers di diskusi',
    activityAction: 'pada File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  eventCheersCreate: {
    activityAction: 'memberi Cheers di Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventCommentCheersCreate: {
    activityActionInitial: 'memberi Cheers di komentar',
    activityAction: 'pada Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventCommentDiscussionCheersCreate: {
    activityActionInitial: 'memberi Cheers di diskusi',
    activityAction: 'pada Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceCheersCreate: {
    activityAction: 'memberi Cheers di Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceCommentCheersCreate: {
    activityActionInitial: 'memberi Cheers di komentar',
    activityAction: 'pada Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceCommentDiscussionCheersCreate: {
    activityActionInitial: 'memberi Cheers di diskusi',
    activityAction: 'pada Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  questionCommentCheersCreate: {
    activityActionInitial: 'memberi Cheers di komentar',
    activityAction: 'pada Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionCommentDiscussionCheersCreate: {
    activityActionInitial: 'memberi Cheers di diskusi',
    activityAction: 'pada Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  cardCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardCommentCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari komentar',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  cardCommentDiscussionCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari diskusi',
    activityActionEnd: 'pada Tugas',
    activityTitle: 'Tugas',
    icon: pageProperties.cards.icon,
    iconBackgroundColor: '#ff7a8a',
  },
  postCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'pada Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postCommentCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari komentar',
    activityActionEnd: 'pada Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  postCommentDiscussionCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari diskusi',
    activityActionEnd: 'pada Pengumuman',
    activityTitle: 'Pengumuman',
    icon: pageProperties.posts.icon,
  },
  eventCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'pada Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventCommentCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari komentar',
    activityActionEnd: 'pada Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  eventCommentDiscussionCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari diskusi',
    activityActionEnd: 'pada Jadwal',
    activityTitle: 'Jadwal',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'pada Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceCommentCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari komentar',
    activityActionEnd: 'pada Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  recurringOccurrenceCommentDiscussionCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari diskusi',
    activityActionEnd: 'pada Jadwal Berulang',
    activityTitle: 'Jadwal Berulang',
    icon: pageProperties.events.icon,
    iconBackgroundColor: '#ce1212',
  },
  docCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'pada Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docCommentCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari komentar',
    activityActionEnd: 'pada Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  docCommentDiscussionCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari diskusi',
    activityActionEnd: 'pada Dokumen',
    activityTitle: 'Dokumen',
    icon: pageProperties.docs.icon,
    iconBackgroundColor: '#7868e6',
  },
  fileCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'pada File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileCommentCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari komentar',
    activityActionEnd: 'pada File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  fileCommentDiscussionCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari diskusi',
    activityActionEnd: 'pada File',
    activityTitle: 'File',
    icon: pageProperties.files.icon,
    iconBackgroundColor: '#822659',
  },
  questionCommentCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari komentar',
    activityActionEnd: 'pada Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  questionCommentDiscussionCheerDeleteActivity: {
    activityActionInitial: 'manghapus Cheers',
    activityAction: 'dari diskusi',
    activityActionEnd: 'pada Pertanyaan',
    activityTitle: 'Pertanyaan',
    icon: pageProperties.questions.icon,
    iconBackgroundColor: '#28df99',
  },
  listCreateActivity: {
    activityAction: 'membuat List baru',
    activityTitle: 'List Tugas',
    icon: pageProperties.boards.icon,
    iconBackgroundColor: '#F0B499',
  },
  listMovedActivity: {
    activityActionInitial: 'memindahkan List',
    activityAction: 'dari posisi',
    activityActionEnd: 'ke posisi',
    activityTitle: 'List Tugas',
    icon: pageProperties.boards.icon,
    iconBackgroundColor: '#F0B499',
  },
  listArchivedActivity: {
    activityAction: 'mengarsipkan List',
    activityTitle: 'List Tugas',
    icon: pageProperties.boards.icon,
    iconBackgroundColor: '#F0B499',
  },
  listUnarchivedActivity: {
    activityAction: 'mengembalikan dari arsip untuk List',
    activityTitle: 'List Tugas',
    icon: pageProperties.boards.icon,
    iconBackgroundColor: '#F0B499',
  },
  listUpdateNameActivity: {
    activityAction: 'mengubah nama List menjadi',
    activityTitle: 'List Tugas',
    icon: pageProperties.boards.icon,
    iconBackgroundColor: '#F0B499',
  },
  listSetCompleteStatusActivity: {
    activityActionInitial: 'mengubah tipe List',
    activityAction: 'menjadi List',
    activityTitle: 'List Tugas',
    icon: pageProperties.boards.icon,
    iconBackgroundColor: '#F0B499',
  },
  listUnsetCompleteStatusActivity: {
    activityActionInitial: 'mengubah tipe List',
    activityAction: 'menjadi List',
    activityTitle: 'List Tugas',
    icon: pageProperties.boards.icon,
    iconBackgroundColor: '#F0B499',
  },
  listUpdateTypeCompleteStatusActivity: {
    activityActionInitial: 'mengubah tipe List',
    activityAction: 'menjadi List',
    activityTitle: 'List Tugas',
    icon: pageProperties.boards.icon,
    iconBackgroundColor: '#F0B499',
  },
};

const limitActivity = 10;

export {
  featureTypeList,
  limitActivity,
  serviceTypeProperty,
  serviceTypeRef,
};
