import React, { useEffect, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import LoadingWithAnimation from '../../components/UI/LoadingWithAnimation/LoadingWithAnimation';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';
import styles from './EmailChangeVerificationPage.module.css';
import Title from '../../components/Title/Title';
import LogoHeader from '../../components/UI/LogoHeader/LogoHeader';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import { actionTypes } from '../../reducers/reducer';
import {
  AuthActions,
} from '../../actions';

function EmailChangeVerificationPage() {
  const [{ }, dispatch] = useContext(GlobalContext);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const verifyEmailChangeApi = async () => {
    const { tokenKey } = params;
    try {
      const result = await AuthActions.emailChangeVerification({ tokenKey });
      history.push('/success');
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      dispatch({
        type: actionTypes.SET_ERROR_RESPONSE,
        errorResponse: { message: status.message },
      });

      history.push(`/errors?previousPath=${location.pathname}`);
    }
  };

  useEffect(() => {
    verifyEmailChangeApi();
  }, []);

  return (
    <>
      <Title title="Verifikasi Perubahan Email" />
      <LogoHeader />
      <MainLayout>
        <LoadingWithAnimation>
          <h1>Tunggu ya...</h1>
          <h1>Memverifikasi perubahan email kamu</h1>
        </LoadingWithAnimation>
      </MainLayout>
    </>
  );
}

export default EmailChangeVerificationPage;
