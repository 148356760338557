import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SelectDueDate.module.css';
import Color from '../../../../themes/colors';
import { InputButtonMain } from '../../../UI_V2/Input/Button';
import WithDueDateSection from './WithDueDateSection/WithDueDateSection';
import WithoutDueDateSection from './WithoutDueDateSection/WithoutDueDateSection';

const SelectDueDate = ({
  dueDate, handleSetDueDate,
  startDate, handleSetStartDate,
}) => {
  const [showDueDateSection,
    setShowDueDateSection] = useState(dueDate);

  const handleShowDueDateSection = () => {
    handleSetDueDate(dueDate || new Date());
    setShowDueDateSection(true);
  };

  const handleHideDueDateSection = useCallback(() => {
    handleSetDueDate();
    setShowDueDateSection(false);
  }, []);

  return (
    <div className={styles.container}>
      {showDueDateSection && (
      <WithDueDateSection
        dueDate={dueDate}
        startDate={startDate}
        handleSetStartDate={handleSetStartDate}
        handleSetDueDate={handleSetDueDate}
        handleHideDueDateSection={handleHideDueDateSection}
      />
      )}
      {!showDueDateSection && (
      <WithoutDueDateSection
        handleShowDueDateSection={handleShowDueDateSection}
        handleSetDueDate={handleSetDueDate}
        showDueDateSection={showDueDateSection}
      />
      )}
    </div>
  );
};

SelectDueDate.propTypes = {
  dueDate: PropTypes.object.isRequired,
  handleSetDueDate: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  handleSetStartDate: PropTypes.func.isRequired,
};

SelectDueDate.defaultProps = {};

export default memo(SelectDueDate);
