import { cloneDeep, isArray, isNumber } from 'lodash';
import axios from 'axios';
import apiUtil, { getNextPage } from '../utilities/apiUtil';
import { actionTypes } from '../reducers/reducer';
import { ErrorException } from '../utilities/handleError';
import { ApiConstants, ChecklistConstants } from '../constants';
import {
  getIndexItemByIDFromList,
  mergeObjectListAndRemoveDuplicate,
  updateListProperty, updateListSocket,
} from '../utilities/arrayUtil';
import { initialState } from '../contexts/GlobalStateProvider';
import { level, typeRef } from '../constants/RoleConstants';
import { TeamActions } from '.';
import { handleAxiosDeleteRefreshToken } from './CheckLoginActions';

/*
  Dispatcher
*/

function dispatchPreviousChecklists({ previousChecklists }, dispatch) {
  dispatch({
    type: actionTypes.SET_PREVIOUS_CHECKLISTS,
    previousChecklists,
  });
}

function dispatchCurrentChecklists({ currentChecklists }, dispatch) {
  dispatch({
    type: actionTypes.SET_CURRENT_CHECKLISTS,
    currentChecklists,
  });
}

function dispatchUpdateChecklists({ updateChecklists }, dispatch) {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_CHECKLISTS,
    updateChecklists,
  });
}

/*
  SetterDispatcher
*/

function setPreviousChecklists({ previousChecklists }, dispatch) {
  if (!previousChecklists) return;

  dispatchPreviousChecklists(
    { previousChecklists: cloneDeep(previousChecklists) }, dispatch,
  );
}

function setCurrentChecklists({ currentChecklists }, dispatch) {
  if (!currentChecklists) return;
  dispatchCurrentChecklists(
    { currentChecklists: cloneDeep(currentChecklists) }, dispatch,
  );
}

/*
  Method
*/

async function initiateChecklists({
  page = 1, limit = ChecklistConstants.limitChecklist,
  companyId,
  cardId,
  teamId,
}, dispatch) {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.CHECKLISTS({
      cardId,
    }), {
      params: {
        page,
        limit,
        companyId,
        teamId,
      },
    });

    setCurrentChecklists({ currentChecklists: result?.data }, dispatch);
    setPreviousChecklists({
      previousChecklists: initialState.previousChecklists,
    }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function loadMoreChecklists({
  currentChecklists,
  limit = ChecklistConstants.limitChecklist,
  companyId,
  cardId,
  teamId,
}, dispatch) {
  try {
    const page = getNextPage({
      data: currentChecklists.data,
      limitPerPage: limit,
    });

    const result = await apiUtil.get(ApiConstants.URL_V1.CHECKLISTS({
      cardId,
    }), {
      params: {
        page,
        limit,
        companyId,
        teamId,
      },
    });

    const mergedDataChecklists = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentChecklists,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedChecklists = {
      ...result?.data,
      data: mergedDataChecklists?.data,
    };

    setPreviousChecklists({ previousChecklists: result?.data }, dispatch);
    setCurrentChecklists({ currentChecklists: mergedChecklists }, dispatch);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function createChecklistApi({
  cardId, companyId, teamId, checklistName,
}) {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.CHECKLISTS({ cardId }),
      {
        name: checklistName,
      },
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

async function editChecklistNameApi({
  cardId, checklistId, companyId, teamId, checklistName,
}) {
  try {
    const result = await apiUtil.post(
      ApiConstants.URL_V1.CHECKLIST_NAME({ cardId, checklistId }),
      {
        name: checklistName,
      },
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
}

const reorderChecklistPosition = ({
  checklistId, destPosition, sourcePosition,
}, dispatch) => {
  const updateChecklists = (currentChecklists) => {
    let newChecklists = currentChecklists.data;

    if (!checklistId
      || (destPosition === null || destPosition === undefined)
      || (sourcePosition === null || sourcePosition === undefined)) {
      return currentChecklists || [];
    }

    const checklist = newChecklists[sourcePosition];

    const isSourcePositionOutsideList = !checklist;

    if (!isSourcePositionOutsideList) {
      const removedListFromCurrentChecklists = updateListSocket({
        newData: checklist,
        currentList: newChecklists,
        typeAction: ChecklistConstants.typeCallback.DELETE,
        reverse: false,
      });
      newChecklists = [...removedListFromCurrentChecklists];
    }

    const isDestPositionOutsideList = destPosition > (currentChecklists.data.length - 1);

    if (!isDestPositionOutsideList) {
      const addedListToCurrentChecklists = updateListSocket({
        newData: checklist,
        currentList: newChecklists,
        typeAction: ChecklistConstants.typeCallback.NEW,
        destIndex: destPosition,
        reverse: false,
      });
      newChecklists = [...addedListToCurrentChecklists];
    }

    const finalChecklists = {
      ...currentChecklists,
      data: newChecklists,
    };
    return cloneDeep(finalChecklists);
  };

  dispatchUpdateChecklists({ updateChecklists }, dispatch);
};

async function reorderChecklistThenMoveChecklistApi({
  cardId, checklistId, companyId, teamId, destPosition, sourcePosition, currentChecklists,
}, dispatch) {
  try {
    // reorder local
    reorderChecklistPosition({
      checklistId,
      destPosition,
      sourcePosition,
    }, dispatch);

    // reorder api
    const result = await apiUtil.post(
      ApiConstants.URL_V1.CHECKLIST_MOVE({ cardId, checklistId }),
      {
        position: destPosition,
      },
      {
        params: {
          companyId,
          teamId,
        },
      },
    );

    return result;
  } catch (error) {
    // reset position if error
    setCurrentChecklists({ currentChecklists }, dispatch);
    throw new ErrorException(error);
  }
}

async function deleteChecklistApi({
  cardId, checklistId, companyId, teamId,
}) {
  try {
    const result = await axios.delete(`${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V1.CHECKLIST({ cardId, checklistId })}`, {
      withCredentials: true,
      headers: {
        Authorization: `jwt ${localStorage.getItem('token')}`,
      },
      params: {
        companyId,
        teamId,
      },
    });

    return result;
  } catch (error) {
    const newAuthToken = await handleAxiosDeleteRefreshToken(error);
    if (newAuthToken) {
      try {
        const result = await axios.delete(`${process.env.REACT_APP_PRIMARY_API_URL}${ApiConstants.URL_V1.CHECKLIST({ cardId, checklistId })}`, {
          withCredentials: true,
          headers: {
            Authorization: `jwt ${localStorage.getItem('token')}`,
          },
          params: {
            companyId,
            teamId,
          },
        });

        return result;
      } catch (err) {
        throw new ErrorException(err);
      }
    }
    throw new ErrorException(error);
  }
}

/* Socket Stuff */

function incomingChecklist({
  checklist, typeAction, keyProperty = 'data',
}, dispatch) {
  if (!checklist) return;

  const updateChecklists = (currentChecklists) => {
    const isNew = typeAction === ChecklistConstants.typeCallback.NEW;
    const isUpdate = typeAction === ChecklistConstants.typeCallback.EDIT;
    const isDelete = typeAction === ChecklistConstants.typeCallback.DELETE;

    let finalChecklist;
    if (isNew) {
      finalChecklist = checklist;
    }
    if (isUpdate) {
      const oldChecklist = currentChecklists.data.find((elem) => elem._id === checklist._id);
      if (!oldChecklist) return currentChecklists;

      finalChecklist = {
        ...checklist,
        checklistItems: [...oldChecklist?.checklistItems],
      };
    }
    if (isDelete) {
      finalChecklist = currentChecklists.data.find((elem) => elem._id === checklist._id);
      if (!finalChecklist) return currentChecklists;
    }

    return updateListProperty({
      keyProperty,
      newData: finalChecklist,
      currentList: currentChecklists,
      typeAction,
      reverse: false,
    });
  };

  dispatchUpdateChecklists({ updateChecklists }, dispatch);
}

function incomingChecklistMove({
  checklist, position,
}, dispatch) {
  if (!checklist) return;

  const updateChecklists = (currentChecklists) => {
    const isChecklistCurrentlyExist = currentChecklists.data.find(
      (elem) => elem._id === checklist._id,
    );

    const indexChecklist = getIndexItemByIDFromList(
      currentChecklists.data, checklist,
    );

    const isIndexTheSameWithDestPosition = indexChecklist === position;

    if (isChecklistCurrentlyExist && isIndexTheSameWithDestPosition) return currentChecklists;

    let newChecklists = currentChecklists.data;
    if (isChecklistCurrentlyExist) {
      const removedChecklists = updateListSocket({
        newData: checklist,
        currentList: newChecklists,
        typeAction: ChecklistConstants.typeCallback.DELETE,
        reverse: false,
      });
      newChecklists = [...removedChecklists];
    }

    const isDestPositionInsideCurrentChecklists = position <= currentChecklists.data.length;

    if (isDestPositionInsideCurrentChecklists) {
      const addedChecklists = updateListSocket({
        newData: checklist,
        currentList: newChecklists,
        typeAction: ChecklistConstants.typeCallback.NEW,
        destIndex: position,
        reverse: false,
      });
      newChecklists = [...addedChecklists];
    }

    const finalChecklists = {
      ...currentChecklists,
      data: newChecklists,
    };
    return cloneDeep(finalChecklists);
  };

  dispatchUpdateChecklists({ updateChecklists }, dispatch);
}

export {
  initiateChecklists,
  loadMoreChecklists,
  setPreviousChecklists,
  setCurrentChecklists,
  createChecklistApi,
  incomingChecklist,
  dispatchUpdateChecklists,
  editChecklistNameApi,
  deleteChecklistApi,
  reorderChecklistThenMoveChecklistApi,
  incomingChecklistMove,
};
