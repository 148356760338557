import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useMediaQuery } from '@mui/material';
import styles from './ChecklistCountIcon.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody } from '../../../../components/UI_V2/Display/Text';

const completedStyles = {
  true: `${styles.container} ${styles.completed}`,
  false: styles.container,
};

const completedIconStyles = {
  true: `${styles.icon} ${styles.completedIcon}`,
  false: styles.icon,
};

const ChecklistCountIcon = ({
  completed,
  total,
}) => {
  const isCompletedAll = completed === total;
  const isZero = total === undefined || total === null || total === 0;
  return (
    <>
      {!isZero && (
      <div className={completedStyles[isCompletedAll]}>
        <div className={completedIconStyles[isCompletedAll]}>
          <CheckCircleOutlineIcon />
        </div>
        <div className={styles.text}>
          <DisplayTextBody mode="12" color={isCompletedAll ? Color.white : Color.fontColor}>
            {completed}
            /
            {total}
          </DisplayTextBody>
        </div>
      </div>
      )}
    </>
  );
};

ChecklistCountIcon.propTypes = {
  completed: PropTypes.number,
  total: PropTypes.number,
};

ChecklistCountIcon.defaultProps = {
  completed: 0,
  total: 0,
};

export default memo(ChecklistCountIcon);
