import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ModalRoute, ModalSwitch } from 'react-router-modal-gallery';
import { Modal } from 'react-bootstrap';
import styles from './CalendarListCards.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import CardListItem from '../../../components/CardList/CardListItem/CardListItem';
import { useInfinityScrollHooks } from '../../../hooks/InfinityScrollHooks';
import handleLoadings from '../../../utilities/handleLoadings';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { MyTaskCardConstants } from '../../../constants';
import { CardActions, MyTaskCardActions } from '../../../actions';
import { FeedbackLoadingMain } from '../../../components/UI_V2/Feedback/Loading';
import InfinityScroll from '../../../components/UI/InfinityScroll/InfinityScroll';
import CardDetailContainer from '../../CardDetailContainer/CardDetailContainer';
import ModalLinkNoDecor from '../../../components/ModalLinkNoDecor/ModalLinkNoDecor';
import { useDelayShowHideHandler } from '../../../hooks/HelperHooks';
import { GlobalContext, initialState } from '../../../contexts/GlobalStateProvider';
import { useMyTaskCardSocket } from '../../../hooks/MyTaskCardHooks';
import FilterBar from '../../../components/Container/FilterBar/FilterBar';
import CalendarListCardsSkeleton from './CalendarListCardsSkeleton/CalendarListCardsSkeleton';
import { getStoreFilterMyTaskCard, setStoreFilterMyTaskCard, setStoreMyTaskCardView } from '../../../utilities/localStorage';
import CardCalendar from '../../../components/CardCalendar/CardCalendar';
import { getFirstAndLastDateWithSurplusThisMonth } from '../../../utilities/dateUtil';
import { FeedbackSpinnerGeneral } from '../../../components/UI_V2/Feedback/Spinner';
import { DisplayTextHeadline } from '../../../components/UI_V2/Display/Text';
import { setSessionMyTaskCardView } from '../../../utilities/sessionStorage';
import { FeedbackDialogGeneral } from '../../../components/UI_V2/Feedback/Dialog';

const CalendarListCards = ({
  showFilters,
  handleHideFilters,
  handleShowFilterActive,
  handleHideFilterActive,
  handleShowCreateMyTaskCard,
  handleSetDueDate,
  initialCalendarView,
}) => {
  const [{
    user,
    currentMyTaskCardCalendarAll,
    previousMyTaskCardCalendarAll,
    teams,
    currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    companyId,
  } = params;

  const [loadings, setLoadings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialFilterMyTaskCard = getStoreFilterMyTaskCard(
    { companyId, userId: user._id },
  );

  const initialFilterTeams = initialFilterMyTaskCard?.filterTeams || teams.map((team) => team._id);
  const initialFilterSubscribers = initialFilterMyTaskCard?.filterSubscribers || [];
  const initialFilterLabels = initialFilterMyTaskCard?.filterLabels || [];
  const initialFilterTitle = initialFilterMyTaskCard?.filterTitle || '';
  const initialFilterDueDate = initialFilterMyTaskCard?.filterDueDate || null;

  const [filters, setFilters] = useState({
    filterTeams: initialFilterTeams,
    filterSubscribers: initialFilterSubscribers,
    filterLabels: initialFilterLabels,
    filterTitle: initialFilterTitle,
    filterDueDate: initialFilterDueDate,
  });

  useMyTaskCardSocket({
    companyId,
    userId: user?._id,
    view: 'calendarAll',
  }, dispatch);

  // const [initialFromDate,
  //   initialUntilDate] = getFirstAndLastDateWithSurplusThisMonth();
  const [rangeDate, setRangeDate] = useState({
    fromDate: undefined,
    untilDate: undefined,
  });

  const handleSetRangeDate = useCallback((fromDate, untilDate) => {
    setRangeDate({
      fromDate,
      untilDate,
    });
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const initiateMyTaskCardCalendarAllApi = async (filtersValue, fromDate, untilDate) => {
    try {
      setIsLoading(true);

      const result = await MyTaskCardActions.initiateMyTaskCardCalendarAll({
        companyId,
        filters: filtersValue,
        fromDate,
        untilDate,
      }, dispatch);
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (!user?._id) return;
  //   initiateMyTaskCardCalendarAllApi(filters, rangeDate.fromDate, rangeDate.untilDate);
  // }, []);

  const onApplyFilters = (filtersValue) => {
    setFilters(filtersValue);
    initiateMyTaskCardCalendarAllApi(filtersValue, rangeDate.fromDate, rangeDate.untilDate);
    setStoreFilterMyTaskCard({
      companyId,
      userId: user._id,
      filter: filtersValue,
    });
  };

  useEffect(() => {
    const {
      filterDueDate,
      filterLabels,
      filterSubscribers,
      filterTeams,
      filterTitle,
    } = filters;
    const isSearchFilterActive = filterTitle.length > 0;
    const isTeamsFilterActive = filterTeams.length !== teams.length;
    const isLabelsFilterActive = filterLabels.length > 0;
    const isMembersFilterActive = filterSubscribers.length > 0;
    const isDueDateFilterActive = filterDueDate;

    let isActive = false;
    if (isSearchFilterActive) isActive = true;
    if (isTeamsFilterActive) isActive = true;
    if (isLabelsFilterActive) isActive = true;
    if (isMembersFilterActive) isActive = true;
    if (isDueDateFilterActive) isActive = true;

    if (isActive) {
      handleShowFilterActive(true);
    } else {
      handleHideFilterActive(false);
    }
  }, [filters]);

  const updateCardApiAndSetLoading = async (card, startDate, dueDate, event) => {
    const cardId = params.cardId || card._id;
    const teamId = params.teamId || card.team?._id || card.team;
    const isoDate = dueDate.toISOString();
    let payload = { dueDate: isoDate, 'isNotified.dueOneDay': false, 'isNotified.dueOneHour': false };

    if (startDate) {
      const isoStartDate = startDate.toISOString();
      payload = {
        ...payload,
        startDate: isoStartDate,
        'isNotified.startOneDay': false,
        'isNotified.startOneHour': false,
      };
    }

    try {
      event.setExtendedProp('isLoading', true);

      const result = await CardActions.updateCard({
        teamId, cardId, body: payload, companyId,
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      event.setExtendedProp('isLoading', false);
    }
  };

  const handleDatesSet = (dateInfo) => {
    const fromDate = dateInfo.start;
    const untilDate = dateInfo.end;
    const calendarView = dateInfo.view.type;
    setStoreMyTaskCardView({
      companyId,
      userId: user._id,
      view: {
        initialCalendarViewValue: calendarView,
        mainValue: 'calendarAll',
      },
    });
    setSessionMyTaskCardView({
      companyId,
      userId: user._id,
      view: {
        initialCalendarViewValue: calendarView,
        mainValue: 'calendarAll',
      },
    });
    handleSetRangeDate(fromDate, untilDate);
    initiateMyTaskCardCalendarAllApi(filters, fromDate, untilDate);
  };

  const handleDateClick = (dateInfo) => {
    handleSetDueDate(dateInfo.date);
    handleShowCreateMyTaskCard();
  };

  const handleCardDrop = (dateInfo) => {
    const card = dateInfo.event.extendedProps;
    const { event } = dateInfo;
    // in fullcalendar, it's event.start for dueDate when no startDate
    // but start date will remain startDate and end become endDate
    // if there are startDate & endDate
    const newStartDate = dateInfo.event.start;
    const newDueDate = dateInfo.event.end;

    if (newDueDate) {
      updateCardApiAndSetLoading(card, newStartDate, newDueDate, event);
    } else {
      updateCardApiAndSetLoading(card, null, newStartDate, event);
    }
  };

  return (
    <>
      <CardCalendar
        listCards={currentMyTaskCardCalendarAll.data}
        handleDatesSet={handleDatesSet}
        handleDateClick={handleDateClick}
        handleCardDrop={handleCardDrop}
        initialCalendarView={initialCalendarView}
      />
      <ModalSwitch renderModal={({ open, redirectToBack }) => (
        <FeedbackDialogGeneral
          open={open}
          onClose={
          () => history.push(`/companies/${companyId}/my-tasks/calendar/all`)
          }
          maxWidth="md"
          disableCloseIcon
        >
          <ModalRoute
            defaultParentPath={`/companies/${companyId}/my-tasks/calendar/all`}
            path="/companies/:companyId/my-tasks/calendar/all/teams/:teamId/cards/:cardId"
            component={CardDetailContainer}
          />
        </FeedbackDialogGeneral>
      )}
      >
        <ModalRoute
          defaultParentPath={`/companies/${companyId}/my-tasks/calendar/all`}
          path="/companies/:companyId/my-tasks/calendar/all/teams/:teamId/cards/:cardId"
          component={CardDetailContainer}
        />
      </ModalSwitch>
      {isLoading && (
      <div className={styles.forLoading}>
        <div className={styles.forLoading__box} />
        <div className={styles.forLoading__item}>
          <FeedbackSpinnerGeneral size={36} color={Color.yellowAccentCicle} thickness={6} />
          <DisplayTextHeadline
            color={Color.blueNavyCicle}
            mode="28"
            decoration="bold"
            position="center"
          >
            Menyiapkan data...
          </DisplayTextHeadline>
        </div>
      </div>
      )}
      {showFilters
      && (
      <FilterBar
        showLabelSection={false}
        listTeams={teams}
        initialSelectedTeams={filters.filterTeams}
        listMembers={currentCompany.members}
        initialSelectedMembers={filters.filterSubscribers}
        showMemberSection
        showDueDateSection
        initialDueDate={filters.filterDueDate}
        showTeamSection
        onClose={handleHideFilters}
        onApplyFilters={onApplyFilters}
        loadingsOnApplyFilters={loadings}
        waitOnApplyFilters="initiateMyTaskCardCalendarAll"
        initialSearch={filters.filterTitle}
        barTitle="Filter Tugas"
        topPositionVariant="withBottomBar"
        invertResetTeamCondition
      />
      )}
    </>
  );
};

CalendarListCards.propTypes = {
  showFilters: PropTypes.bool.isRequired,
  handleHideFilters: PropTypes.func.isRequired,
  handleShowFilterActive: PropTypes.func.isRequired,
  handleHideFilterActive: PropTypes.func.isRequired,
  handleShowCreateMyTaskCard: PropTypes.func.isRequired,
  handleSetDueDate: PropTypes.func.isRequired,
  initialCalendarView: PropTypes.string.isRequired,
};

CalendarListCards.defaultProps = {};

export default memo(CalendarListCards);
