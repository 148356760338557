import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton, useMediaQuery } from '@mui/material';
import styles from './CardCalendarItem.module.css';
import Color from '../../../themes/colors';
import { InputButtonMain } from '../../UI_V2/Input/Button';
import DescIcon from '../../UI/DescIcon/DescIcon';
import CommentIcon from '../../UI/CommentIcon/CommentIcon';
import AttachmentIcon from '../../UI/AttachmentIcon/AttachmentIcon';
import PrivateIcon from '../../UI/PrivateIcon/PrivateIcon';
import { DisplayTextBody, DisplayTextSubBody } from '../../UI_V2/Display/Text';
import ModalLinkNoDecor from '../../ModalLinkNoDecor/ModalLinkNoDecor';
import LabelThin from '../../UI/LabelThin/LabelThin';
import ShowLimitMembers from '../../ShowLimitMembers/ShowLimitMembers';
import { limitChar } from '../../../utilities/stringUtil';
import { DisplayDateThinBox } from '../../UI_V2/Display/Date';
import ChecklistCountIcon from '../../../pages/ListContainer/SmallCard/ChecklistCountIcon/ChecklistCountIcon';

const CardCalendarItem = ({ event, urlVariant }) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const card = event.extendedProps;

  const { isLoading } = card;

  let url;
  switch (urlVariant) {
    case 'myTaskCalendarAll':
      url = `/companies/${card.company}/my-tasks/calendar/all/teams/${card.team?._id}/cards/${card._id}`;
      break;
    case 'boardCalendarAll':
      url = `/companies/${card.company}/teams/${card.team?._id}/boards/${card.board}/calendar/all/cards/${card._id}`;
      break;
    default:
      break;
  }

  return (
    <div className={styles.container}>
      <ModalLinkNoDecor to={url}><div className={styles.link} /></ModalLinkNoDecor>
      <div className={styles.labelsAndDate}>
        <div className={styles.labels}>
          {card.labels.map((label, index) => {
            if (isMobile && index > 0) return <></>;
            return (
              <LabelThin color={label?.color?.name} />
            );
          })}
        </div>
        <DisplayDateThinBox dueDate={card.dueDate} completeStatus={card.complete?.status} />
      </div>
      <div className={styles.titleAndIcon}>
        <PrivateIcon data={{ isPublic: card.isPublic }} size="small" />
        {' '}
        {' '}
        {!isLoading && <DisplayTextBody decoration="bold">{limitChar(event.title, isMobile ? 15 : 45)}</DisplayTextBody>}
        {isLoading && <Skeleton width={90} />}
      </div>
      <div className={styles.membersAndTeam}>
        <div className={styles.members}>
          <ShowLimitMembers
            members={card.members}
            limit={isMobile ? 1 : 3}
            noPlusButton
            companyId={card.company}
            disableMemberOnlinePhoto
            sizePhotoMember="xs"
          />
        </div>
        <div className={styles.othersPropertiesSection}>
          <DescIcon desc={card.desc} />
          <CommentIcon comments={card.comments} />
          <AttachmentIcon attachments={card.attachments} />
          <ChecklistCountIcon
            completed={card.allChecklistItemsCount?.completed}
            total={card.allChecklistItemsCount?.total}
          />
        </div>
      </div>
      <div className={styles.teamAndList}>
        <DisplayTextSubBody color={Color.gray3}>
          {limitChar(card.list.name, isMobile ? 5 : 16)}
          {' - '}
          {limitChar(card.team.name, isMobile ? 5 : 16)}
        </DisplayTextSubBody>
      </div>
    </div>
  );
};

CardCalendarItem.propTypes = {
  event: PropTypes.object,
  urlVariant: PropTypes.oneOf(['myTaskCalendarAll', 'boardCalendarAll']),
};

CardCalendarItem.defaultProps = {
  event: {},
  urlVariant: 'myTaskCalendarAll',
};

export default memo(CardCalendarItem);
