import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import styles from './SmallCard.module.css';
import Label from '../../../components/UI/Label/Label';
import ImageMember from '../../../components/UI/ImageMember/ImageMember';
import DisplayDateBox from '../../../components/UI_V2/Display/Date/Box/Box';
import DescIcon from '../../../components/UI/DescIcon/DescIcon';
import CommentIcon from '../../../components/UI/CommentIcon/CommentIcon';
import AttachmentIcon from '../../../components/UI/AttachmentIcon/AttachmentIcon';
import ArchivedIcon from '../../../components/UI/ArchivedIcon/ArchivedIcon';
import ModalLinkNoDecor from '../../../components/ModalLinkNoDecor/ModalLinkNoDecor';
import PrivateIcon from '../../../components/UI/PrivateIcon/PrivateIcon';
import { DisplayAvatarMember } from '../../../components/UI_V2/Display/Avatar';
import { DisplayTextBody, DisplayTextSubBody } from '../../../components/UI_V2/Display/Text';
import { limitChar } from '../../../utilities/stringUtil';
import Color from '../../../themes/colors';
import ChecklistCountIcon from './ChecklistCountIcon/ChecklistCountIcon';

const borderVariantStyles = {
  square: styles.borderSquare,
  semiRounded: styles.borderSemiRounded,
};

const shadowStyles = {
  disabled: styles.shadowDisabled,
  regular: styles.shadowRegular,
};

function SmallCard(props) {
  const {
    provided, innerRef, name, desc, labels, members, comments, attachments, dueDate, startDate,
    archived, complete, cardId, handleShowEdit, handleCancelEditCardMenu,
    handleCancelAllCardMenu, clicked, isCardPublic, teamId, borderVariant, shadowVariant,
    isCardBlur, isCardOngoingButOverdue, boardId, list, team, showListAndTeamName,
    allChecklistItemsCount,
  } = props;

  const params = useParams();
  const { companyId } = params;

  return (
    <>
      {provided ? (
        <>
          <div
            onMouseDown={(event) => {
              handleCancelEditCardMenu(event);
              // make sure edit cardmenu closed, before this
              setTimeout(() => {
                handleCancelAllCardMenu();
              }, 200);
            }}
            onTouchStart={(event) => {
              handleCancelEditCardMenu(event);
              // make sure edit cardmenu closed, before this
              setTimeout(() => {
                handleCancelAllCardMenu();
              }, 200);
            }}
            className={`${styles.SmallCard} ${borderVariantStyles[borderVariant]} ${shadowStyles[shadowVariant]}`}
            {...provided?.draggableProps}
            {...provided?.dragHandleProps}
            ref={innerRef}
            onClick={clicked}
          >
            <ModalLinkNoDecor to={`/companies/${companyId}/teams/${teamId || params?.teamId}/boards/${boardId || params.boardId}/kanban/all/cards/${cardId}`} overlay />

            <div
              id="editCardMenu"
              className={styles.cardMenu}
              onClick={() => handleShowEdit(cardId)}
            >
              <EditIcon id="editCardMenuIcon" />
            </div>

            <div className={styles.labelsSection}>
              {labels !== undefined ? labels.map((label) => (

                <Label color={label?.color?.name} name={label?.name} />

              )) : null}
            </div>
            <div className={styles.cardTitleSection}>
              <h1>
                <PrivateIcon data={{ isPublic: isCardPublic }} size="small" />
                {' '}
                {name}
              </h1>
            </div>

            <div className={styles.othersPropertiesSection}>
              {dueDate !== undefined
                ? (
                  <div className={styles.dateBox}>
                    <DisplayDateBox
                      complete={complete}
                      date={dueDate}
                      startDate={startDate}
                      icon
                      changeColorDate
                    />
                  </div>
                ) : null}
              <DescIcon desc={desc} />
              <CommentIcon comments={comments} />
              <AttachmentIcon attachments={attachments} />
              <ChecklistCountIcon
                completed={allChecklistItemsCount?.completed}
                total={allChecklistItemsCount?.total}
              />
            </div>
            <div className={styles.imageMemberSection}>
              {members?.map((member) => (
                <DisplayAvatarMember marginRight="6px" marginBottom="3px" src={member?.photoUrl} alt={member?.fullName} size="sm" />
              ))}
            </div>
            {showListAndTeamName && (
            <div className={styles.teamAndList}>
              <DisplayTextSubBody color={Color.gray3}>
                {limitChar(list.name, 16)}
                {' - '}
                {limitChar(team.name, 16)}
              </DisplayTextSubBody>
            </div>
            )}
          </div>

        </>
      )
        : (
          <div
            className={`${styles.SmallCard} ${borderVariantStyles[borderVariant]} ${shadowStyles[shadowVariant]}`}
            onClick={clicked}
          >
            {isCardBlur && <div className={styles.cardBlur} />}
            {isCardBlur && (
            <div className={styles.cardBlurText}>
              <div className={styles.blurIconPrivate}>
                <PrivateIcon data={{ isPublic: isCardPublic }} size="small" />
              </div>
              <DisplayTextBody
                decoration="bold"
              >
                Tugas Rahasia
              </DisplayTextBody>
            </div>
            )}
            <ModalLinkNoDecor to={`/companies/${companyId}/teams/${teamId || params?.teamId}/cards/${cardId}`} overlay />
            <div className={styles.labelsSection}>
              {labels !== undefined ? labels.map((label) => (

                <Label color={label?.color?.name} name={label?.name} />

              )) : null}
            </div>
            <div className={styles.cardTitleSection}>
              <h1>
                <PrivateIcon data={{ isPublic: isCardPublic }} size="small" />
                {' '}
                {name}
              </h1>
            </div>

            <div className={styles.othersPropertiesSection}>
              {dueDate !== undefined
                && (
                  <DisplayDateBox
                    complete={complete}
                    date={dueDate}
                    startDate={startDate}
                    icon
                    changeColorDate
                    isOngoingButOverdue={isCardOngoingButOverdue}
                  />
                )}
              <DescIcon desc={desc} />
              <CommentIcon comments={comments} />
              <AttachmentIcon attachments={attachments} />
              <ArchivedIcon archived={archived} />
              <ChecklistCountIcon
                completed={allChecklistItemsCount?.completed}
                total={allChecklistItemsCount?.total}
              />
            </div>
            <div className={styles.imageMemberSection}>
              {members?.map((member) => (
                <DisplayAvatarMember marginRight="6px" marginBottom="3px" src={member?.photoUrl} alt={member?.fullName} size="sm" />
              ))}
            </div>
            {showListAndTeamName && (
            <div className={styles.teamAndList}>
              <DisplayTextSubBody color={Color.gray3}>
                {limitChar(list.name, 16)}
                {' - '}
                {limitChar(team.name, 16)}
              </DisplayTextSubBody>
            </div>
            )}
          </div>
        )}
    </>
  );
}

SmallCard.propTypes = {
  provided: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  archived: PropTypes.bool.isRequired,
  isCardPublic: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  comments: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
  complete: PropTypes.object.isRequired,
  allChecklistItemsCount: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
  clicked: PropTypes.func.isRequired,
  handleShowEdit: PropTypes.func.isRequired,
  handleCancelEditCardMenu: PropTypes.func.isRequired,
  handleCancelAllCardMenu: PropTypes.func.isRequired,
  teamId: PropTypes.string,
  boardId: PropTypes.string,
  borderVariant: PropTypes.oneOf(['semiRounded', 'square']),
  shadowVariant: PropTypes.oneOf(['disabled', 'regular']),
  isCardBlur: PropTypes.bool,
  isCardOngoingButOverdue: PropTypes.bool,
  list: PropTypes.object,
  team: PropTypes.object,
  showListAndTeamName: PropTypes.bool,
};

SmallCard.defaultProps = {
  teamId: undefined,
  boardId: undefined,
  borderVariant: 'semiRounded',
  shadowVariant: 'regular',
  isCardBlur: false,
  isCardOngoingButOverdue: false,
  list: {},
  team: {},
  showListAndTeamName: false,
};

const isComponentDataEqual = (prevProps, nextProps) => {
  const {
    provided, archived, name, desc, labels, members,
    comments, attachments, complete, cardId, dueDate,
    isCardPublic, teamId, borderVariant, shadowVariant,
    isCardBlur, isCardOngoingButOverdue, boardId, startDate,
    allChecklistItemsCount,
  } = nextProps;
  const isStringOrBoolTheSame = prevProps.archived === archived
  && prevProps.name === name
  && prevProps.desc === desc
  && prevProps.dueDate === dueDate
  && prevProps.startDate === startDate
  && prevProps.cardId === cardId
  && prevProps.teamId === teamId
  && prevProps.boardId === boardId
  && prevProps.borderVariant === borderVariant
  && prevProps.shadowVariant === shadowVariant
  && prevProps.isCardBlur === isCardBlur
  && prevProps.isCardOngoingButOverdue === isCardOngoingButOverdue
  && prevProps.isCardPublic === isCardPublic;
  const isObjectTheSame = isEqual(prevProps.provided, provided)
  && isEqual(prevProps.complete, complete)
  && isEqual(prevProps.allChecklistItemsCount, allChecklistItemsCount);
  const isArrayTheSame = isEqual(prevProps.members, members)
    && isEqual(prevProps.comments, comments)
    && isEqual(prevProps.attachments, attachments)
    && isEqual(prevProps.labels, labels);
  const isTheSame = isStringOrBoolTheSame && isObjectTheSame && isArrayTheSame;
  return isTheSame;
};

export default memo(SmallCard, isComponentDataEqual);
