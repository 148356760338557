/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import { debounce } from 'lodash';
import {
  useCallback, useState, useEffect, useContext,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  CheerActions,
  CardActions,
  ActivityActions, ChecklistActions, ChecklistItemActions,
} from '../actions';
import {
  ActivityConstants, CardConstants, ChecklistConstants, ChecklistItemConstants, CheerConstants,
} from '../constants';
import { connectSocketCard } from '../services/socket';
import { useSocketHooks } from './SocketHooks';
import { useInfinityScrollHooks } from './InfinityScrollHooks';
import handleLoadings from '../utilities/handleLoadings';
import handleStatusMsg from '../utilities/handleStatusMsg';

import { GlobalContext } from '../contexts/GlobalStateProvider';

// Deprecated
// function useCardSocket({ cardId, userId }, dispatch) {
//   const updateCardImmediately = (card) => {
//     CardActions.incomingCardUpdate({ card }, dispatch);
//   };
//   const updateCardWithDelay = debounce(
//     (card) => CardActions.incomingCardUpdate({ card }, dispatch),
//     CardConstants.delayUpdateCard,
//   );

//   const listenSocket = (socket, params) => {
//     socket.on(`card-${params?.cardId}`, (card, board, senderId) => {
//       if (senderId === params?.userId) {
//         updateCardImmediately(card);
//       } else {
//         updateCardWithDelay(card);
//       }
//     });
//   };
//   const removeListener = (socket, params) => {
//     socket.off(`card-${params?.cardId}`);
//   };

function useCardV2Socket({ cardId, userId }, dispatch) {
  // Queue function
  // const QueueFn = (function () {
  //   function Queue() {}

  //   Queue.prototype.running = false;

  //   Queue.prototype.queue = [];

  //   Queue.prototype.add_function = function (callback) {
  //     const _this = this;
  //     // add callback to the queue
  //     this.queue.push(() => {
  //       const finished = callback();
  //       if (typeof finished === 'undefined' || finished) {
  //       //  if callback returns `false`, then you have to
  //       //  call `next` somewhere in the callback
  //         _this.next();
  //       }
  //     });

  //     if (!this.running) {
  //     // if nothing is running, then start the engines!
  //       this.next();
  //     }

  //     return this; // for chaining fun!
  //   };

  //   Queue.prototype.next = function () {
  //     this.running = false;
  //     // get the first element off the queue
  //     const shift = this.queue.shift();
  //     if (shift) {
  //       this.running = true;
  //       shift();
  //     }
  //   };

  //   return Queue;
  // }());

  // const queue = new QueueFn();

  /*
    Callback card
  */

  const callbackUpdateCard = useCallback((payload) => {
    // queue.add_function(() => {
    //   const { currentCard, userId } = payload;
    //   CardActions.incomingCardUpdate({ currentCard }, dispatch);
    // });
    const { currentCard, userId } = payload;
    CardActions.incomingCardUpdate({ currentCard }, dispatch);
  }, []);
  const callbackArchiveCard = useCallback(({ currentCard, userId }) => {
    CardActions.incomingCardArchive({ currentCard }, dispatch);
  }, []);
  const callbackChecklistNew = useCallback(({ cardChecklist, userId }) => {
    ChecklistActions.incomingChecklist({
      checklist: cardChecklist,
      typeAction: ChecklistConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackChecklistUpdate = useCallback(({ cardChecklist, userId }) => {
    ChecklistActions.incomingChecklist({
      checklist: cardChecklist,
      typeAction: ChecklistConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackChecklistDelete = useCallback(({ cardChecklistId, userId }) => {
    ChecklistActions.incomingChecklist({
      checklist: { _id: cardChecklistId },
      typeAction: ChecklistConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);
  const callbackChecklistMove = useCallback(({ cardChecklist, position, userId }) => {
    ChecklistActions.incomingChecklistMove({
      checklist: cardChecklist,
      position,
    }, dispatch);
  }, []);
  const callbackChecklistItemNew = useCallback(({ cardChecklistItem, cardChecklistId, userId }) => {
    ChecklistItemActions.incomingChecklistItem({
      checklistItem: cardChecklistItem,
      checklistId: cardChecklistId,
      typeAction: ChecklistItemConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackChecklistItemUpdate = useCallback(({
    cardChecklistItem, cardChecklistId, userId,
  }) => {
    ChecklistItemActions.incomingChecklistItem({
      checklistItem: cardChecklistItem,
      checklistId: cardChecklistId,
      typeAction: ChecklistItemConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);

  const callbackChecklistItemDelete = useCallback(({
    cardChecklistItemId, cardChecklistId, userId,
  }) => {
    ChecklistItemActions.incomingChecklistItem({
      checklistItem: { _id: cardChecklistItemId },
      checklistId: cardChecklistId,
      typeAction: ChecklistItemConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);
  const callbackChecklistItemMove = useCallback(({
    cardChecklistItem,
    sourceCardChecklistId,
    destCardChecklistId,
    position,
    userId,
  }) => {
    ChecklistItemActions.incomingChecklistItemMove({
      checklistItem: cardChecklistItem,
      sourceChecklistId: sourceCardChecklistId,
      destChecklistId: destCardChecklistId,
      position,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('cardChecklistNew', callbackChecklistNew)
      .on('cardChecklistUpdate', callbackChecklistUpdate)
      .on('cardChecklistMove', callbackChecklistMove)
      .on('cardChecklistDelete', callbackChecklistDelete)
      .on('cardChecklistItemNew', callbackChecklistItemNew)
      .on('cardChecklistItemUpdate', callbackChecklistItemUpdate)
      .on('cardChecklistItemMove', callbackChecklistItemMove)
      .on('cardChecklistItemDelete', callbackChecklistItemDelete)
      .on('update', callbackUpdateCard)
      .on('archive', callbackArchiveCard);
  };
  const removeListener = (socket, params) => {
    socket
      .off('update')
      .off('archive');
    socket.disconnect();
  };

  const { socket } = useSocketHooks({
    params: { cardId, userId },
    connectSocket: connectSocketCard,
    listenSocket,
    removeListener,
  });

  return { socket };
}

function useCardCheerNCommentSocket({ cardId, userId }, dispatch) {
  const callbackNewCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      primaryParentAction: ({ updateStateParent }) => CardActions.dispatchUpdateCurrentCard(
        { updateCurrentCard: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);
  const callbackDeleteCheer = useCallback((cheer) => {
    CheerActions.incomingCheerFromPrimaryParent({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      primaryParentAction: ({ updateStateParent }) => CardActions.dispatchUpdateCurrentCard(
        { updateCurrentCard: updateStateParent },
        dispatch,
      ),
      keyProperty: 'cheers',
    });
  }, []);

  const callbackNewComment = useCallback((payload) => {
    const { currentComment, userId } = payload;
    CardActions.incomingCardComment({
      currentComment,
      typeAction: CardConstants.typeCallback.NEW,
    }, dispatch);
  }, []);
  const callbackUpdateComment = useCallback(({ currentComment, userId }) => {
    CardActions.incomingCardComment({
      currentComment,
      typeAction: CardConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteComment = useCallback(({ currentComment, userId }) => {
    CardActions.incomingCardComment({
      currentComment,
      typeAction: CardConstants.typeCallback.DELETE,
    }, dispatch);
  }, []);

  const callbackNewCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParentV2({
      cheer,
      typeAction: CheerConstants.typeCallback.NEW,
      secondaryParentAction: CardActions.incomingCardComment,
      typeActionEditSecondaryParent: CardConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);
  const callbackDeleteCheerComment = useCallback((cheer) => {
    CheerActions.incomingCheerFromSecondaryParentV2({
      cheer,
      typeAction: CheerConstants.typeCallback.DELETE,
      secondaryParentAction: CardActions.incomingCardComment,
      typeActionEditSecondaryParent: CardConstants.typeCallback.EDIT,
    }, dispatch);
  }, []);

  const listenSocket = (socket, params) => {
    socket
      .on('cheer-new', callbackNewCheer)
      .on('cheer-delete', callbackDeleteCheer)
      .on(`cardsComment-new-${params?.cardId}`, callbackNewComment)
      .on(`cardsComment-update-${params?.cardId}`, callbackUpdateComment)
      .on(`cardsComment-delete-${params?.cardId}`, callbackDeleteComment)
      .on('cheerComment-new', callbackNewCheerComment)
      .on('cheerComment-delete', callbackDeleteCheerComment);
  };
  const removeListener = (socket, params) => {
    socket
      .off('cheer-new')
      .off('cheer-delete')
      .off(`cardsComment-new-${params?.cardId}`)
      .off(`cardsComment-update-${params?.cardId}`)
      .off(`cardsComment-delete-${params?.cardId}`)
      .off('cheerComment-new')
      .off('cheerComment-delete');
    socket.disconnect();
  };

  useSocketHooks({
    params: { cardId, userId },
    connectSocket: connectSocketCard,
    listenSocket,
    removeListener,
  });
}

const useCardActivities = ({ showActivities, forceCardId, forceTeamId }) => {
  const [{ currentCompany }, dispatch] = useContext(GlobalContext);
  /* Initiate list data */
  const initialStatePreviousActivities = {
    data: new Array(ActivityConstants.limitActivity),
  };
  const initialStateCurrentActivities = {
    data: [],
  };
  const [previousActivities, setPreviousActivities] = useState(initialStatePreviousActivities);
  const [currentActivities, setCurrentActivities] = useState(initialStateCurrentActivities);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loadings, setLoadings] = useState([]);

  // const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const {
    companyId,
  } = params;

  const teamId = params.teamId || forceTeamId;

  let { cardId } = params;
  if (!cardId) cardId = forceCardId;

  const { enqueueSnackbar } = useSnackbar();

  const getMoreLists = async () => {
    const result = await ActivityActions.loadMoreCardActivities({
      companyId,
      teamId,
      cardId,
      setCurrentActivities,
      setPreviousActivities,
      currentActivities,
    });

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    setPreviousActivities(initialStatePreviousActivities);
    setCurrentActivities(initialStateCurrentActivities);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentActivities,
    previousLists: previousActivities?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: ActivityConstants.limitActivity,
  });

  const initiateActivitiesApi = async () => {
    try {
      const startLoadings = handleLoadings('initiateActivities', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await ActivityActions.initiateCardActivities({
        companyId,
        teamId,
        cardId,
        setCurrentActivities,
        setPreviousActivities,
        initialStatePreviousActivities,
      });

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }

      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateActivities', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (!showActivities) return;
    initiateActivitiesApi();
  }, [showActivities]);

  return {
    listActivities: lists,
    handleLoadMoreListActivities: () => handleLoadMoreLists(),
    checkIfListNeedToLoadActivities: () => checkIfListNeedToLoad(),
    setCheckLoadMoreActivities: () => setCheckLoadMore(),
    isListsLengthAtTheLimitOrAboveActivities: isListsLengthAtTheLimitOrAbove,
    loadingsActivities: loadings,
  };
};

export {
  useCardV2Socket,
  useCardCheerNCommentSocket,
  useCardActivities,
};
