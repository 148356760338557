import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import invariant from 'tiny-invariant';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import styles from './DroppableChecklistItem.module.css';

const DroppableChecklistItem = ({
  index, checklistId, children,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [shouldShowBorderTop, setShouldShowBorderTop] = useState(false);
  const [shouldShowBorderBottom, setShouldShowBorderBottom] = useState(false);

  useEffect(() => {
    const el = ref.current;
    invariant(el, 'Element ref not set');

    return dropTargetForElements({
      element: el,
      onDragEnter: ({ source }) => {
        const isSourceAndDestChecklistIdTheSame = source.data.checklistId === checklistId;
        const isSourceIndexHigherThanDestIndex = source.data.index > index;
        const isSelfDrag = source.data.index === index && isSourceAndDestChecklistIdTheSame;

        if (isSelfDrag === false) {
          if (isSourceAndDestChecklistIdTheSame) {
            if (isSourceIndexHigherThanDestIndex) {
              setShouldShowBorderTop(true);
              setShouldShowBorderBottom(false);
            }
            if (!isSourceIndexHigherThanDestIndex) {
              setShouldShowBorderBottom(true);
              setShouldShowBorderTop(false);
            }
          } else {
            setShouldShowBorderTop(true);
            setShouldShowBorderBottom(false);
          }
        }
        setIsDraggedOver(true);
      },
      getData: () => ({ index, checklistId, type: 'checklistItem' }),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
    });
  }, []);

  const isBorderTop = isDraggedOver && shouldShowBorderTop;
  const isBorderBottom = isDraggedOver && shouldShowBorderBottom;
  return (
    <div
      ref={ref}
      style={{
        borderTop: isBorderTop && '2px solid black',
        borderBottom: isBorderBottom && '2px solid black',
      }}
      className={styles.container}
    >
      {children}
    </div>
  );
};

DroppableChecklistItem.propTypes = {
  children: PropTypes.node,
  checklistId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

DroppableChecklistItem.defaultProps = {
  children: <></>,
};

export default memo(DroppableChecklistItem);
