import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './ListCardUserReport.module.css';
import Color from '../../../../../themes/colors';
import SmallCard from '../../../../ListContainer/SmallCard/SmallCard';
import handleLoadings from '../../../../../utilities/handleLoadings';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import { ReportConstants } from '../../../../../constants';
import { useInfinityScrollHooks } from '../../../../../hooks/InfinityScrollHooks';
import { FeedbackLoadingMain } from '../../../../../components/UI_V2/Feedback/Loading';
import InfinityScroll from '../../../../../components/UI/InfinityScroll/InfinityScroll';
import { handleCompareDate, isDateBeforeNow } from '../../../../../utilities/dateUtil';
import DateSeparator from '../../../../CommentsSectionContainer/DateSeparator/DateSeparator';
import ListItemSkeleton from '../ListItemSkeleton/ListItemSkeleton';
import { initiateReportListCard, loadMoreReportListCard } from '../../../../../actions/ReportActions';

const ListCardUserReport = ({
  fromDate,
  untilDate,
  itemType,
}) => {
  /* Initiate list data */
  const initialStatePreviousReportListCard = {
    data: new Array(ReportConstants.limitReport),
  };
  const initialStateCurrentReportListCard = {
    data: [],
  };
  const [previousReportListCard,
    setPreviousReportListCard] = useState(initialStatePreviousReportListCard);
  const [currentReportListCard,
    setCurrentReportListCard] = useState(initialStateCurrentReportListCard);
  const [loadings, setLoadings] = useState([]);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    companyId, teamId, memberId,
  } = params;

  const { enqueueSnackbar } = useSnackbar();

  const getMoreLists = async () => {
    const result = await loadMoreReportListCard({
      memberId,
      teamId,
      companyId,
      fromDate,
      untilDate,
      itemType,
      setCurrentReportListCard,
      setPreviousReportListCard,
      currentReportListCard,
    });

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    setPreviousReportListCard(initialStatePreviousReportListCard);
    setCurrentReportListCard(initialStateCurrentReportListCard);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentReportListCard,
    previousLists: previousReportListCard?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: ReportConstants.limitReport,
    dontSorting: true,
  });

  const initiateReportListCardApi = async () => {
    try {
      const startLoadings = handleLoadings('initiateReportListCard', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await initiateReportListCard({
        teamId,
        memberId,
        companyId,
        fromDate,
        untilDate,
        itemType,
        setCurrentReportListCard,
        setPreviousReportListCard,
        initialStatePreviousReportListCard,
      });

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateReportListCard', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    initiateReportListCardApi();
  }, [location, fromDate, untilDate, itemType]);

  const isItemTypeComplete = itemType === ReportConstants.typeRef.completed;
  const isItemTypeOngoing = itemType === ReportConstants.typeRef.ongoing;
  const isItemTypeOverdue = itemType === ReportConstants.typeRef.overdue;

  const today = new Date();

  return (
    <div className={styles.container}>
      <FeedbackLoadingMain
        loadingComponent={<ListItemSkeleton />}
        wait="initiateReportListCard"
        loadings={loadings}
      >
        <InfinityScroll
          scrollableTarget="listReportListCard"
          dataLength={lists?.length || 0}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          emptyMessage="Belum ada tugas di kategori ini..."
        >
          {lists.map((item, index) => {
            let isSameDate = handleCompareDate(lists, item, (index - 1), 'createdAt');
            let dateSeparator = item.createdAt;
            let isCardOngoingButOverdue = false;
            if (isItemTypeComplete) {
              isSameDate = handleCompareDate(lists, item, (index - 1), 'setAt', 'complete');
              dateSeparator = item.complete.setAt;
            }

            if (isItemTypeOngoing || isItemTypeOverdue) {
              isSameDate = handleCompareDate(lists, item, (index - 1), 'dueDate');
              dateSeparator = item.dueDate;
            }

            if (isItemTypeOngoing) {
              isCardOngoingButOverdue = isDateBeforeNow(item.dueDate, today);
            }

            if (!isSameDate) {
              return (
                <>
                  <DateSeparator date={dateSeparator} />
                  <div className={styles.card}>
                    <SmallCard
                      name={item.name}
                      desc={item.desc}
                      labels={item.labels}
                      members={item.members}
                      allChecklistItemsCount={item.allChecklistItemsCount}
                      dueDate={item.dueDate}
                      startDate={item.startDate}
                      comments={item.comments}
                      attachments={item.attachments}
                      archived={item.archived.status}
                      complete={item.complete}
                      isCardPublic={item?.isPublic}
                      cardId={item?._id}
                      teamId={item.team}
                      isCardBlur={item?.isForbidden}
                      isCardOngoingButOverdue={isCardOngoingButOverdue}
                    />
                  </div>
                </>
              );
            }
            return (
              <div className={styles.card}>
                <SmallCard
                  name={item.name}
                  desc={item.desc}
                  labels={item.labels}
                  members={item.members}
                  dueDate={item.dueDate}
                  allChecklistItemsCount={item.allChecklistItemsCount}
                  startDate={item.startDate}
                  comments={item.comments}
                  attachments={item.attachments}
                  archived={item.archived.status}
                  complete={item.complete}
                  isCardPublic={item?.isPublic}
                  cardId={item?._id}
                  teamId={item.team}
                  isCardBlur={item?.isForbidden}
                  isCardOngoingButOverdue={isCardOngoingButOverdue}
                />
              </div>
            );
          })}
        </InfinityScroll>
      </FeedbackLoadingMain>
    </div>
  );
};

ListCardUserReport.propTypes = {
  fromDate: PropTypes.string.isRequired,
  untilDate: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
};

ListCardUserReport.defaultProps = {};

export default memo(ListCardUserReport);
