import { ApiConstants } from '../constants';
import { limitActivity, serviceTypeProperty, serviceTypeRef } from '../constants/ActivityConstants';
import apiUtil from '../utilities/apiUtil';
import { ErrorException } from '../utilities/handleError';
import { getLastListDate, mergeObjectListAndRemoveDuplicate } from '../utilities/arrayUtil';
import { dateBeautifyFormatter } from '../utilities/dateUtil';
import { limitChar } from '../utilities/stringUtil';
import { roleTitle } from '../constants/RoleConstants';
import { listCompleteTypeTitle } from '../constants/BoardConstants';
import { NOTIF_ACTIVITY_API_URL } from '../constants/ApiConstants';

const initiateActivities = async ({
  companyId,
  filters,
  setCurrentActivities,
  setPreviousActivities,
  initialStatePreviousActivities,
  limit = limitActivity,
}) => {
  try {
    let params = {
      companyId,
      limit,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterFeatureTypes) {
        params = {
          ...params,
          'filter[moduleTypes]': filters.filterFeatureTypes,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES(), {
      params,
    }, {}, NOTIF_ACTIVITY_API_URL);

    setCurrentActivities(result?.data);
    setPreviousActivities(initialStatePreviousActivities);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreActivities = async ({
  companyId,
  filters,
  setCurrentActivities,
  setPreviousActivities,
  currentActivities,
  limit = limitActivity,
}) => {
  try {
    const createdAt = getLastListDate(currentActivities.data, 'createdAt');

    let params = {
      companyId,
      limit,
      createdAt,
    };

    if (filters) {
      if (filters.filterTeams) {
        params = {
          ...params,
          'filter[teams]': filters.filterTeams,
        };
      }

      if (filters.filterFeatureTypes) {
        params = {
          ...params,
          'filter[moduleTypes]': filters.filterFeatureTypes,
        };
      }
    }

    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES(), {
      params,
    }, {}, NOTIF_ACTIVITY_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentActivities,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousActivities(result?.data);
    setCurrentActivities(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateCardActivities = async ({
  companyId,
  teamId,
  cardId,
  setCurrentActivities,
  setPreviousActivities,
  initialStatePreviousActivities,
  limit = limitActivity,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_CARD({ cardId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    setCurrentActivities(result?.data);
    setPreviousActivities(initialStatePreviousActivities);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreCardActivities = async ({
  companyId,
  teamId,
  cardId,
  setCurrentActivities,
  setPreviousActivities,
  currentActivities,
  limit = limitActivity,
}) => {
  try {
    const createdAt = getLastListDate(currentActivities.data, 'createdAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_CARD({ cardId }), {
      params: {
        companyId,
        teamId,
        limit,
        createdAt,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentActivities,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousActivities(result?.data);
    setCurrentActivities(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiatePostActivities = async ({
  companyId,
  teamId,
  postId,
  setCurrentActivities,
  setPreviousActivities,
  initialStatePreviousActivities,
  limit = limitActivity,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_POST({ postId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    setCurrentActivities(result?.data);
    setPreviousActivities(initialStatePreviousActivities);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMorePostActivities = async ({
  companyId,
  teamId,
  postId,
  setCurrentActivities,
  setPreviousActivities,
  currentActivities,
  limit = limitActivity,
}) => {
  try {
    const createdAt = getLastListDate(currentActivities.data, 'createdAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_POST({ postId }), {
      params: {
        companyId,
        teamId,
        limit,
        createdAt,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentActivities,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousActivities(result?.data);
    setCurrentActivities(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateQuestionActivities = async ({
  companyId,
  teamId,
  questionId,
  setCurrentActivities,
  setPreviousActivities,
  initialStatePreviousActivities,
  limit = limitActivity,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_QUESTION({ questionId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    setCurrentActivities(result?.data);
    setPreviousActivities(initialStatePreviousActivities);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreQuestionActivities = async ({
  companyId,
  teamId,
  questionId,
  setCurrentActivities,
  setPreviousActivities,
  currentActivities,
  limit = limitActivity,
}) => {
  try {
    const createdAt = getLastListDate(currentActivities.data, 'createdAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_QUESTION({ questionId }), {
      params: {
        companyId,
        teamId,
        limit,
        createdAt,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentActivities,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousActivities(result?.data);
    setCurrentActivities(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateDocActivities = async ({
  companyId,
  teamId,
  docId,
  setCurrentActivities,
  setPreviousActivities,
  initialStatePreviousActivities,
  limit = limitActivity,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_DOC({ docId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    setCurrentActivities(result?.data);
    setPreviousActivities(initialStatePreviousActivities);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreDocActivities = async ({
  companyId,
  teamId,
  docId,
  setCurrentActivities,
  setPreviousActivities,
  currentActivities,
  limit = limitActivity,
}) => {
  try {
    const createdAt = getLastListDate(currentActivities.data, 'createdAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_DOC({ docId }), {
      params: {
        companyId,
        teamId,
        limit,
        createdAt,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentActivities,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousActivities(result?.data);
    setCurrentActivities(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateFileActivities = async ({
  companyId,
  teamId,
  fileId,
  setCurrentActivities,
  setPreviousActivities,
  initialStatePreviousActivities,
  limit = limitActivity,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_FILE({ fileId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    setCurrentActivities(result?.data);
    setPreviousActivities(initialStatePreviousActivities);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreFileActivities = async ({
  companyId,
  teamId,
  fileId,
  setCurrentActivities,
  setPreviousActivities,
  currentActivities,
  limit = limitActivity,
}) => {
  try {
    const createdAt = getLastListDate(currentActivities.data, 'createdAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_FILE({ fileId }), {
      params: {
        companyId,
        teamId,
        limit,
        createdAt,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentActivities,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousActivities(result?.data);
    setCurrentActivities(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateEventActivities = async ({
  companyId,
  teamId,
  eventId,
  setCurrentActivities,
  setPreviousActivities,
  initialStatePreviousActivities,
  limit = limitActivity,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_EVENT({ eventId }), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    setCurrentActivities(result?.data);
    setPreviousActivities(initialStatePreviousActivities);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreEventActivities = async ({
  companyId,
  teamId,
  eventId,
  setCurrentActivities,
  setPreviousActivities,
  currentActivities,
  limit = limitActivity,
}) => {
  try {
    const createdAt = getLastListDate(currentActivities.data, 'createdAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_EVENT({ eventId }), {
      params: {
        companyId,
        teamId,
        limit,
        createdAt,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentActivities,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousActivities(result?.data);
    setCurrentActivities(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const initiateOccurrenceActivities = async ({
  companyId,
  teamId,
  eventId,
  occurrenceId,
  setCurrentActivities,
  setPreviousActivities,
  initialStatePreviousActivities,
  limit = limitActivity,
}) => {
  try {
    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_OCCURRENCE(
      { eventId, occurrenceId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    setCurrentActivities(result?.data);
    setPreviousActivities(initialStatePreviousActivities);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const loadMoreOccurrenceActivities = async ({
  companyId,
  teamId,
  eventId,
  occurrenceId,
  setCurrentActivities,
  setPreviousActivities,
  currentActivities,
  limit = limitActivity,
}) => {
  try {
    const createdAt = getLastListDate(currentActivities.data, 'createdAt');

    const result = await apiUtil.get(ApiConstants.URL_V1.ACTIVITIES_OCCURRENCE(
      { eventId, occurrenceId },
    ), {
      params: {
        companyId,
        teamId,
        limit,
        createdAt,
      },
    }, {}, NOTIF_ACTIVITY_API_URL);

    const mergedDataListData = mergeObjectListAndRemoveDuplicate({
      currentObjectList: currentActivities,
      nextObjectList: result?.data,
      keyObject: 'data',
    });

    const mergedListData = {
      ...result?.data,
      data: mergedDataListData?.data,
    };

    setPreviousActivities(result?.data);
    setCurrentActivities(mergedListData);

    return result;
  } catch (error) {
    throw new ErrorException(error);
  }
};

const getActionTextByServiceType = ({
  serviceType,
  item,
  company,
}) => {
  try {
    let actionText = '';
    let locationText = '';
    let activityTitleText = '';
    let contentText = null;
    let clickUrl;
    let isForbidden = false;

    // URL ID
    const companyIdUrl = item?.company;
    const teamIdUrl = item?.team?._id || item?.team;
    const cardIdUrl = item?.card?._id || item?.card;
    const postIdUrl = item?.post?._id || item?.post;
    const questionIdUrl = item?.question?._id || item.question;
    const eventIdUrl = item?.event?._id || item?.event;
    const occurrenceIdUrl = item?.occurrence?._id || item?.occurrence;
    const docIdUrl = item?.doc?._id || item?.doc;
    const fileIdUrl = item?.file?._id || item?.file;
    const boardIdUrl = item?.board?._id || item?.board;
    const bucketIdUrl = item?.bucket?._id || item?.bucket;
    const groupChatIdUrl = item?.groupChat?._id || item?.groupChat;
    const commentIdUrl = item?.comment?._id || item?.comment;
    const discussionIdUrl = item?.discussion?._id || item?.discussion;

    // GENERAL
    const isAddSubscriber = item?.activityPayload?.type === 'add';
    const subscriberName = item?.subscribers?.[0]?.fullName;
    const receiverName = item?.activityPayload?.receiverName || 'seseorang';
    const commentId = item?.activityPayload?.commentId;
    const commentContent = item?.activityPayload?.commentContent;
    const messageContent = item?.activityPayload?.messageContent;
    const cheerContent = item?.activityPayload?.cheerContent;
    const attachmentContent = item?.activityPayload?.attachmentContent;
    const teamName = item?.team?.name;
    const companyName = company?.name;
    const discussionId = item?.activityPayload?.discussionId;
    const discussionContent = item?.activityPayload?.discussionContent;
    const messageId = item?.activityPayload?.messageId;
    const attachmentId = item?.activityPayload?.attachmentId;
    const commentContentMin = commentContent ? limitChar(commentContent, 15, true) : null;
    const discussionContentMin = discussionContent ? limitChar(discussionContent, 15, true) : null;

    // CARD
    const dueDateName = dateBeautifyFormatter(item?.activityPayload?.dueDate, true);
    const startDateName = dateBeautifyFormatter(item?.activityPayload?.startDate, true);
    const listName = item?.activityPayload?.listName;
    const cardName = item?.activityPayload?.cardName;
    const labelNames = item?.activityPayload?.labelNames;
    const attachmentNames = item?.activityPayload?.attachmentNames;

    // CARD CHECKLIST
    const cardChecklistName = item?.activityPayload?.cardChecklistName;
    const position = item?.activityPayload?.position;

    // CARD CHECKLIST ITEM
    const cardChecklistItemName = item?.activityPayload?.cardChecklistItemName;
    const destCardChecklistName = item?.activityPayload?.destCardChecklistName;
    let dateChecklistItemName;
    const startDateChecklistItem = item?.activityPayload?.startDate;
    const dueDateChecklistItem = item?.activityPayload?.dueDate;
    if (startDateChecklistItem) {
      dateChecklistItemName = dateBeautifyFormatter(startDateChecklistItem, true);
    }

    if (dueDateChecklistItem) {
      if (dateChecklistItemName) {
        dateChecklistItemName = `${dateChecklistItemName} - ${dateBeautifyFormatter(dueDateChecklistItem, true)}`;
      } else {
        dateChecklistItemName = dateBeautifyFormatter(dueDateChecklistItem, true);
      }
    }

    // POST
    const postName = item?.activityPayload?.postName;

    // DOC
    const docName = item?.activityPayload?.docName;

    // FILE
    const fileName = item?.activityPayload?.fileName;

    // BUCKET
    const bucketName = item?.activityPayload?.bucketName;

    // QUESTION
    const questionName = item?.activityPayload?.questionName;

    // EVENT
    const eventName = item?.activityPayload?.eventName;

    // OCCURRENCE
    const isUpdateOccurrence = item?.activityPayload?.type === 'updateOccurrence';
    const isUpdateThisAndFollowingOccurrences = item?.activityPayload?.type === 'updateThisAndFollowingOccurrences';
    const isUpdateSingleEventBecomeRecurringOccurrences = item?.activityPayload?.type === 'updateSingleEventBecomeRecurringOccurrences';
    const occurrenceId = item?.activityPayload?.occurrenceId;

    // TEAM
    const teamNamePermanent = item?.activityPayload?.teamName || 'Tim';
    const teamType = item?.activityPayload?.teamType || 'Tim';

    // COMPANY
    const countEmails = item?.activityPayload?.countEmails;

    // ROLE
    const receiverRole = roleTitle[item?.activityPayload?.receiverRole];

    // LIST
    const sourcePos = item?.activityPayload?.sourcePos;
    const destPos = item?.activityPayload?.destPos;
    const listCompleteType = listCompleteTypeTitle[item?.activityPayload?.listCompleteType];

    switch (serviceType) {
      // CARD
      case serviceTypeRef.card:
        actionText = serviceTypeProperty.card.activityAction;
        activityTitleText = serviceTypeProperty.card.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardMember:
        if (isAddSubscriber) {
          actionText = `${serviceTypeProperty.cardMember.activityActionAddInitial} ${receiverName} ${serviceTypeProperty.cardMember.activityActionAdd}`;
        } else {
          actionText = `${serviceTypeProperty.cardMember.activityActionRemoveInitial} ${receiverName} ${serviceTypeProperty.cardMember.activityActionRemove}`;
        }
        activityTitleText = serviceTypeProperty.cardMember.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardMention:
        actionText = `${serviceTypeProperty.cardMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.cardMention.activityAction}`;
        activityTitleText = serviceTypeProperty.cardMention.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardMoved:
        actionText = `${serviceTypeProperty.cardMoved.activityActionInitial} ${cardName} ${serviceTypeProperty.cardMoved.activityAction}`;
        activityTitleText = serviceTypeProperty.cardMoved.activityTitle;
        locationText = listName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardArchivedActivity:
        actionText = serviceTypeProperty.cardArchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.cardArchivedActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUnarchivedActivity:
        actionText = `${serviceTypeProperty.cardUnarchivedActivity.activityActionInitial} ${cardName} ${serviceTypeProperty.cardUnarchivedActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.cardUnarchivedActivity.activityTitle;
        locationText = listName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateLabelsActivity:
        actionText = `${serviceTypeProperty.cardUpdateLabelsActivity.activityActionInitial} ${labelNames} ${serviceTypeProperty.cardUpdateLabelsActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.cardUpdateLabelsActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardAddAttachmentActivity:
        actionText = `${serviceTypeProperty.cardAddAttachmentActivity.activityActionInitial} ${attachmentNames} ${serviceTypeProperty.cardAddAttachmentActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.cardAddAttachmentActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateNameAttachmentActivity:
        actionText = `${serviceTypeProperty.cardUpdateNameAttachmentActivity.activityActionInitial} ${attachmentNames} ${serviceTypeProperty.cardUpdateNameAttachmentActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.cardUpdateNameAttachmentActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardDeleteAttachmentActivity:
        actionText = `${serviceTypeProperty.cardDeleteAttachmentActivity.activityActionInitial} ${attachmentNames} ${serviceTypeProperty.cardDeleteAttachmentActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.cardDeleteAttachmentActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateDueDateActivity:
        actionText = `${serviceTypeProperty.cardUpdateDueDateActivity.activityAction} ${dueDateName} pada tugas`;
        activityTitleText = serviceTypeProperty.cardUpdateDueDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateStartDateActivity:
        actionText = `${serviceTypeProperty.cardUpdateStartDateActivity.activityAction} ${startDateName} pada tugas`;
        activityTitleText = serviceTypeProperty.cardUpdateStartDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateStartDateAndDueDateActivity:
        actionText = `${serviceTypeProperty.cardUpdateStartDateAndDueDateActivity.activityAction} ${startDateName} - ${dueDateName} pada tugas`;
        activityTitleText = serviceTypeProperty.cardUpdateStartDateAndDueDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateUnsetDueDateActivity:
        actionText = serviceTypeProperty.cardUpdateUnsetDueDateActivity.activityAction;
        activityTitleText = serviceTypeProperty.cardUpdateUnsetDueDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateUnsetStartDateActivity:
        actionText = serviceTypeProperty.cardUpdateUnsetStartDateActivity.activityAction;
        activityTitleText = serviceTypeProperty.cardUpdateUnsetStartDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateUnsetStartDateButSetDueDateActivity:
        actionText = serviceTypeProperty
          .cardUpdateUnsetStartDateButSetDueDateActivity.activityAction;
        activityTitleText = serviceTypeProperty
          .cardUpdateUnsetStartDateButSetDueDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateDescActivity:
        actionText = serviceTypeProperty.cardUpdateDescActivity.activityAction;
        activityTitleText = serviceTypeProperty.cardUpdateDescActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateNameActivity:
        actionText = serviceTypeProperty.cardUpdateNameActivity.activityAction;
        activityTitleText = serviceTypeProperty.cardUpdateNameActivity.activityTitle;
        locationText = cardName || cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateSetPublicActivity:
        actionText = serviceTypeProperty.cardUpdateSetPublicActivity.activityAction;
        activityTitleText = serviceTypeProperty.cardUpdateSetPublicActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardUpdateSetPrivateActivity:
        actionText = serviceTypeProperty.cardUpdateSetPrivateActivity.activityAction;
        activityTitleText = serviceTypeProperty.cardUpdateSetPrivateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardRoleUpdateActivity:
        actionText = `${serviceTypeProperty.cardRoleUpdateActivity.activityActionInitial} ${receiverName} ${serviceTypeProperty.cardRoleUpdateActivity.activityAction} ${cardName} ${serviceTypeProperty.cardRoleUpdateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.cardRoleUpdateActivity.activityTitle;
        locationText = receiverRole;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardComment:
        actionText = serviceTypeProperty.cardComment.activityAction;
        activityTitleText = serviceTypeProperty.cardComment.activityTitle;
        contentText = commentContent;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardCommentMention:
        actionText = `${serviceTypeProperty.cardCommentMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.cardCommentMention.activityAction}`;
        activityTitleText = serviceTypeProperty.cardCommentMention.activityTitle;
        contentText = commentContent;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardCommentDeleteActivity:
        actionText = `${serviceTypeProperty.cardCommentDeleteActivity.activityActionInitial} ${`${commentContentMin} - ${commentId}`} ${serviceTypeProperty.cardCommentDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.cardCommentDeleteActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardCommentUpdateActivity:
        actionText = `${serviceTypeProperty.cardCommentUpdateActivity.activityActionInitial} ${`"${commentContentMin}"`} ${serviceTypeProperty.cardCommentUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.cardCommentUpdateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionCardUpdateActivity:
        actionText = `${serviceTypeProperty.commentDiscussionCardUpdateActivity.activityActionInitial} ${`"${discussionContentMin}"`} ${serviceTypeProperty.commentDiscussionCardUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionCardUpdateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionCardDeleteActivity:
        actionText = `${serviceTypeProperty.commentDiscussionCardDeleteActivity.activityActionInitial} ${`${discussionContentMin} - ${discussionId}`} ${serviceTypeProperty.commentDiscussionCardDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionCardDeleteActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionCard:
        actionText = serviceTypeProperty.commentDiscussionCard.activityAction;
        activityTitleText = serviceTypeProperty.commentDiscussionCard.activityTitle;
        contentText = discussionContent;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionMentionCard:
        actionText = `${serviceTypeProperty.commentDiscussionMentionCard.activityActionInitial} ${receiverName} ${serviceTypeProperty.commentDiscussionMentionCard.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionMentionCard.activityTitle;
        contentText = discussionContent;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;

      // CARD CHECKLIST
      case serviceTypeRef.cardChecklistNewActivity:
        actionText = `${serviceTypeProperty.cardChecklistNewActivity.activityActionInitial} ${cardChecklistName} ${serviceTypeProperty.cardChecklistNewActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.cardChecklistNewActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistUpdateNameActivity:
        actionText = `${serviceTypeProperty.cardChecklistUpdateNameActivity.activityActionInitial} ${cardChecklistName} ${serviceTypeProperty.cardChecklistUpdateNameActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.cardChecklistUpdateNameActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistMoveActivity:
        actionText = `${serviceTypeProperty.cardChecklistMoveActivity.activityActionInitial} ${cardChecklistName} ${serviceTypeProperty.cardChecklistMoveActivity.activityAction} ${position + 1} ${serviceTypeProperty.cardChecklistMoveActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.cardChecklistMoveActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistDeleteActivity:
        actionText = `${serviceTypeProperty.cardChecklistDeleteActivity.activityActionInitial} ${cardChecklistName} ${serviceTypeProperty.cardChecklistDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.cardChecklistDeleteActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;

      // CARD CHECKLIST ITEM
      case serviceTypeRef.cardChecklistItemNewActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemNewActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemNewActivity.activityAction} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemNewActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.cardChecklistItemNewActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistItemUpdateNameActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemUpdateNameActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateNameActivity.activityAction} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemUpdateNameActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.cardChecklistItemUpdateNameActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistItemUpdateCompleteStatusActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemUpdateCompleteStatusActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateCompleteStatusActivity.activityAction} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemUpdateCompleteStatusActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty
          .cardChecklistItemUpdateCompleteStatusActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistItemUpdateUnsetCompleteStatusActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemUpdateUnsetCompleteStatusActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateUnsetCompleteStatusActivity.activityAction} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemUpdateUnsetCompleteStatusActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty
          .cardChecklistItemUpdateUnsetCompleteStatusActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistItemUpdateStartDateActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemUpdateStartDateActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateStartDateActivity.activityActionInitialSecond} ${dateChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateStartDateActivity.activityAction} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemUpdateStartDateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty
          .cardChecklistItemUpdateStartDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistItemUpdateStartDateAndDueDateActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemUpdateStartDateAndDueDateActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateStartDateAndDueDateActivity.activityActionInitialSecond} ${dateChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateStartDateAndDueDateActivity.activityAction} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemUpdateStartDateAndDueDateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty
          .cardChecklistItemUpdateStartDateAndDueDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistItemUpdateDueDateActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemUpdateDueDateActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateDueDateActivity.activityActionInitialSecond} ${dateChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateDueDateActivity.activityAction} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemUpdateDueDateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty
          .cardChecklistItemUpdateDueDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistItemUpdateUnsetStartDateActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemUpdateUnsetStartDateActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateUnsetStartDateActivity.activityAction} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemUpdateUnsetStartDateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty
          .cardChecklistItemUpdateUnsetStartDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistItemUpdateUnsetDueDateActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemUpdateUnsetDueDateActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateUnsetDueDateActivity.activityAction} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemUpdateUnsetDueDateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty
          .cardChecklistItemUpdateUnsetDueDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistItemUpdateUnsetStartDateButSetDueDateActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemUpdateUnsetStartDateButSetDueDateActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateUnsetStartDateButSetDueDateActivity.activityActionInitialSecond} ${dateChecklistItemName} ${serviceTypeProperty.cardChecklistItemUpdateUnsetStartDateButSetDueDateActivity.activityAction} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemUpdateUnsetStartDateButSetDueDateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty
          .cardChecklistItemUpdateUnsetStartDateButSetDueDateActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistItemMoveActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemMoveActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemMoveActivity.activityActionInitialSecond} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemMoveActivity.activityActionInitialThird} ${destCardChecklistName} ${serviceTypeProperty.cardChecklistItemMoveActivity.activityAction} ${position + 1} ${serviceTypeProperty.cardChecklistItemMoveActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty
          .cardChecklistItemMoveActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardChecklistItemDeleteActivity:
        actionText = `${serviceTypeProperty.cardChecklistItemDeleteActivity.activityActionInitial} ${cardChecklistItemName} ${serviceTypeProperty.cardChecklistItemDeleteActivity.activityAction} ${cardChecklistName} ${serviceTypeProperty.cardChecklistItemDeleteActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.cardChecklistItemDeleteActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;

      // POST
      case serviceTypeRef.post:
        actionText = serviceTypeProperty.post.activityAction;
        activityTitleText = serviceTypeProperty.post.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postMention:
        actionText = `${serviceTypeProperty.postMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.postMention.activityAction}`;
        activityTitleText = serviceTypeProperty.postMention.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postSubscription:
        if (isAddSubscriber) {
          actionText = `${serviceTypeProperty.postSubscription.activityActionAddInitial} ${receiverName} ${serviceTypeProperty.postSubscription.activityActionAdd}`;
        } else {
          actionText = `${serviceTypeProperty.postSubscription.activityActionRemoveInitial} ${receiverName} ${serviceTypeProperty.postSubscription.activityActionRemove}`;
        }
        activityTitleText = serviceTypeProperty.postSubscription.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postUpdateActivity:
        actionText = serviceTypeProperty.postUpdateActivity.activityAction;
        activityTitleText = serviceTypeProperty.postUpdateActivity.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postArchivedActivity:
        actionText = serviceTypeProperty.postArchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.postArchivedActivity.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postUnarchivedActivity:
        actionText = serviceTypeProperty.postUnarchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.postUnarchivedActivity.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postRoleUpdateActivity:
        actionText = `${serviceTypeProperty.postRoleUpdateActivity.activityActionInitial} ${receiverName} ${serviceTypeProperty.postRoleUpdateActivity.activityAction} ${postName} ${serviceTypeProperty.postRoleUpdateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.postRoleUpdateActivity.activityTitle;
        locationText = receiverRole;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postComment:
        actionText = serviceTypeProperty.postComment.activityAction;
        activityTitleText = serviceTypeProperty.postComment.activityTitle;
        contentText = commentContent;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postCommentMention:
        actionText = `${serviceTypeProperty.postCommentMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.postCommentMention.activityAction}`;
        activityTitleText = serviceTypeProperty.postCommentMention.activityTitle;
        contentText = commentContent;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postCommentDeleteActivity:
        actionText = `${serviceTypeProperty.postCommentDeleteActivity.activityActionInitial} ${`${commentContentMin} - ${commentId}`} ${serviceTypeProperty.postCommentDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.postCommentDeleteActivity.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postCommentUpdateActivity:
        actionText = `${serviceTypeProperty.postCommentUpdateActivity.activityActionInitial} ${`"${commentContentMin}"`} ${serviceTypeProperty.postCommentUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.postCommentUpdateActivity.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionPostUpdateActivity:
        actionText = `${serviceTypeProperty.commentDiscussionPostUpdateActivity.activityActionInitial} ${`"${discussionContentMin}"`} ${serviceTypeProperty.commentDiscussionPostUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionPostUpdateActivity.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionPostDeleteActivity:
        actionText = `${serviceTypeProperty.commentDiscussionPostDeleteActivity.activityActionInitial} ${`${discussionContentMin} - ${discussionId}`} ${serviceTypeProperty.commentDiscussionPostDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionPostDeleteActivity.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionPost:
        actionText = serviceTypeProperty.commentDiscussionPost.activityAction;
        activityTitleText = serviceTypeProperty.commentDiscussionPost.activityTitle;
        contentText = discussionContent;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionMentionPost:
        actionText = `${serviceTypeProperty.commentDiscussionMentionPost.activityActionInitial} ${receiverName} ${serviceTypeProperty.commentDiscussionMentionPost.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionMentionPost.activityTitle;
        contentText = discussionContent;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;

      // DOC
      case serviceTypeRef.doc:
        actionText = serviceTypeProperty.doc.activityAction;
        activityTitleText = serviceTypeProperty.doc.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docMention:
        actionText = `${serviceTypeProperty.docMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.docMention.activityAction}`;
        activityTitleText = serviceTypeProperty.docMention.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docSubscription:
        if (isAddSubscriber) {
          actionText = `${serviceTypeProperty.docSubscription.activityActionAddInitial} ${receiverName} ${serviceTypeProperty.docSubscription.activityActionAdd}`;
        } else {
          actionText = `${serviceTypeProperty.docSubscription.activityActionRemoveInitial} ${receiverName} ${serviceTypeProperty.docSubscription.activityActionRemove}`;
        }
        activityTitleText = serviceTypeProperty.docSubscription.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docUpdateActivity:
        actionText = serviceTypeProperty.docUpdateActivity.activityAction;
        activityTitleText = serviceTypeProperty.docUpdateActivity.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docArchivedActivity:
        actionText = serviceTypeProperty.docArchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.docArchivedActivity.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docUnarchivedActivity:
        actionText = serviceTypeProperty.docUnarchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.docUnarchivedActivity.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docRoleUpdateActivity:
        actionText = `${serviceTypeProperty.docRoleUpdateActivity.activityActionInitial} ${receiverName} ${serviceTypeProperty.docRoleUpdateActivity.activityAction} ${docName} ${serviceTypeProperty.docRoleUpdateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.docRoleUpdateActivity.activityTitle;
        locationText = receiverRole;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docComment:
        actionText = serviceTypeProperty.docComment.activityAction;
        activityTitleText = serviceTypeProperty.docComment.activityTitle;
        contentText = commentContent;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docCommentMention:
        actionText = `${serviceTypeProperty.docCommentMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.docCommentMention.activityAction}`;
        activityTitleText = serviceTypeProperty.docCommentMention.activityTitle;
        contentText = commentContent;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docCommentDeleteActivity:
        actionText = `${serviceTypeProperty.docCommentDeleteActivity.activityActionInitial} ${`${commentContentMin} - ${commentId}`} ${serviceTypeProperty.docCommentDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.docCommentDeleteActivity.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docCommentUpdateActivity:
        actionText = `${serviceTypeProperty.docCommentUpdateActivity.activityActionInitial} ${`"${commentContentMin}"`} ${serviceTypeProperty.docCommentUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.docCommentUpdateActivity.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionDocUpdateActivity:
        actionText = `${serviceTypeProperty.commentDiscussionDocUpdateActivity.activityActionInitial} ${`"${discussionContentMin}"`} ${serviceTypeProperty.commentDiscussionDocUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionDocUpdateActivity.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionDocDeleteActivity:
        actionText = `${serviceTypeProperty.commentDiscussionDocDeleteActivity.activityActionInitial} ${`${discussionContentMin} - ${discussionId}`} ${serviceTypeProperty.commentDiscussionDocDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionDocDeleteActivity.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionDoc:
        actionText = serviceTypeProperty.commentDiscussionDoc.activityAction;
        activityTitleText = serviceTypeProperty.commentDiscussionDoc.activityTitle;
        contentText = discussionContent;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionMentionDoc:
        actionText = `${serviceTypeProperty.commentDiscussionMentionDoc.activityActionInitial} ${receiverName} ${serviceTypeProperty.commentDiscussionMentionDoc.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionMentionDoc.activityTitle;
        contentText = discussionContent;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;

      // QUESTION
      case serviceTypeRef.question:
        actionText = serviceTypeProperty.question.activityAction;
        activityTitleText = serviceTypeProperty.question.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionMention:
        actionText = `${serviceTypeProperty.questionMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.questionMention.activityAction}`;
        activityTitleText = serviceTypeProperty.questionMention.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionSubscription:
        if (isAddSubscriber) {
          actionText = `${serviceTypeProperty.questionSubscription.activityActionAddInitial} ${receiverName} ${serviceTypeProperty.questionSubscription.activityActionAdd}`;
        } else {
          actionText = `${serviceTypeProperty.questionSubscription.activityActionRemoveInitial} ${receiverName} ${serviceTypeProperty.questionSubscription.activityActionRemove}`;
        }
        activityTitleText = serviceTypeProperty.questionSubscription.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionUpdateActivity:
        actionText = serviceTypeProperty.questionUpdateActivity.activityAction;
        activityTitleText = serviceTypeProperty.questionUpdateActivity.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionArchivedActivity:
        actionText = serviceTypeProperty.questionArchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.questionArchivedActivity.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionUnarchivedActivity:
        actionText = serviceTypeProperty.questionUnarchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.questionUnarchivedActivity.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionRoleUpdateActivity:
        actionText = `${serviceTypeProperty.questionRoleUpdateActivity.activityActionInitial} ${receiverName} ${serviceTypeProperty.questionRoleUpdateActivity.activityAction} ${questionName} ${serviceTypeProperty.questionRoleUpdateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.questionRoleUpdateActivity.activityTitle;
        locationText = receiverRole;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionComment:
        actionText = serviceTypeProperty.questionComment.activityAction;
        activityTitleText = serviceTypeProperty.questionComment.activityTitle;
        contentText = commentContent;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionCommentMention:
        actionText = `${serviceTypeProperty.questionCommentMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.questionCommentMention.activityAction}`;
        activityTitleText = serviceTypeProperty.questionCommentMention.activityTitle;
        contentText = commentContent;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionCommentDeleteActivity:
        actionText = `${serviceTypeProperty.questionCommentDeleteActivity.activityActionInitial} ${`${commentContentMin} - ${commentId}`} ${serviceTypeProperty.questionCommentDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.questionCommentDeleteActivity.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionCommentUpdateActivity:
        actionText = `${serviceTypeProperty.questionCommentUpdateActivity.activityActionInitial} ${`"${commentContentMin}"`} ${serviceTypeProperty.questionCommentUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.questionCommentUpdateActivity.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionQuestionUpdateActivity:
        actionText = `${serviceTypeProperty.commentDiscussionQuestionUpdateActivity.activityActionInitial} ${`"${discussionContentMin}"`} ${serviceTypeProperty.commentDiscussionQuestionUpdateActivity.activityAction}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.commentDiscussionQuestionUpdateActivity.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionQuestionDeleteActivity:
        actionText = `${serviceTypeProperty.commentDiscussionQuestionDeleteActivity.activityActionInitial} ${`${discussionContentMin} - ${discussionId}`} ${serviceTypeProperty.commentDiscussionQuestionDeleteActivity.activityAction}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.commentDiscussionQuestionDeleteActivity.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionQuestion:
        actionText = serviceTypeProperty.commentDiscussionQuestion.activityAction;
        activityTitleText = serviceTypeProperty.commentDiscussionQuestion.activityTitle;
        contentText = discussionContent;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionMentionQuestion:
        actionText = `${serviceTypeProperty.commentDiscussionMentionQuestion.activityActionInitial} ${receiverName} ${serviceTypeProperty.commentDiscussionMentionQuestion.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionMentionQuestion.activityTitle;
        contentText = discussionContent;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;

      // EVENT
      case serviceTypeRef.event:
        if (isUpdateOccurrence) {
          actionText = serviceTypeProperty.event.activityActionUpdateOccurrence;
        } else {
          actionText = serviceTypeProperty.event.activityAction;
        }
        activityTitleText = serviceTypeProperty.event.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventMention:
        actionText = `${serviceTypeProperty.eventMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.eventMention.activityAction}`;
        activityTitleText = serviceTypeProperty.eventMention.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventSubscription:
        if (isAddSubscriber) {
          actionText = `${serviceTypeProperty.eventSubscription.activityActionAddInitial} ${receiverName} ${serviceTypeProperty.eventSubscription.activityActionAdd}`;
        } else {
          actionText = `${serviceTypeProperty.eventSubscription.activityActionRemoveInitial} ${receiverName} ${serviceTypeProperty.eventSubscription.activityActionRemove}`;
        }
        activityTitleText = serviceTypeProperty.eventSubscription.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventUpdateActivity:
        actionText = serviceTypeProperty.eventUpdateActivity.activityAction;
        activityTitleText = serviceTypeProperty.eventUpdateActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventArchivedActivity:
        actionText = serviceTypeProperty.eventArchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.eventArchivedActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventUnarchivedActivity:
        actionText = serviceTypeProperty.eventUnarchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.eventUnarchivedActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventRoleUpdateActivity:
        actionText = `${serviceTypeProperty.eventRoleUpdateActivity.activityActionInitial} ${receiverName} ${serviceTypeProperty.eventRoleUpdateActivity.activityAction} ${eventName} ${serviceTypeProperty.eventRoleUpdateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.eventRoleUpdateActivity.activityTitle;
        locationText = receiverRole;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventComment:
        actionText = serviceTypeProperty.eventComment.activityAction;
        activityTitleText = serviceTypeProperty.eventComment.activityTitle;
        contentText = commentContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventCommentMention:
        actionText = `${serviceTypeProperty.eventCommentMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.eventCommentMention.activityAction}`;
        activityTitleText = serviceTypeProperty.eventCommentMention.activityTitle;
        contentText = commentContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventCommentDeleteActivity:
        actionText = `${serviceTypeProperty.eventCommentDeleteActivity.activityActionInitial} ${`${commentContentMin} - ${commentId}`} ${serviceTypeProperty.eventCommentDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.eventCommentDeleteActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventCommentUpdateActivity:
        actionText = `${serviceTypeProperty.eventCommentUpdateActivity.activityActionInitial} ${`"${commentContentMin}"`} ${serviceTypeProperty.eventCommentUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.eventCommentUpdateActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionEventUpdateActivity:
        actionText = `${serviceTypeProperty.commentDiscussionEventUpdateActivity.activityActionInitial} ${`"${discussionContentMin}"`} ${serviceTypeProperty.commentDiscussionEventUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionEventUpdateActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionEventDeleteActivity:
        actionText = `${serviceTypeProperty.commentDiscussionEventDeleteActivity.activityActionInitial} ${`${discussionContentMin} - ${discussionId}`} ${serviceTypeProperty.commentDiscussionEventDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionEventDeleteActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionEvent:
        actionText = serviceTypeProperty.commentDiscussionEvent.activityAction;
        activityTitleText = serviceTypeProperty.commentDiscussionEvent.activityTitle;
        contentText = discussionContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionMentionEvent:
        actionText = `${serviceTypeProperty.commentDiscussionMentionEvent.activityActionInitial} ${receiverName} ${serviceTypeProperty.commentDiscussionMentionEvent.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionMentionEvent.activityTitle;
        contentText = discussionContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;

        // RECURRING EVENT
      case serviceTypeRef.recurringOccurrence:
        if (isUpdateThisAndFollowingOccurrences) {
          actionText = `${serviceTypeProperty.recurringOccurrence.activityActionUpdateThisAndFollowingOccurrenceInitial} ${occurrenceId} ${serviceTypeProperty.recurringOccurrence.activityActionUpdateThisAndFollowingOccurrence}`;
        } else if (isUpdateSingleEventBecomeRecurringOccurrences) {
          // eslint-disable-next-line max-len
          actionText = serviceTypeProperty.recurringOccurrence.activityActionUpdateSingleEventBecomeRecurringOccurrences;
        } else {
          actionText = serviceTypeProperty.recurringOccurrence.activityAction;
        }
        activityTitleText = serviceTypeProperty.recurringOccurrence.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceMention:
        actionText = `${serviceTypeProperty.recurringOccurrenceMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.recurringOccurrenceMention.activityAction}`;
        activityTitleText = serviceTypeProperty.recurringOccurrenceMention.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceSubscription:
        if (isAddSubscriber) {
          actionText = `${serviceTypeProperty.recurringOccurrenceSubscription.activityActionAddInitial} ${receiverName} ${serviceTypeProperty.recurringOccurrenceSubscription.activityActionAdd}`;
        } else {
          actionText = `${serviceTypeProperty.recurringOccurrenceSubscription.activityActionRemoveInitial} ${receiverName} ${serviceTypeProperty.recurringOccurrenceSubscription.activityActionRemove}`;
        }
        activityTitleText = serviceTypeProperty.recurringOccurrenceSubscription.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceUpdateActivity:
        actionText = serviceTypeProperty.recurringOccurrenceUpdateActivity.activityAction;
        activityTitleText = serviceTypeProperty.recurringOccurrenceUpdateActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceArchivedActivity:
        actionText = serviceTypeProperty.recurringOccurrenceArchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.recurringOccurrenceArchivedActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceUnarchivedActivity:
        actionText = serviceTypeProperty.recurringOccurrenceUnarchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.recurringOccurrenceUnarchivedActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceRoleUpdateActivity:
        actionText = `${serviceTypeProperty.recurringOccurrenceRoleUpdateActivity.activityActionInitial} ${receiverName} ${serviceTypeProperty.recurringOccurrenceRoleUpdateActivity.activityAction} ${eventName} ${serviceTypeProperty.recurringOccurrenceRoleUpdateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.recurringOccurrenceRoleUpdateActivity.activityTitle;
        locationText = receiverRole;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceComment:
        actionText = serviceTypeProperty.recurringOccurrenceComment.activityAction;
        activityTitleText = serviceTypeProperty.recurringOccurrenceComment.activityTitle;
        contentText = commentContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceCommentMention:
        actionText = `${serviceTypeProperty.recurringOccurrenceCommentMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.recurringOccurrenceCommentMention.activityAction}`;
        activityTitleText = serviceTypeProperty.recurringOccurrenceCommentMention.activityTitle;
        contentText = commentContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceCommentDeleteActivity:
        actionText = `${serviceTypeProperty.recurringOccurrenceCommentDeleteActivity.activityActionInitial} ${`${commentContentMin} - ${commentId}`} ${serviceTypeProperty.recurringOccurrenceCommentDeleteActivity.activityAction}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.recurringOccurrenceCommentDeleteActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceCommentUpdateActivity:
        actionText = `${serviceTypeProperty.recurringOccurrenceCommentUpdateActivity.activityActionInitial} ${`"${commentContentMin}"`} ${serviceTypeProperty.recurringOccurrenceCommentUpdateActivity.activityAction}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.recurringOccurrenceCommentUpdateActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionRecurringOccurrenceUpdateActivity:
        actionText = `${serviceTypeProperty.commentDiscussionRecurringOccurrenceUpdateActivity.activityActionInitial} ${`"${discussionContentMin}"`} ${serviceTypeProperty.commentDiscussionRecurringOccurrenceUpdateActivity.activityAction}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.commentDiscussionRecurringOccurrenceUpdateActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionRecurringOccurrenceDeleteActivity:
        actionText = `${serviceTypeProperty.commentDiscussionRecurringOccurrenceDeleteActivity.activityActionInitial} ${`${discussionContentMin} - ${discussionId}`} ${serviceTypeProperty.commentDiscussionRecurringOccurrenceDeleteActivity.activityAction}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.commentDiscussionRecurringOccurrenceDeleteActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionRecurringOccurrence:
        actionText = serviceTypeProperty.commentDiscussionRecurringOccurrence.activityAction;
        activityTitleText = serviceTypeProperty.commentDiscussionRecurringOccurrence.activityTitle;
        contentText = discussionContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionMentionRecurringOccurrence:
        actionText = `${serviceTypeProperty.commentDiscussionMentionRecurringOccurrence.activityActionInitial} ${receiverName} ${serviceTypeProperty.commentDiscussionMentionRecurringOccurrence.activityAction}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.commentDiscussionMentionRecurringOccurrence.activityTitle;
        contentText = discussionContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;

        // FILE
      case serviceTypeRef.file:
        actionText = serviceTypeProperty.file.activityAction;
        activityTitleText = serviceTypeProperty.file.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileSubscription:
        if (isAddSubscriber) {
          actionText = `${serviceTypeProperty.fileSubscription.activityActionAddInitial} ${receiverName} ${serviceTypeProperty.fileSubscription.activityActionAdd}`;
        } else {
          actionText = `${serviceTypeProperty.fileSubscription.activityActionRemoveInitial} ${receiverName} ${serviceTypeProperty.fileSubscription.activityActionRemove}`;
        }
        activityTitleText = serviceTypeProperty.fileSubscription.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileUpdateActivity:
        actionText = serviceTypeProperty.fileUpdateActivity.activityAction;
        activityTitleText = serviceTypeProperty.fileUpdateActivity.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileArchivedActivity:
        actionText = serviceTypeProperty.fileArchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.fileArchivedActivity.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileUnarchivedActivity:
        actionText = serviceTypeProperty.fileUnarchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.fileUnarchivedActivity.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileRoleUpdateActivity:
        actionText = `${serviceTypeProperty.fileRoleUpdateActivity.activityActionInitial} ${receiverName} ${serviceTypeProperty.fileRoleUpdateActivity.activityAction} ${fileName} ${serviceTypeProperty.fileRoleUpdateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.fileRoleUpdateActivity.activityTitle;
        locationText = receiverRole;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileComment:
        actionText = serviceTypeProperty.fileComment.activityAction;
        activityTitleText = serviceTypeProperty.fileComment.activityTitle;
        contentText = commentContent;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileCommentMention:
        actionText = `${serviceTypeProperty.fileCommentMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.fileCommentMention.activityAction}`;
        activityTitleText = serviceTypeProperty.fileCommentMention.activityTitle;
        contentText = commentContent;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileCommentDeleteActivity:
        actionText = `${serviceTypeProperty.fileCommentDeleteActivity.activityActionInitial} ${`${commentContentMin} - ${commentId}`} ${serviceTypeProperty.fileCommentDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.fileCommentDeleteActivity.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileCommentUpdateActivity:
        actionText = `${serviceTypeProperty.fileCommentUpdateActivity.activityActionInitial} ${`"${commentContentMin}"`} ${serviceTypeProperty.fileCommentUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.fileCommentUpdateActivity.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionFileUpdateActivity:
        actionText = `${serviceTypeProperty.commentDiscussionFileUpdateActivity.activityActionInitial} ${`"${discussionContentMin}"`} ${serviceTypeProperty.commentDiscussionFileUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionFileUpdateActivity.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionFileDeleteActivity:
        actionText = `${serviceTypeProperty.commentDiscussionFileDeleteActivity.activityActionInitial} ${`${discussionContentMin} - ${discussionId}`} ${serviceTypeProperty.commentDiscussionFileDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionFileDeleteActivity.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionFile:
        actionText = serviceTypeProperty.commentDiscussionFile.activityAction;
        activityTitleText = serviceTypeProperty.commentDiscussionFile.activityTitle;
        contentText = discussionContent;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.commentDiscussionMentionFile:
        actionText = `${serviceTypeProperty.commentDiscussionMentionFile.activityActionInitial} ${receiverName} ${serviceTypeProperty.commentDiscussionMentionFile.activityAction}`;
        activityTitleText = serviceTypeProperty.commentDiscussionMentionFile.activityTitle;
        contentText = discussionContent;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.groupChat:
        actionText = serviceTypeProperty.groupChat.activityAction;
        activityTitleText = serviceTypeProperty.groupChat.activityTitle;
        locationText = teamName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/group-chats/${groupChatIdUrl}`;
        break;
      case serviceTypeRef.groupChatMessage:
        actionText = serviceTypeProperty.groupChatMessage.activityAction;
        activityTitleText = serviceTypeProperty.groupChatMessage.activityTitle;
        contentText = messageContent;
        locationText = teamName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/group-chats/${groupChatIdUrl}`;
        break;
      case serviceTypeRef.groupChatMessageDeleteActivity:
        actionText = `${serviceTypeProperty.groupChatMessageDeleteActivity.activityActionInitial} ${`${messageId}`} ${serviceTypeProperty.groupChatMessageDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.groupChatMessageDeleteActivity.activityTitle;
        locationText = teamName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/group-chats/${groupChatIdUrl}`;
        break;
      case serviceTypeRef.groupChatAttachment:
        actionText = serviceTypeProperty.groupChatAttachment.activityAction;
        activityTitleText = serviceTypeProperty.groupChatAttachment.activityTitle;
        contentText = attachmentContent;
        locationText = teamName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/group-chats/${groupChatIdUrl}`;
        break;
      case serviceTypeRef.groupChatAttachmentDeleteActivity:
        actionText = `${serviceTypeProperty.groupChatAttachmentDeleteActivity.activityActionInitial} ${`${attachmentId}`} ${serviceTypeProperty.groupChatAttachmentDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.groupChatAttachmentDeleteActivity.activityTitle;
        locationText = teamName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/group-chats/${groupChatIdUrl}`;
        break;
      case serviceTypeRef.groupChatMention:
        actionText = `${serviceTypeProperty.groupChatMention.activityActionInitial} ${receiverName} ${serviceTypeProperty.groupChatMention.activityAction}`;
        activityTitleText = serviceTypeProperty.groupChatMention.activityTitle;
        contentText = messageContent;
        locationText = teamName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/group-chats/${groupChatIdUrl}`;
        break;
      // BUCKET
      case serviceTypeRef.bucket:
        actionText = serviceTypeProperty.bucket.activityAction;
        activityTitleText = serviceTypeProperty.bucket.activityTitle;
        locationText = bucketName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/buckets/${bucketIdUrl}`;
        isForbidden = !item?.bucket || !item?.team;
        break;
      case serviceTypeRef.bucketSubscription:
        if (isAddSubscriber) {
          actionText = `${serviceTypeProperty.bucketSubscription.activityActionAddInitial} ${receiverName} ${serviceTypeProperty.bucketSubscription.activityActionAdd}`;
        } else {
          actionText = `${serviceTypeProperty.bucketSubscription.activityActionRemoveInitial} ${receiverName} ${serviceTypeProperty.bucketSubscription.activityActionRemove}`;
        }
        activityTitleText = serviceTypeProperty.bucketSubscription.activityTitle;
        locationText = bucketName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/buckets/${bucketIdUrl}`;
        isForbidden = !item?.bucket || !item?.team;
        break;
      case serviceTypeRef.bucketUpdateActivity:
        actionText = serviceTypeProperty.bucketUpdateActivity.activityAction;
        activityTitleText = serviceTypeProperty.bucketUpdateActivity.activityTitle;
        locationText = bucketName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/buckets/${bucketIdUrl}`;
        isForbidden = !item?.bucket || !item?.team;
        break;
      case serviceTypeRef.bucketArchivedActivity:
        actionText = serviceTypeProperty.bucketArchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.bucketArchivedActivity.activityTitle;
        locationText = bucketName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/buckets/${bucketIdUrl}`;
        isForbidden = !item?.bucket || !item?.team;
        break;
      case serviceTypeRef.bucketUnarchivedActivity:
        actionText = serviceTypeProperty.bucketUnarchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.bucketUnarchivedActivity.activityTitle;
        locationText = bucketName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/buckets/${bucketIdUrl}`;
        isForbidden = !item?.bucket || !item?.team;
        break;
      case serviceTypeRef.bucketRoleUpdateActivity:
        actionText = `${serviceTypeProperty.bucketRoleUpdateActivity.activityActionInitial} ${receiverName} ${serviceTypeProperty.bucketRoleUpdateActivity.activityAction} ${bucketName} ${serviceTypeProperty.bucketRoleUpdateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.bucketRoleUpdateActivity.activityTitle;
        locationText = receiverRole;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/buckets/${bucketIdUrl}`;
        isForbidden = !item?.bucket || !item?.team;
        break;
      case serviceTypeRef.teamCreateActivity:
        actionText = `${serviceTypeProperty.teamCreateActivity.activityAction} ${teamType}`;
        activityTitleText = serviceTypeProperty.teamCreateActivity.activityTitle;
        locationText = teamNamePermanent;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}`;
        isForbidden = !item?.team;
        break;
      case serviceTypeRef.teamUpdateActivity:
        actionText = `${serviceTypeProperty.teamUpdateActivity.activityAction} ${teamType}`;
        activityTitleText = serviceTypeProperty.teamUpdateActivity.activityTitle;
        locationText = teamName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}`;
        isForbidden = !item?.team;
        break;
      case serviceTypeRef.teamArchivedActivity:
        actionText = `${serviceTypeProperty.teamArchivedActivity.activityAction} ${teamType}`;
        activityTitleText = serviceTypeProperty.teamArchivedActivity.activityTitle;
        locationText = teamName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}`;
        isForbidden = !item?.team;
        break;
      case serviceTypeRef.teamUnarchivedActivity:
        actionText = `${serviceTypeProperty.teamUnarchivedActivity.activityAction} ${teamType}`;
        activityTitleText = serviceTypeProperty.teamUnarchivedActivity.activityTitle;
        locationText = teamName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}`;
        isForbidden = !item?.team;
        break;
      case serviceTypeRef.teamSubscriptionActivity:
        if (isAddSubscriber) {
          actionText = `${serviceTypeProperty.teamSubscriptionActivity.activityActionAddInitial} ${receiverName} ${serviceTypeProperty.teamSubscriptionActivity.activityActionAdd} ${teamType}`;
        } else {
          actionText = `${serviceTypeProperty.teamSubscriptionActivity.activityActionRemoveInitial} ${receiverName} ${serviceTypeProperty.teamSubscriptionActivity.activityActionRemove} ${teamType}`;
        }
        activityTitleText = serviceTypeProperty.teamSubscriptionActivity.activityTitle;
        locationText = teamName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}`;
        isForbidden = !item?.team;
        break;
      case serviceTypeRef.teamRoleUpdateActivity:
        actionText = `${serviceTypeProperty.teamRoleUpdateActivity.activityActionInitial} ${receiverName} ${serviceTypeProperty.teamRoleUpdateActivity.activityAction} ${teamName} ${serviceTypeProperty.teamRoleUpdateActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.teamRoleUpdateActivity.activityTitle;
        locationText = receiverRole;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}`;
        isForbidden = !item?.team;
        break;
      case serviceTypeRef.companyInviteMembersActivity:
        actionText = `${serviceTypeProperty.companyInviteMembersActivity.activityActionInitial} ${countEmails} ${serviceTypeProperty.companyInviteMembersActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.companyInviteMembersActivity.activityTitle;
        locationText = companyName;
        clickUrl = `/companies/${companyIdUrl}/settings/members`;
        break;
      case serviceTypeRef.companyJoinMemberActivity:
        actionText = serviceTypeProperty.companyJoinMemberActivity.activityAction;
        activityTitleText = serviceTypeProperty.companyJoinMemberActivity.activityTitle;
        locationText = companyName;
        clickUrl = `/companies/${companyIdUrl}/settings/members`;
        break;
      case serviceTypeRef.companyRemoveMemberActivity:
        actionText = `${serviceTypeProperty.companyRemoveMemberActivity.activityActionInitial} ${receiverName} ${serviceTypeProperty.companyRemoveMemberActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.companyRemoveMemberActivity.activityTitle;
        locationText = companyName;
        clickUrl = `/companies/${companyIdUrl}/settings/members`;
        break;
      case serviceTypeRef.companyUpdateActivity:
        actionText = serviceTypeProperty.companyUpdateActivity.activityAction;
        activityTitleText = serviceTypeProperty.companyUpdateActivity.activityTitle;
        locationText = companyName;
        clickUrl = `/companies/${companyIdUrl}/settings/edit`;
        break;
      case serviceTypeRef.companyUpdateLogoActivity:
        actionText = serviceTypeProperty.companyUpdateLogoActivity.activityAction;
        activityTitleText = serviceTypeProperty.companyUpdateLogoActivity.activityTitle;
        locationText = companyName;
        clickUrl = `/companies/${companyIdUrl}/settings/edit`;
        break;
      case serviceTypeRef.companyRoleUpdateActivity:
        actionText = `${serviceTypeProperty.companyRoleUpdateActivity.activityActionInitial} ${receiverName} ${serviceTypeProperty.companyRoleUpdateActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.companyRoleUpdateActivity.activityTitle;
        locationText = receiverRole;
        clickUrl = `/companies/${companyIdUrl}`;
        break;
      case serviceTypeRef.cardCheersCreate:
        actionText = serviceTypeProperty.cardCheersCreate.activityAction;
        activityTitleText = serviceTypeProperty.cardCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardCommentCheersCreate:
        actionText = `${serviceTypeProperty.cardCommentCheersCreate.activityActionInitial} ${commentContentMin} ${serviceTypeProperty.cardCommentCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.cardCommentCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardCommentDiscussionCheersCreate:
        actionText = `${serviceTypeProperty.cardCommentDiscussionCheersCreate.activityActionInitial} ${discussionContentMin} ${serviceTypeProperty.cardCommentDiscussionCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.cardCommentDiscussionCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.postCheersCreate:
        actionText = serviceTypeProperty.postCheersCreate.activityAction;
        activityTitleText = serviceTypeProperty.postCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postCommentCheersCreate:
        actionText = `${serviceTypeProperty.postCommentCheersCreate.activityActionInitial} ${commentContentMin} ${serviceTypeProperty.postCommentCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.postCommentCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postCommentDiscussionCheersCreate:
        actionText = `${serviceTypeProperty.postCommentDiscussionCheersCreate.activityActionInitial} ${discussionContentMin} ${serviceTypeProperty.postCommentDiscussionCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.postCommentDiscussionCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}/comments/${commentIdUrl}`;
        break;
      case serviceTypeRef.docCheersCreate:
        actionText = serviceTypeProperty.docCheersCreate.activityAction;
        activityTitleText = serviceTypeProperty.docCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docCommentCheersCreate:
        actionText = `${serviceTypeProperty.docCommentCheersCreate.activityActionInitial} ${commentContentMin} ${serviceTypeProperty.docCommentCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.docCommentCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docCommentDiscussionCheersCreate:
        actionText = `${serviceTypeProperty.docCommentDiscussionCheersCreate.activityActionInitial} ${discussionContentMin} ${serviceTypeProperty.docCommentDiscussionCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.docCommentDiscussionCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.fileCheersCreate:
        actionText = serviceTypeProperty.fileCheersCreate.activityAction;
        activityTitleText = serviceTypeProperty.fileCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileCommentCheersCreate:
        actionText = `${serviceTypeProperty.fileCommentCheersCreate.activityActionInitial} ${commentContentMin} ${serviceTypeProperty.fileCommentCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.fileCommentCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileCommentDiscussionCheersCreate:
        actionText = `${serviceTypeProperty.fileCommentDiscussionCheersCreate.activityActionInitial} ${discussionContentMin} ${serviceTypeProperty.fileCommentDiscussionCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.fileCommentDiscussionCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.eventCheersCreate:
        actionText = serviceTypeProperty.eventCheersCreate.activityAction;
        activityTitleText = serviceTypeProperty.eventCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventCommentCheersCreate:
        actionText = `${serviceTypeProperty.eventCommentCheersCreate.activityActionInitial} ${commentContentMin} ${serviceTypeProperty.eventCommentCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.eventCommentCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventCommentDiscussionCheersCreate:
        actionText = `${serviceTypeProperty.eventCommentDiscussionCheersCreate.activityActionInitial} ${discussionContentMin} ${serviceTypeProperty.eventCommentDiscussionCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.eventCommentDiscussionCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceCheersCreate:
        actionText = serviceTypeProperty.recurringOccurrenceCheersCreate.activityAction;
        activityTitleText = serviceTypeProperty.recurringOccurrenceCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceCommentCheersCreate:
        actionText = `${serviceTypeProperty.recurringOccurrenceCommentCheersCreate.activityActionInitial} ${commentContentMin} ${serviceTypeProperty.recurringOccurrenceCommentCheersCreate.activityAction}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.recurringOccurrenceCommentCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceCommentDiscussionCheersCreate:
        actionText = `${serviceTypeProperty.recurringOccurrenceCommentDiscussionCheersCreate.activityActionInitial} ${discussionContentMin} ${serviceTypeProperty.recurringOccurrenceCommentDiscussionCheersCreate.activityAction}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.recurringOccurrenceCommentDiscussionCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.questionCommentCheersCreate:
        actionText = `${serviceTypeProperty.questionCommentCheersCreate.activityActionInitial} ${commentContentMin} ${serviceTypeProperty.questionCommentCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.questionCommentCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionCommentDiscussionCheersCreate:
        actionText = `${serviceTypeProperty.questionCommentDiscussionCheersCreate.activityActionInitial} ${discussionContentMin} ${serviceTypeProperty.questionCommentDiscussionCheersCreate.activityAction}`;
        activityTitleText = serviceTypeProperty.questionCommentDiscussionCheersCreate.activityTitle;
        contentText = cheerContent;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.cardCheerDeleteActivity:
        actionText = `${serviceTypeProperty.cardCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.cardCheerDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.cardCheerDeleteActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardCommentCheerDeleteActivity:
        actionText = `${serviceTypeProperty.cardCommentCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.cardCommentCheerDeleteActivity.activityAction} ${commentContentMin} ${serviceTypeProperty.cardCommentCheerDeleteActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.cardCommentCheerDeleteActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}`;
        isForbidden = !item?.card || !item?.team;
        break;
      case serviceTypeRef.cardCommentDiscussionCheerDeleteActivity:
        actionText = `${serviceTypeProperty.cardCommentDiscussionCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.cardCommentDiscussionCheerDeleteActivity.activityAction} ${discussionContentMin} ${serviceTypeProperty.cardCommentDiscussionCheerDeleteActivity.activityActionEnd}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.cardCommentDiscussionCheerDeleteActivity.activityTitle;
        locationText = cardName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/cards/${cardIdUrl}/comments/${commentId}`;
        break;
      case serviceTypeRef.postCheerDeleteActivity:
        actionText = `${serviceTypeProperty.postCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.postCheerDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.postCheerDeleteActivity.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postCommentCheerDeleteActivity:
        actionText = `${serviceTypeProperty.postCommentCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.postCommentCheerDeleteActivity.activityAction} ${commentContentMin} ${serviceTypeProperty.postCommentCheerDeleteActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.postCommentCheerDeleteActivity.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}`;
        isForbidden = !item?.post || !item?.team;
        break;
      case serviceTypeRef.postCommentDiscussionCheerDeleteActivity:
        actionText = `${serviceTypeProperty.postCommentDiscussionCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.postCommentDiscussionCheerDeleteActivity.activityAction} ${discussionContentMin} ${serviceTypeProperty.postCommentDiscussionCheerDeleteActivity.activityActionEnd}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.postCommentDiscussionCheerDeleteActivity.activityTitle;
        locationText = postName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/posts/${postIdUrl}/comments/${commentIdUrl}`;
        break;
      case serviceTypeRef.eventCheerDeleteActivity:
        actionText = `${serviceTypeProperty.eventCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.eventCheerDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.eventCheerDeleteActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventCommentCheerDeleteActivity:
        actionText = `${serviceTypeProperty.eventCommentCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.eventCommentCheerDeleteActivity.activityAction} ${commentContentMin} ${serviceTypeProperty.eventCommentCheerDeleteActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.eventCommentCheerDeleteActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.eventCommentDiscussionCheerDeleteActivity:
        actionText = `${serviceTypeProperty.eventCommentDiscussionCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.eventCommentDiscussionCheerDeleteActivity.activityAction} ${discussionContentMin} ${serviceTypeProperty.eventCommentDiscussionCheerDeleteActivity.activityActionEnd}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.eventCommentDiscussionCheerDeleteActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceCheerDeleteActivity:
        actionText = `${serviceTypeProperty.recurringOccurrenceCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.recurringOccurrenceCheerDeleteActivity.activityAction}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.recurringOccurrenceCheerDeleteActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceId}`;
        break;
      case serviceTypeRef.recurringOccurrenceCommentCheerDeleteActivity:
        actionText = `${serviceTypeProperty.recurringOccurrenceCommentCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.recurringOccurrenceCommentCheerDeleteActivity.activityAction} ${commentContentMin} ${serviceTypeProperty.recurringOccurrenceCommentCheerDeleteActivity.activityActionEnd}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.recurringOccurrenceCommentCheerDeleteActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceId}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.recurringOccurrenceCommentDiscussionCheerDeleteActivity:
        actionText = `${serviceTypeProperty.recurringOccurrenceCommentDiscussionCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.recurringOccurrenceCommentDiscussionCheerDeleteActivity.activityAction} ${discussionContentMin} ${serviceTypeProperty.recurringOccurrenceCommentDiscussionCheerDeleteActivity.activityActionEnd}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.recurringOccurrenceCommentDiscussionCheerDeleteActivity.activityTitle;
        locationText = eventName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/events/${eventIdUrl}/occurrences/${occurrenceId}/comments/${commentIdUrl}`;
        isForbidden = !item?.event || !item?.team;
        break;
      case serviceTypeRef.docCheerDeleteActivity:
        actionText = `${serviceTypeProperty.docCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.docCheerDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.docCheerDeleteActivity.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docCommentCheerDeleteActivity:
        actionText = `${serviceTypeProperty.docCommentCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.docCommentCheerDeleteActivity.activityAction} ${commentContentMin} ${serviceTypeProperty.docCommentCheerDeleteActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.docCommentCheerDeleteActivity.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.docCommentDiscussionCheerDeleteActivity:
        actionText = `${serviceTypeProperty.docCommentDiscussionCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.docCommentDiscussionCheerDeleteActivity.activityAction} ${discussionContentMin} ${serviceTypeProperty.docCommentDiscussionCheerDeleteActivity.activityActionEnd}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.docCommentDiscussionCheerDeleteActivity.activityTitle;
        locationText = docName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/docs/${docIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.doc || !item?.team;
        break;
      case serviceTypeRef.fileCheerDeleteActivity:
        actionText = `${serviceTypeProperty.fileCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.fileCheerDeleteActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.fileCheerDeleteActivity.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileCommentCheerDeleteActivity:
        actionText = `${serviceTypeProperty.fileCommentCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.fileCommentCheerDeleteActivity.activityAction} ${commentContentMin} ${serviceTypeProperty.fileCommentCheerDeleteActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.fileCommentCheerDeleteActivity.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.fileCommentDiscussionCheerDeleteActivity:
        actionText = `${serviceTypeProperty.fileCommentDiscussionCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.fileCommentDiscussionCheerDeleteActivity.activityAction} ${discussionContentMin} ${serviceTypeProperty.fileCommentDiscussionCheerDeleteActivity.activityActionEnd}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.fileCommentDiscussionCheerDeleteActivity.activityTitle;
        locationText = fileName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/files/${fileIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.file || !item?.team;
        break;
      case serviceTypeRef.questionCommentCheerDeleteActivity:
        actionText = `${serviceTypeProperty.questionCommentCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.questionCommentCheerDeleteActivity.activityAction} ${commentContentMin} ${serviceTypeProperty.questionCommentCheerDeleteActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.questionCommentCheerDeleteActivity.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.questionCommentDiscussionCheerDeleteActivity:
        actionText = `${serviceTypeProperty.questionCommentDiscussionCheerDeleteActivity.activityActionInitial} ${cheerContent} ${serviceTypeProperty.questionCommentDiscussionCheerDeleteActivity.activityAction} ${discussionContentMin} ${serviceTypeProperty.questionCommentDiscussionCheerDeleteActivity.activityActionEnd}`;
        // eslint-disable-next-line max-len
        activityTitleText = serviceTypeProperty.questionCommentDiscussionCheerDeleteActivity.activityTitle;
        locationText = questionName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/questions/${questionIdUrl}/comments/${commentIdUrl}`;
        isForbidden = !item?.question || !item?.team;
        break;
      case serviceTypeRef.listCreateActivity:
        actionText = serviceTypeProperty.listCreateActivity.activityAction;
        activityTitleText = serviceTypeProperty.listCreateActivity.activityTitle;
        locationText = listName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/boards/${boardIdUrl}`;
        isForbidden = !item?.board || !item?.team;
        break;
      case serviceTypeRef.listUpdateNameActivity:
        actionText = serviceTypeProperty.listUpdateNameActivity.activityAction;
        activityTitleText = serviceTypeProperty.listUpdateNameActivity.activityTitle;
        locationText = listName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/boards/${boardIdUrl}`;
        isForbidden = !item?.board || !item?.team;
        break;
      case serviceTypeRef.listMovedActivity:
        actionText = `${serviceTypeProperty.listMovedActivity.activityActionInitial} ${listName} ${serviceTypeProperty.listMovedActivity.activityAction} ${sourcePos} ${serviceTypeProperty.listMovedActivity.activityActionEnd}`;
        activityTitleText = serviceTypeProperty.listMovedActivity.activityTitle;
        locationText = destPos;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/boards/${boardIdUrl}`;
        isForbidden = !item?.board || !item?.team;
        break;
      case serviceTypeRef.listSetCompleteStatusActivity:
        actionText = `${serviceTypeProperty.listSetCompleteStatusActivity.activityActionInitial} ${listName} ${serviceTypeProperty.listSetCompleteStatusActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.listSetCompleteStatusActivity.activityTitle;
        locationText = 'Selesai';
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/boards/${boardIdUrl}`;
        isForbidden = !item?.board || !item?.team;
        break;
      case serviceTypeRef.listUnsetCompleteStatusActivity:
        actionText = `${serviceTypeProperty.listUnsetCompleteStatusActivity.activityActionInitial} ${listName} ${serviceTypeProperty.listUnsetCompleteStatusActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.listUnsetCompleteStatusActivity.activityTitle;
        locationText = 'Biasa';
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/boards/${boardIdUrl}`;
        isForbidden = !item?.board || !item?.team;
        break;
      case serviceTypeRef.listArchivedActivity:
        actionText = serviceTypeProperty.listArchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.listArchivedActivity.activityTitle;
        locationText = listName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/boards/${boardIdUrl}`;
        isForbidden = !item?.board || !item?.team;
        break;
      case serviceTypeRef.listUnarchivedActivity:
        actionText = serviceTypeProperty.listUnarchivedActivity.activityAction;
        activityTitleText = serviceTypeProperty.listUnarchivedActivity.activityTitle;
        locationText = listName;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/boards/${boardIdUrl}`;
        isForbidden = !item?.board || !item?.team;
        break;
      case serviceTypeRef.listUpdateTypeCompleteStatusActivity:
        actionText = `${serviceTypeProperty.listUpdateTypeCompleteStatusActivity.activityActionInitial} ${listName} ${serviceTypeProperty.listUpdateTypeCompleteStatusActivity.activityAction}`;
        activityTitleText = serviceTypeProperty.listUpdateTypeCompleteStatusActivity.activityTitle;
        locationText = listCompleteType;
        clickUrl = `/companies/${companyIdUrl}/teams/${teamIdUrl}/boards/${boardIdUrl}`;
        isForbidden = !item?.board || !item?.team;
        break;
      default:
        actionText = 'beraktifitas';
        break;
    }

    return [actionText, locationText, activityTitleText, contentText, clickUrl, isForbidden];
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getListActivityVariantByCommentSectionType = ({
  type,
}) => {
  try {
    let variant;
    switch (type) {
      case 'card':
        variant = serviceTypeRef.card;
        break;
      case 'blast':
        variant = serviceTypeRef.post;
        break;
      case 'occurrence':
        variant = serviceTypeRef.recurringOccurrence;
        break;
      case 'question':
        variant = serviceTypeRef.question;
        break;
      case 'event':
        variant = serviceTypeRef.event;
        break;
      case 'doc':
        variant = serviceTypeRef.doc;
        break;
      case 'file':
        variant = serviceTypeRef.file;
        break;
      default:
        break;
    }

    return variant;
  } catch (error) {
    throw new ErrorException(error);
  }
};

export {
  initiateActivities,
  loadMoreActivities,
  initiateCardActivities,
  loadMoreCardActivities,
  initiatePostActivities,
  loadMorePostActivities,
  initiateQuestionActivities,
  loadMoreQuestionActivities,
  initiateDocActivities,
  loadMoreDocActivities,
  initiateFileActivities,
  loadMoreFileActivities,
  initiateEventActivities,
  loadMoreEventActivities,
  initiateOccurrenceActivities,
  loadMoreOccurrenceActivities,
  getActionTextByServiceType,
  getListActivityVariantByCommentSectionType,
};
