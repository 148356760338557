import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useInView } from 'react-intersection-observer';
import styles from './ListChecklistItems.module.css';
import Color from '../../../../../themes/colors';
import { InputButtonMain } from '../../../../../components/UI_V2/Input/Button';
import ChecklistItem from './ChecklistItem/ChecklistItem';
import { GlobalContext } from '../../../../../contexts/GlobalStateProvider';
import { ChecklistItemActions } from '../../../../../actions';
import { useInfinityScrollHooks } from '../../../../../hooks/InfinityScrollHooks';
import { ChecklistItemConstants } from '../../../../../constants';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import InfinityScroll from '../../../../../components/UI/InfinityScroll/InfinityScroll';
import { FeedbackSpinnerGeneral } from '../../../../../components/UI_V2/Feedback/Spinner';
import DroppableChecklistItem from './DroppableChecklistItem/DroppableChecklistItem';

const ListChecklistItems = ({
  cardId,
  checklistId,
  checklistItemsCountTotal,
  currentChecklistItems,
  initialStatePreviousChecklistItems,
  previousChecklistItems,
  setPreviousChecklistItems,
  handleDeleteChecklistItem,
  movedChecklistItemId,
}) => {
  const [{ user }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');
  const [loadings, setLoadings] = useState([]);

  const params = useParams();

  const { companyId, teamId } = params;

  const { enqueueSnackbar } = useSnackbar();

  const getMoreLists = async () => {
    const result = await ChecklistItemActions.loadMoreChecklistItems({
      currentChecklistItems,
      cardId,
      teamId,
      checklistId,
      companyId,
      // setCurrentChecklistItems,
      setPreviousChecklistItems,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    setPreviousChecklistItems(initialStatePreviousChecklistItems);
    // setCurrentChecklistItems(initialStateCurrentChecklistItems);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentChecklistItems,
    previousLists: previousChecklistItems?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: ChecklistItemConstants.limitChecklistItem,
    dontSorting: true,
    forceTriggerUpdateOnDataIndexOrderChange: true,
  });

  const isChecklistItemsEmpty = lists.length < 1;

  useEffect(() => {
    if (!user._id || !companyId || !checklistId) {
      return;
    }
    if (currentChecklistItems?.data?.length < ChecklistItemConstants.limitChecklistItem
      && checklistItemsCountTotal === currentChecklistItems?.data?.length) {
      setCheckLoadMore(false);
    } else {
      setCheckLoadMore(true);
    }
  }, [user, companyId, checklistId, JSON.stringify(currentChecklistItems)]);

  // need using observerintersection
  // for infinite scroll comment inside modal dialog
  const { ref, inView, entry } = useInView();
  useEffect(() => {
    if (inView) {
      if (checkIfListNeedToLoad()) {
        handleLoadMoreLists();
      }
    }
  }, [inView]);
  return (
    <InfinityScroll
                // scrollableTarget="listSidebar"
      dataLength={lists?.length}
      hasMore={checkIfListNeedToLoad()}
      next={handleLoadMoreLists}
      hideEndMessage
      loader={(<></>)}
    >
      {!isChecklistItemsEmpty && lists.map((checklistItem, index) => (
        <DroppableChecklistItem
          index={index}
          checklistId={checklistId}
        >
          <ChecklistItem
            checklistItem={checklistItem}
            index={index}
            checklistId={checklistId}
            cardId={cardId}
            teamId={teamId}
            companyId={companyId}
            handleDeleteChecklistItem={handleDeleteChecklistItem}
            movedChecklistItemId={movedChecklistItemId}
          />
        </DroppableChecklistItem>
      ))}
      {checkIfListNeedToLoad() && (
      <div ref={ref} className={styles.infiniteScroll}>
        <FeedbackSpinnerGeneral size={30} color={Color.yellowAccentCicle} />
      </div>
      )}
    </InfinityScroll>
  );
};

ListChecklistItems.propTypes = {
  cardId: PropTypes.string.isRequired,
  checklistId: PropTypes.string.isRequired,
  currentChecklistItems: PropTypes.object.isRequired,
  initialStatePreviousChecklistItems: PropTypes.array.isRequired,
  previousChecklistItems: PropTypes.array.isRequired,
  setPreviousChecklistItems: PropTypes.func.isRequired,
  handleDeleteChecklistItem: PropTypes.func.isRequired,
  movedChecklistItemId: PropTypes.string.isRequired,
  checklistItemsCountTotal: PropTypes.number.isRequired,
};

ListChecklistItems.defaultProps = {
};

export default memo(ListChecklistItems);
