import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  BillingConstants,
  BlastConstants, BoardConstants, BucketConstants, CardConstants, ChatConstants, CheckInConstants,
  ChecklistConstants,
  ChecklistItemConstants,
  CheerConstants, CommentConstants, CouponConstants, EventConstants,
  MyTaskCardConstants,
  NotificationConstants,
  RoleConstants,
  TeamFavoriteConstants,
} from '../constants';
import Reducer from '../reducers/reducer';

export const initialState = {
  isAuthenticated: false,
  isSidebarOpen: false,
  token: '',
  user: {},
  currentCompanies: [],
  defaultCompany: {},
  currentCompany: { members: [] },
  teams: [],
  sortedTeams: [],
  allUserOnline: [],
  allUserTypingChat: [],
  allUserTypingGroupChat: [],
  allUserTypingCard: [],
  allUserTypingPost: [],
  allUserTypingEvent: [],
  allUserTypingOccurrence: [],
  allUserTypingDoc: [],
  allUserTypingFile: [],
  allUserTypingQuestion: [],
  allUserTypingDiscussion: [],
  searchList: [],
  currentTeam: {},
  currentTeamBelowRole: {},
  currentTeamMembers: [],
  currentTeamBoards: [],
  currentBoard: {},
  currentBoardLabels: [],
  currentBoardLists: [],
  filteredLists: [],
  currentCard: {},
  currentCardList: {},
  colors: [],
  previousChat: {
    chats: new Array(ChatConstants.limitChat),
  },
  previousGroupChat: {
    groupChats: new Array(ChatConstants.limitChat),
  },
  previousCheckIn: {
    questions: new Array(CheckInConstants.limitCheckIn),
  },
  previousBlast: new Array(BlastConstants.limitBlast),
  previousMyTaskCardListAll: {
    data: new Array(MyTaskCardConstants.limitMyTaskCardList),
  },
  previousBoardCardListAll: {
    data: new Array(BoardConstants.boardCardListLimit),
  },
  previousBoardCardCalendarAll: {
    data: new Array(BoardConstants.boardCardListLimit),
  },
  previousMyTaskCardCalendarAll: {
    data: new Array(MyTaskCardConstants.limitMyTaskCardCalendar),
  },
  previousInvoices: {
    data: new Array(BillingConstants.limitInvoices),
  },
  previousChecklists: {
    data: new Array(ChecklistConstants.limitChecklist),
  },
  previousChecklistItems: {
    data: new Array(ChecklistItemConstants.limitChecklistItem),
  },
  previousBucketItems: {
    data: new Array(BucketConstants.limitBucket),
  },
  previousSubscriptions: {
    data: new Array(BillingConstants.limitSubscriptions),
  },
  previousRoleSuperUsers: {
    data: new Array(RoleConstants.limitRoleSuperUsers),
  },
  previousRoleUsers: {
    data: new Array(RoleConstants.limitRoleUsers),
  },
  previousCoupons: {
    data: new Array(CouponConstants.limitCoupons),
  },
  previousTeamFavorites: {
    data: new Array(TeamFavoriteConstants.limitTeamFavorites),
  },
  previousBlastComment: new Array(CommentConstants.limitComment),
  previousCheckInComment: new Array(CommentConstants.limitComment),
  previousDiscussionComment: new Array(CommentConstants.limitComment),
  previousCardComment: new Array(CardConstants.limitComment),
  previousEventComment: new Array(EventConstants.limitComment),
  previousDocComment: new Array(CommentConstants.limitComment),
  previousFileComment: new Array(CommentConstants.limitComment),
  previousBoardList: [],
  currentChat: {},
  currentChatUnreadMessagesCounter: 0,
  currentInvoicesUnpaidCounter: 0,
  recentChats: [],
  currentGroupChat: {},
  currentBlast: {},
  currentRoleUser: { role: '' },
  currentMyTaskCardListAll: {
    data: [],
  },
  currentBoardCardListAll: {
    data: [],
  },
  currentBoardCardCalendarAll: {
    data: [],
  },
  currentMyTaskCardCalendarAll: {
    data: [],
  },
  currentInvoices: {
    data: [],
  },
  currentSubscriptions: {
    data: [],
  },
  currentRoleSuperUsers: {
    data: [],
  },
  currentRoleUsers: {
    data: [],
  },
  currentCoupons: {
    data: [],
  },
  currentTeamFavorites: {
    data: [],
  },
  currentBlastPost: {},
  currentChecklists: {
    data: [],
  },
  currentChecklistItems: {
    data: [],
  },
  allNotifications: {
    data: [],
    total: NotificationConstants.limitNotif,
    isFirstLoad: true,
  },
  cheersNotifications: {
    data: [],
    total: CheerConstants.limitListCheer,
  },
  counterNotif: { chat: 0, nonChat: 0, isFirstLoad: true },
  counterTaskNotif: { totalCounter: 0 },
  counterCompaniesNotif: { totalCounter: 0, companies: [] },
  counterTeamsNotif: [],
  currentNotifications: {
    data: [],
    total: NotificationConstants.limitNotif,
    isFirstLoad: true,
  },
  chatNotifications: [],
  currentChatNotifications: [],
  currentCheckIn: {},
  currentCheckInQuestion: {},
  currentSchedule: {},
  currentScheduleEvent: {},
  currentOccurrence: {},
  currentBucket: {},
  currentBucketDoc: {},
  currentBucketFile: {},
  currentBucketItems: {
    data: [],
  },
  currentComment: {},
  currentCommentDiscussions: [],
  bucketDocPath: [],
  errorResponse: {},
  showPrivateChat: false,
  showNotification: false,
  showActivityMenu: false,
  showCompaniesSelect: false,
  showUserMenu: false,
  showEditTeamMenu: false,
  showEditTeamFavoriteMenu: false,
  selectedEditTeam: {},
  selectedEditTeamFavorite: {},
  isDelayShowActivityMenu: false,
  isDelayShowNotifMenu: false,
  isDelayShowCompaniesMenu: false,
  isDelayShowUserMenu: false,
  isDelayShowEditTeamMenu: false,
  isDelayShowEditTeamFavoriteMenu: false,
  isOpenTeamFromFavorite: false,
  shouldCheckTeamsColor: false,
  editCardItems: { card: {}, list: {} },
  isFavoriteListEmpty: false,
};

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  );
};

GlobalStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const GlobalContext = createContext(initialState);
export default GlobalStateProvider;
