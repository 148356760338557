import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useMediaQuery } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import styles from './MarkChecklistItem.module.css';
import Color from '../../../../../../../themes/colors';
import handleLoadings from '../../../../../../../utilities/handleLoadings';
import { updateCompleteStatusChecklistItemApi } from '../../../../../../../actions/ChecklistItemActions';
import handleStatusMsg from '../../../../../../../utilities/handleStatusMsg';

const completedIconStyles = {
  true: styles.iconTrue,
  false: styles.icon,
};

const MarkChecklistItem = ({
  checklistItem,
  teamId,
  cardId,
  companyId,
  checklistId,
  setIsCompleted,
  isCompleted,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [loadings, setLoadings] = useState([]);
  const handleCompleteStatusApi = async (value) => {
    try {
      const startLoadings = handleLoadings('editChecklistItemName', [...loadings], 'start');
      setLoadings([...startLoadings]);

      setIsCompleted(value);

      const result = await updateCompleteStatusChecklistItemApi({
        cardId,
        checklistId,
        checklistItemId: checklistItem._id,
        companyId,
        teamId,
        status: value,
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });
    } catch (error) {
      const status = handleStatusMsg(error, 'error');
      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      const endLoadings = handleLoadings('editChecklistItemName', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };
  return (
    <div
      className={completedIconStyles[isCompleted]}
      onClick={
        () => handleCompleteStatusApi(!isCompleted)
        }
    >
      {isCompleted ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
    </div>
  );
};

MarkChecklistItem.propTypes = {
  checklistItem: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  checklistId: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  setIsCompleted: PropTypes.func.isRequired,
};

MarkChecklistItem.defaultProps = {};

export default memo(MarkChecklistItem);
