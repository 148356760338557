import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import { FeedbackDialogAlert } from '../../../../../components/UI_V2/Feedback/Dialog';
import handleLoadings from '../../../../../utilities/handleLoadings';
import { deleteChecklistApi } from '../../../../../actions/ChecklistActions';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';

const DeleteChecklist = ({
  showDeleteChecklist,
  handleHideDeleteChecklist,
  cardId,
  deletedChecklistId,
  companyId,
  teamId,
}) => {
  const [loadings, setLoadings] = useState([]);

  const handleDeleteChecklistApi = async () => {
    try {
      const startLoadings = handleLoadings('deleteChecklist', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await deleteChecklistApi({
        cardId,
        checklistId: deletedChecklistId,
        companyId,
        teamId,
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      handleHideDeleteChecklist();
    } catch (error) {
      const status = handleStatusMsg(error, 'error');
      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      const endLoadings = handleLoadings('deleteChecklist', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  return (
    <FeedbackDialogAlert
      open={showDeleteChecklist}
      onClose={handleHideDeleteChecklist}
      handleConfirmNo={handleHideDeleteChecklist}
      handleConfirmYes={handleDeleteChecklistApi}
      variantButtonYes="danger"
      waitYes="deleteChecklist"
      loadings={loadings}
      labelYes="Hapus"
      labelNo="Batal"
      title="Hapus ceklis ini?"
      content="Ceklis ini akan dihapus beserta seluruh sub tugasnya"
    />
  );
};

DeleteChecklist.propTypes = {
  showDeleteChecklist: PropTypes.bool.isRequired,
  handleHideDeleteChecklist: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  deletedChecklistId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
};

DeleteChecklist.defaultProps = {};

export default memo(DeleteChecklist);
