import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import styles from './CardListHeader.module.css';
import Color from '../../../themes/colors';
import CardListColumnItem from './CardListColumnItem/CardListColumnItem';

const CardListHeader = () => (
  <div className={styles.container}>
    <CardListColumnItem
      text="Nama Tugas"
      widthPercentage={36}
    />
    <CardListColumnItem
      text="Tim"
      widthPercentage={12}
    />
    <CardListColumnItem
      text="List"
      widthPercentage={12}
    />
    <CardListColumnItem
      text="Tanggal"
      widthPercentage={12}
    />
    <CardListColumnItem
      text="Anggota"
      widthPercentage={14}
    />
    <CardListColumnItem
      text="Label"
      widthPercentage={14}
      variantBorder="noLeftRightBorder"
    />
  </div>
);

CardListHeader.propTypes = {};

CardListHeader.defaultProps = {};

export default memo(CardListHeader);
