import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Paper, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import OutsideClickHandler from 'react-outside-click-handler';
import Color from '../../../../../themes/colors';

const defaultStyles = {
  position: 'absolute',
  top: '15px',
  zIndex: 3,
  border: '1px solid #DEEAFF',
  display: 'block',
};

const positionStyles = {
  topLeft: {
    top: '30px',
    left: '0px',
    right: 'auto',
    bottom: 'auto',
  },
  topRight: {
    top: '30px',
    left: 'auto',
    right: '0px',
    bottom: 'auto',
  },
  bottomLeft: {
    top: 'auto',
    left: '0px',
    right: 'auto',
    bottom: '30px',
  },
  bottomMoreLeft: {
    top: 'auto',
    left: '60px',
    right: 'auto',
    bottom: '30px',
  },
  bottomRight: {
    top: 'auto',
    left: 'auto',
    right: '0px',
    bottom: '30px',
  },
  centerRight: {
    top: '-114px',
    left: '35px',
    bottom: 'auto',
    right: 'auto',
  },
  centerLeft: {
    top: '-114px',
    left: 'auto',
    bottom: 'auto',
    right: '35px',
  },
};

const SurfacePaperFloating = ({
  elevation,
  square,
  variant,
  children,
  onOutsideClick,
  disabled,
  customStyle,
  size,
  position,
  // width,
  // height,
  // top,
  // left,
  // right,
  // bottom,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const sizeStyles = {
    xs: {
      width: '100px',
    },
    sm: {
      width: '140px',
    },
    md: {
      width: '220px',
    },
    mdWider: {
      width: '280px',
    },
    lg: {
      width: '320px',
    },
    lgWider: {
      width: '620px',
    },
    mlg: {
      width: isMobile ? '340px' : '520px',
      height: '300px',
    },
    xl: {
      width: '60vw',
      height: '60vh',
    },
  };
  return (
    <OutsideClickHandler
      onOutsideClick={onOutsideClick}
      disabled={disabled}
    >
      <Paper
        elevation={elevation}
        square={square}
        variant={variant}
        sx={{
          ...defaultStyles,
          ...sizeStyles[size],
          ...positionStyles[position],
          // top,
          // left,
          // right,
          // bottom,
          // width,
          // height,
          ...customStyle,
        }}
      >
        {children}
      </Paper>
    </OutsideClickHandler>
  );
};

SurfacePaperFloating.propTypes = {
  elevation: PropTypes.number,
  variant: PropTypes.oneOf(['outlined', 'elevation']),
  square: PropTypes.bool,
  children: PropTypes.node,
  onOutsideClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  customStyle: PropTypes.object,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  position: PropTypes.oneOf(['topRight', 'topLeft', 'bottomRight', 'bottomLeft', 'centerRight', 'centerLeft']),
  // width: PropTypes.string,
  // height: PropTypes.string,
  // top: PropTypes.string,
  // left: PropTypes.string,
  // right: PropTypes.string,
  // bottom: PropTypes.string,
};

SurfacePaperFloating.defaultProps = {
  elevation: 2,
  variant: 'elevation',
  square: false,
  children: null,
  disabled: false,
  customStyle: {},
  size: 'md',
  position: 'topRight',
  // width: '',
  // height: '',
  // top: '0px',
  // left: '0px',
  // right: 'auto',
  // bottom: 'auto',
};

export default memo(SurfacePaperFloating);
