import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import { FeedbackDialogAlert } from '../../../../../../components/UI_V2/Feedback/Dialog';
import handleLoadings from '../../../../../../utilities/handleLoadings';
import { deleteChecklistItemApi } from '../../../../../../actions/ChecklistItemActions';
import handleStatusMsg from '../../../../../../utilities/handleStatusMsg';

const DeleteChecklistItem = ({
  showDeleteChecklistItem,
  handleHideDeleteChecklistItem,
  cardId,
  checklistId,
  deletedChecklistItemId,
  companyId,
  teamId,
}) => {
  const [loadings, setLoadings] = useState([]);

  const handleDeleteChecklistItemApi = async () => {
    try {
      const startLoadings = handleLoadings('deleteChecklistItem', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await deleteChecklistItemApi({
        cardId,
        checklistId,
        checklistItemId: deletedChecklistItemId,
        companyId,
        teamId,
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      handleHideDeleteChecklistItem();
    } catch (error) {
      const status = handleStatusMsg(error, 'error');
      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      const endLoadings = handleLoadings('deleteChecklistItem', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  return (
    <FeedbackDialogAlert
      open={showDeleteChecklistItem}
      onClose={handleHideDeleteChecklistItem}
      handleConfirmNo={handleHideDeleteChecklistItem}
      handleConfirmYes={handleDeleteChecklistItemApi}
      variantButtonYes="danger"
      waitYes="deleteChecklistItem"
      loadings={loadings}
      labelYes="Hapus"
      labelNo="Batal"
      title="Hapus Sub Tugas ini?"
      content="Sub Tugas yang sudah dihapus tidak bisa dikembalikan lagi"
    />
  );
};

DeleteChecklistItem.propTypes = {
  showDeleteChecklistItem: PropTypes.bool.isRequired,
  handleHideDeleteChecklistItem: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  deletedChecklistItemId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  checklistId: PropTypes.string.isRequired,
};

DeleteChecklistItem.defaultProps = {};

export default memo(DeleteChecklistItem);
