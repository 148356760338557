import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import styles from './WithoutDueDateSection.module.css';
import Color from '../../../../../themes/colors';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';

const WithoutDueDateSection = ({
  handleShowDueDateSection,
}) => (
  <div className={styles.container}>
    <div className={styles.text}>
      <DisplayTextBody>
        Tanpa tanggal
      </DisplayTextBody>
    </div>
    <div onClick={handleShowDueDateSection} className={styles.button}>
      <AddIcon />
    </div>
  </div>
);

WithoutDueDateSection.propTypes = {
  handleShowDueDateSection: PropTypes.func.isRequired,
};

WithoutDueDateSection.defaultProps = {};

export default memo(WithoutDueDateSection);
