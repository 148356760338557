import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react'; // Import FullCalendar
import dayGridPlugin from '@fullcalendar/daygrid'; // Import dayGrid plugin
import interactionPlugin from '@fullcalendar/interaction';
import idLocale from '@fullcalendar/core/locales/id';
import styles from './CardCalendar.module.css';
import CardCalendarItem from './CardCalendarItem/CardCalendarItem';
import { DisplayTextBody } from '../UI_V2/Display/Text';
import './fullCalendarCustom.css';

function renderEventContent(eventInfo, urlVariant) {
  return (
    <CardCalendarItem event={eventInfo.event} urlVariant={urlVariant} />
  );
}

function renderDayCellContent(dateInfo) {
  return (
    <div className={styles.container} />
  );
}

const CardCalendar = ({
  listCards,
  handleCardDrop,
  handleDateClick,
  handleDatesSet,
  showWeekends,
  initialCalendarView,
  urlVariant,
}) => (
  <FullCalendar
    height="100%"
    locale={idLocale}
    plugins={[dayGridPlugin, interactionPlugin]} // Register dayGrid plugin
    initialView={initialCalendarView} // Set initial view to month grid
    headerToolbar={{
      left: 'prev,next,today',
      center: 'title',
      right: 'dayGridWeek,dayGridMonth', // user can switch between the two
    }}
    selectable
    editable
    datesSet={handleDatesSet}
    eventDrop={handleCardDrop}
    selectMirror
    weekends={showWeekends}
    events={listCards} // Pass events array
    eventDataTransform={(event) => ({
      title: event.name, // Specify "name" for title
      end: event.dueDate,
      start: event.startDate,
      date: event.dueDate,
      id: event._id,
      extendedProps: {
        ...event,
      },
    })}
    dayCellClassNames={styles.cursor}
    eventClassNames={styles.event}
    eventContent={(eventInfo) => renderEventContent(eventInfo, urlVariant)}
      // eventClick={handleEventClick} // Handle event click
    dateClick={handleDateClick}
    displayEventTime={false}
    // dayMaxEvents={3}
    dayCellContent={initialCalendarView === 'dayGridMonth' ? undefined : renderDayCellContent}
  />
);

CardCalendar.propTypes = {
  listCards: PropTypes.array,
  showWeekends: PropTypes.bool,
  handleCardDrop: PropTypes.func,
  handleDateClick: PropTypes.func,
  handleDatesSet: PropTypes.func,
  initialCalendarView: PropTypes.string,
  urlVariant: PropTypes.oneOf(['myTaskCalendarAll', 'boardCalendarAll']),
};

CardCalendar.defaultProps = {
  listCards: [],
  showWeekends: true,
  handleCardDrop: () => null,
  handleDateClick: () => null,
  handleDatesSet: () => null,
  initialCalendarView: 'dayGridMonth',
  urlVariant: 'myTaskCalendarAll',
};

export default CardCalendar;
