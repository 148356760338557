import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import styles from './CreateChecklistItem.module.css';
import Color from '../../../themes/colors';
import EditorFull from '../../FroalaEditor/EditorFull/EditorFull';
import { InputButtonMain } from '../../../components/UI_V2/Input/Button';
import { InputTextFieldMain } from '../../../components/UI_V2/Input/TextField';
import handleLoadings from '../../../utilities/handleLoadings';
import { validateTitle } from '../../../utilities/inputValidatorUtil';
import handleStatusMsg from '../../../utilities/handleStatusMsg';
import { createChecklistItemApi } from '../../../actions/ChecklistItemActions';

const CreateChecklistItem = ({
  cardId,
  checklistId,
  teamId,
  companyId,
  onClose,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');

  const [newChecklistItemName, setNewChecklistItemName] = useState('');
  const [loadings, setLoadings] = useState([]);

  const clearValue = () => {
    setNewChecklistItemName('');
  };

  const handleChanged = (event) => {
    const { value } = event.target;

    setNewChecklistItemName(() => (value));
  };

  const handleCreateChecklistItemApi = async () => {
    try {
      const startLoadings = handleLoadings('createChecklistItem', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const { isValid, errorMsg } = validateTitle(newChecklistItemName);

      if (!isValid) {
        enqueueSnackbar(errorMsg, {
          variant: 'error',
        });
      }

      const result = await createChecklistItemApi({
        cardId,
        checklistId,
        companyId,
        teamId,
        checklistItemName: newChecklistItemName,
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      clearValue();
      onClose();
    } catch (error) {
      const status = handleStatusMsg(error, 'error');
      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      const endLoadings = handleLoadings('createChecklistItem', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.form}>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="Judul sub tugas"
              name="name"
              onChange={(event) => handleChanged(event)}
              value={newChecklistItemName}
              autoFocus
              multiline
              minRows="3"
              maxRows="3"
              size="lg"
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerButton}>
          <InputButtonMain
            handleClick={handleCreateChecklistItemApi}
            wait="createChecklistItem"
            loadings={loadings}
            size="md"
            variant="success"
          >
            Buat
          </InputButtonMain>
        </div>
        <div className={styles.footerButton}>
          <InputButtonMain
            variant="light"
            handleClick={onClose}
            size="md"
          >
            Batal
          </InputButtonMain>
        </div>
      </div>
    </div>
  );
};

CreateChecklistItem.propTypes = {
  onClose: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  checklistId: PropTypes.string.isRequired,
};

CreateChecklistItem.defaultProps = {};

export default memo(CreateChecklistItem);
