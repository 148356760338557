import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ArchivedItem.module.css';
import TeamCard from '../../../../../pages/DashboardPage/FetchTeamsCards/Card/Card';
import RestoreButton from './RestoreButton/RestoreButton';
import Doc from '../../../../../pages/BucketPage/Doc/Doc';
import LinkNoDecor from '../../../../LinkNoDecor/LinkNoDecor';
import Folder from '../../../../../pages/BucketPage/Folder/Folder';
import File from '../../../../../pages/BucketPage/File/File';
import SmallCard from '../../../../../pages/ListContainer/SmallCard/SmallCard';
import { DisplayTextBody } from '../../../../UI_V2/Display/Text';

const ArchivedItem = ({
  featureType,
  item,
  companyId,
  teamId,
  index,
  unarchiveDataFunc,
  initiateListDataApi,
}) => {
  const isFeatureTeam = featureType === 'team';
  const isFeatureDocsFiles = featureType === 'bucket';
  const isFeatureCard = featureType === 'card';
  const isFeatureList = featureType === 'list';

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        {isFeatureTeam
            && (
            <TeamCard
              team={item}
              companyId={companyId}
              index={index}
            />
            )}

        {isFeatureCard && (
          <SmallCard
            name={item.name}
            desc={item.desc}
            labels={item.labels}
            members={item.members}
            dueDate={item.dueDate}
            startDate={item.startDate}
            comments={item.comments}
            attachments={item.attachments}
            archived={item.archived.status}
            complete={item.complete}
            allChecklistItemsCount={item.allChecklistItemsCount}
            isCardPublic={item?.isPublic}
            cardId={item?._id}
          />
        )}

        {isFeatureList && (
        <DisplayTextBody>
          {item.name}
        </DisplayTextBody>
        )}

        {isFeatureDocsFiles && item.type === 'doc'
            && (
            <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/docs/${item._id}`}>
              <Doc
                doc={item}
              />
            </LinkNoDecor>
            )}

        {isFeatureDocsFiles && item.type === 'bucket'
            && (
            <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/buckets/${item._id}`}>
              <Folder
                folder={item}
              />
            </LinkNoDecor>
            )}

        {isFeatureDocsFiles && item.type === 'file'
            && (
            <LinkNoDecor to={`/companies/${companyId}/teams/${teamId}/files/${item._id}`}>
              <File
                file={item}
              />
            </LinkNoDecor>
            )}
      </div>
      <div className={styles.restore}>
        <RestoreButton
          item={item}
          unarchiveDataFunc={unarchiveDataFunc}
          initiateListDataApi={initiateListDataApi}
        />
      </div>
    </div>
  );
};

ArchivedItem.propTypes = {
  featureType: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  unarchiveDataFunc: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  initiateListDataApi: PropTypes.func.isRequired,
};
export default memo(ArchivedItem);
