import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import styles from './EditChecklistName.module.css';
import Color from '../../../../../themes/colors';
import { InputButtonMain } from '../../../../../components/UI_V2/Input/Button';
import { InputTextFieldMain } from '../../../../../components/UI_V2/Input/TextField';
import handleLoadings from '../../../../../utilities/handleLoadings';
import { validateTitle } from '../../../../../utilities/inputValidatorUtil';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import { editChecklistNameApi } from '../../../../../actions/ChecklistActions';

const EditChecklistName = ({
  cardId,
  checklistId,
  teamId,
  companyId,
  onClose,
  initialChecklistName,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');

  const [newChecklistName, setNewChecklistName] = useState(initialChecklistName);
  const [loadings, setLoadings] = useState([]);

  const clearValue = () => {
    setNewChecklistName('');
  };

  const handleChanged = (event) => {
    const { value } = event.target;

    setNewChecklistName(() => (value));
  };

  const handleEditChecklistNameApi = async () => {
    try {
      const startLoadings = handleLoadings('editChecklistName', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const { isValid, errorMsg } = validateTitle(newChecklistName);

      if (!isValid) {
        enqueueSnackbar(errorMsg, {
          variant: 'error',
        });
      }

      const result = await editChecklistNameApi({
        cardId,
        checklistId,
        companyId,
        teamId,
        checklistName: newChecklistName,
      });

      const status = handleStatusMsg(result, 'success');

      enqueueSnackbar(status.message, {
        variant: 'success',
      });

      clearValue();
      onClose();
    } catch (error) {
      const status = handleStatusMsg(error, 'error');
      if (status?.message) {
        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      }
    } finally {
      const endLoadings = handleLoadings('editChecklistName', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.form}>
          <div className={styles.formArea}>
            <InputTextFieldMain
              placeholder="Judul ceklis"
              name="name"
              onChange={(event) => handleChanged(event)}
              value={newChecklistName}
              autoFocus
              size="md"
            />
          </div>
        </div>
        <div className={styles.action}>
          <div className={styles.actionButton}>
            <InputButtonMain
              handleClick={handleEditChecklistNameApi}
              wait="editChecklistName"
              loadings={loadings}
              size="sm"
              variant="success"
            >
              Simpan
            </InputButtonMain>
          </div>
          <div className={styles.actionButton}>
            <InputButtonMain
              variant="light"
              handleClick={onClose}
              size="sm"
            >
              Batal
            </InputButtonMain>
          </div>
        </div>
      </div>
    </div>
  );
};

EditChecklistName.propTypes = {
  onClose: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  checklistId: PropTypes.string.isRequired,
  initialChecklistName: PropTypes.string.isRequired,
};

EditChecklistName.defaultProps = {};

export default memo(EditChecklistName);
