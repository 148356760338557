import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';
import styles from './ListCardWidgetMyTaskCardAll.module.css';
import Color from '../../../../../themes/colors';
import SmallCard from '../../../../ListContainer/SmallCard/SmallCard';
import handleLoadings from '../../../../../utilities/handleLoadings';
import handleStatusMsg from '../../../../../utilities/handleStatusMsg';
import { MyTaskCardConstants, ReportConstants } from '../../../../../constants';
import { useInfinityScrollHooks } from '../../../../../hooks/InfinityScrollHooks';
import { FeedbackLoadingMain } from '../../../../../components/UI_V2/Feedback/Loading';
import InfinityScroll from '../../../../../components/UI/InfinityScroll/InfinityScroll';
import { handleCompareDate, isDateBeforeNow } from '../../../../../utilities/dateUtil';
import DateSeparator from '../../../../CommentsSectionContainer/DateSeparator/DateSeparator';
import ListItemSkeleton from '../ListItemSkeleton/ListItemSkeleton';
import { initiateReportListCard, loadMoreReportListCard } from '../../../../../actions/ReportActions';
import { MyTaskCardActions } from '../../../../../actions';
import { GlobalContext, initialState } from '../../../../../contexts/GlobalStateProvider';

const ListCardWidgetMyTaskCardAll = ({
  itemType,
}) => {
  const [{
    user,
    currentMyTaskCardListAll,
    previousMyTaskCardListAll,
    teams,
    currentCompany,
  }, dispatch] = useContext(GlobalContext);
  const isMobile = useMediaQuery('(max-width:720px)');

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    companyId,
    cardId,
  } = params;

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loadings, setLoadings] = useState([]);

  const isItemTypeComplete = itemType === ReportConstants.typeRef.completed;
  const isItemTypeOngoing = itemType === ReportConstants.typeRef.ongoing;
  const isItemTypeOverdue = itemType === ReportConstants.typeRef.overdue;

  const today = new Date();

  const initialFilterTeams = teams.map((team) => team._id);
  const initialFilterSubscribers = [user?._id];
  const initialFilterLabels = [];
  const initialFilterTitle = '';
  const initialFilterDueDate = itemType;

  const filters = {
    filterTeams: initialFilterTeams,
    filterSubscribers: initialFilterSubscribers,
    filterLabels: initialFilterLabels,
    filterTitle: initialFilterTitle,
    filterDueDate: initialFilterDueDate,
  };

  const { enqueueSnackbar } = useSnackbar();

  const getMoreLists = async () => {
    const result = await MyTaskCardActions.loadMoreMyTaskCardListAll({
      companyId,
      currentMyTaskCardListAll,
      filters,
    }, dispatch);

    return result;
  };

  const resetPreviousLists = useCallback(() => {
    MyTaskCardActions.setPreviousMyTaskCardListAll({
      previousMyTaskCardListAll: initialState.previousMyTaskCardListAll,
    }, dispatch);
    MyTaskCardActions.setCurrentMyTaskCardListAll({
      currentMyTaskCardListAll: initialState.currentMyTaskCardListAll,
    }, dispatch);
  }, []);

  const {
    lists,
    handleLoadMoreLists,
    checkIfListNeedToLoad,
    setCheckLoadMore,
    isListsLengthAtTheLimitOrAbove,
  } = useInfinityScrollHooks({
    currentObjectWithKeyProperty: currentMyTaskCardListAll,
    previousLists: previousMyTaskCardListAll?.data,
    resetPreviousLists,
    getMoreLists,
    keyProperty: 'data',
    limitList: MyTaskCardConstants.limitMyTaskCardList,
    dontSorting: true,
  });

  const initiateMyTaskCardListByAllApi = async (filtersValue) => {
    try {
      const startLoadings = handleLoadings('initiateMyTaskCardListByAll', [...loadings], 'start');
      setLoadings([...startLoadings]);

      const result = await MyTaskCardActions.initiateMyTaskCardListAll({
        companyId,
        filters: filtersValue,
      }, dispatch);

      if (isListsLengthAtTheLimitOrAbove(result?.data?.data)) {
        setCheckLoadMore(true);
      } else {
        setCheckLoadMore(false);
      }

      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
    } catch (err) {
      const status = handleStatusMsg(err, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      const endLoadings = handleLoadings('initiateMyTaskCardListByAll', [...loadings], 'end');
      setLoadings([...endLoadings]);
    }
  };

  useEffect(() => {
    if (!user?._id) return;
    if (!companyId) return;
    initiateMyTaskCardListByAllApi(filters);
  }, [itemType]);
  return (
    <div className={styles.container}>
      <FeedbackLoadingMain
        loadingComponent={<ListItemSkeleton />}
        wait="initiateMyTaskCardListByAll"
        loadings={loadings}
      >
        <InfinityScroll
          scrollableTarget="listReportListCard"
          dataLength={lists?.length || 0}
          hasMore={checkIfListNeedToLoad()}
          next={handleLoadMoreLists}
          emptyMessage="Belum ada tugas di kategori ini..."
        >
          {lists.map((item, index) => {
            let isSameDate = handleCompareDate(lists, item, (index - 1), 'createdAt');
            let dateSeparator = item.createdAt;
            let isCardOngoingButOverdue = false;
            if (isItemTypeComplete) {
              isSameDate = handleCompareDate(lists, item, (index - 1), 'setAt', 'complete');
              dateSeparator = item.complete.setAt;
            }

            if (isItemTypeOngoing || isItemTypeOverdue) {
              isSameDate = handleCompareDate(lists, item, (index - 1), 'dueDate');
              dateSeparator = item.dueDate;
            }

            if (isItemTypeOngoing) {
              isCardOngoingButOverdue = isDateBeforeNow(item.dueDate, today);
            }

            if (!isSameDate) {
              return (
                <>
                  <DateSeparator date={dateSeparator} />
                  <div className={styles.card}>
                    <SmallCard
                      name={item.name}
                      desc={item.desc}
                      labels={item.labels}
                      members={item.members}
                      dueDate={item.dueDate}
                      startDate={item.startDate}
                      comments={item.comments}
                      attachments={item.attachments}
                      archived={item.archived.status}
                      allChecklistItemsCount={item.allChecklistItemsCount}
                      complete={item.complete}
                      isCardPublic={item?.isPublic}
                      cardId={item?._id}
                      teamId={item.team._id}
                      isCardBlur={item?.isForbidden}
                      isCardOngoingButOverdue={isCardOngoingButOverdue}
                      list={item.list}
                      team={item.team}
                      showListAndTeamName
                    />
                  </div>
                </>
              );
            }
            return (
              <div className={styles.card}>
                <SmallCard
                  name={item.name}
                  desc={item.desc}
                  labels={item.labels}
                  members={item.members}
                  dueDate={item.dueDate}
                  startDate={item.startDate}
                  comments={item.comments}
                  attachments={item.attachments}
                  archived={item.archived.status}
                  complete={item.complete}
                  allChecklistItemsCount={item.allChecklistItemsCount}
                  isCardPublic={item?.isPublic}
                  cardId={item?._id}
                  teamId={item.team._id}
                  isCardBlur={item?.isForbidden}
                  isCardOngoingButOverdue={isCardOngoingButOverdue}
                  list={item.list}
                  team={item.team}
                  showListAndTeamName
                />
              </div>
            );
          })}
        </InfinityScroll>
      </FeedbackLoadingMain>
    </div>
  );
};

ListCardWidgetMyTaskCardAll.propTypes = {
  itemType: PropTypes.string.isRequired,
};

ListCardWidgetMyTaskCardAll.defaultProps = {};

export default memo(ListCardWidgetMyTaskCardAll);
