import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TimePicker from '@mui/lab/TimePicker';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import styles from './ManageDueDateContainer.module.css';
import { InputButtonMain } from '../../components/UI_V2/Input/Button';
import SeparatorLine from '../../components/UI/SeparatorLine/SeparatorLine';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import { CardActions, ChecklistItemActions } from '../../actions';
import { NavigationMenuBasic } from '../../components/UI_V2/Navigation/Menu';
import { useDelayShowHideHandler } from '../../hooks/HelperHooks';
import { DisplayTextBody } from '../../components/UI_V2/Display/Text';
import Color from '../../themes/colors';
import { calculateMonthday, isDateBeforeNow } from '../../utilities/dateUtil';
import { FeedbackDialogGeneral } from '../../components/UI_V2/Feedback/Dialog';
import { limitChar } from '../../utilities/stringUtil';

const ManageDueDateContainer = ({
  cancel, card, checklistItem, type, position,
  open, modalVersion,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadings, setLoadings] = useState([]);
  const [isDatePickerOpen, handleSetDatePickerOpen,
    handleUnsetDatePickerOpen] = useDelayShowHideHandler();

  const isTypeCard = type === 'card';
  let initialDate;

  let item;
  if (isTypeCard) {
    item = card;
  } else {
    item = checklistItem;
  }
  if (item.dueDate !== undefined) {
    initialDate = new Date(item.dueDate);
  } else {
    initialDate = calculateMonthday(new Date(), 1, 'add');
  }

  let initialStartDate;
  if (item.startDate) {
    initialStartDate = new Date(item.startDate);
  }

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [postSelectedDate, setPostSelectedDate] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate);
  const [deleteDate, setDeleteDate] = useState(false);
  const [isStartChecked, setIsStartChecked] = useState(item.startDate && initialStartDate);

  const params = useParams();
  const { companyId } = params;

  useEffect(() => {
    if (postSelectedDate === undefined && deleteDate === false) {
      return;
    }

    const postApiDueDate = async () => {
      let data;
      let waitKey;
      if (deleteDate === true) {
        if (isTypeCard) {
          data = {
            $unset: { dueDate: '', startDate: '' },
            'isNotified.dueOneDay': false,
            'isNotified.dueOneHour': false,
            'isNotified.startOneDay': false,
            'isNotified.startOneHour': false,
          };
        } else {
          data = {
            unsetDueDate: true,
            unsetStartDate: true,
          };
        }
        waitKey = 'deleteDateItem';
      } else {
        const isoDate = postSelectedDate.dueDate.toISOString();
        data = { dueDate: isoDate, 'isNotified.dueOneDay': false, 'isNotified.dueOneHour': false };
        if (postSelectedDate.startDate) {
          const isStartDateHigher = isDateBeforeNow(
            postSelectedDate.dueDate, postSelectedDate.startDate,
          );
          if (isStartDateHigher) {
            enqueueSnackbar('start date cannot higher or the same than due date', {
              variant: 'error',
            });

            return;
          }
          const isoStartDate = postSelectedDate.startDate.toISOString();
          data = {
            ...data, startDate: isoStartDate, 'isNotified.startOneDay': false, 'isNotified.startOneHour': false,
          };
        } else if (isTypeCard && item.startDate) {
          data = {
            ...data, $unset: { startDate: '' },
          };
        } else if (!isTypeCard && item.startDate) {
          data = {
            ...data,
            unsetStartDate: true,
          };
        } else {
          data = {
            ...data,
          };
        }
        waitKey = 'changeDateItem';
      }

      try {
        const startLoadings = handleLoadings(waitKey, [...loadings], 'start');
        setLoadings([...startLoadings]);

        let result;

        if (isTypeCard) {
          const cardId = params.cardId || card._id;
          const teamId = params.teamId || card.team?._id || card.team;
          result = await CardActions.updateCard({
            teamId, cardId, body: data, companyId,
          });
        } else {
          result = await ChecklistItemActions.updateDateChecklistItemApi({
            teamId: item.team,
            cardId: item.card,
            payload: data,
            companyId,
            checklistItemId: item._id,
            checklistId: item.checklist,
          });
        }

        const status = handleStatusMsg(result, 'success');

        enqueueSnackbar(status.message, {
          variant: 'success',
        });

        cancel();
      } catch (err) {
        const status = handleStatusMsg(err, 'error');

        enqueueSnackbar(status.message, {
          variant: 'error',
        });
      } finally {
        const endLoadings = handleLoadings(waitKey, [...loadings], 'end');
        setLoadings([...endLoadings]);
        // close this container after finished
      }
    };
    postApiDueDate();
  }, [postSelectedDate, deleteDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleStartDateChecked = () => {
    if (isStartChecked) {
      setSelectedStartDate();
      setIsStartChecked(false);
    } else {
      setIsStartChecked(true);
      setSelectedStartDate(initialStartDate || new Date());
    }
  };

  const handlePostSelectedDate = () => {
    setPostSelectedDate({
      dueDate: selectedDate,
      startDate: selectedStartDate,
    });
  };

  const handleDeletedDate = () => {
    setDeleteDate(true);
  };

  return (
    <>
      {modalVersion && (
      <FeedbackDialogGeneral
        open={open}
        onClose={cancel}
        maxWidth="xs"
        disableCloseIcon
      >
        <div className={styles.containerModal}>
          <div className={styles.ManageDueDateContainer__Header}>
            <h1 className="mr-auto">
              {`Ubah Tanggal ${limitChar(item.name || item.title, 15)}`}
            </h1>
            {/* <CloseIcon onClick={cancel} /> */}
          </div>
          <SeparatorLine />
          <div className={styles.body}>
            <div className={styles.datePicker}>
              <div className={styles.checklistAndTitle}>
                <div className={styles.checklist} onClick={handleStartDateChecked}>
                  {isStartChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </div>
                <DisplayTextBody>Tanggal Mulai</DisplayTextBody>
              </div>
              {isStartChecked && (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  onOpen={handleSetDatePickerOpen}
                  onClose={handleUnsetDatePickerOpen}
                  inputFormat="yyyy/MM/dd"
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  renderInput={(pickerParams) => <TextField variant="standard" {...pickerParams} />}
                />
              </LocalizationProvider>
              )}
              {!isStartChecked && <DisplayTextBody mode="16" color={Color.gray5}>YYYY/MM/DD</DisplayTextBody>}
            </div>
            <div className={styles.datePicker}>
              <div className={styles.checklistAndTitle}>
                <div className={styles.checklist}>
                  <CheckBoxIcon />
                </div>
                <DisplayTextBody>Tenggat</DisplayTextBody>
              </div>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  onOpen={handleSetDatePickerOpen}
                  onClose={handleUnsetDatePickerOpen}
                  inputFormat="yyyy/MM/dd"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(pickerParams) => <TextField variant="standard" {...pickerParams} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className={styles.footer}>
            <InputButtonMain handleClick={handlePostSelectedDate} variant="success" wait="changeDateItem" loadings={loadings}>Simpan</InputButtonMain>
            <InputButtonMain handleClick={handleDeletedDate} variant="danger" wait="deleteDateItem" loadings={loadings}>Hapus</InputButtonMain>
          </div>
        </div>
      </FeedbackDialogGeneral>
      )}

      {!modalVersion && (
      <NavigationMenuBasic
        disabled={isDatePickerOpen}
        size="mdWider"
        onClose={cancel}
        position={position}
      >
        <div className={styles.container}>
          <div className={styles.ManageDueDateContainer__Header}>
            <h1 className="mr-auto">
              Ubah Tanggal
            </h1>
            {/* <CloseIcon onClick={cancel} /> */}
          </div>
          <SeparatorLine />
          <div className={styles.body}>
            <div className={styles.datePicker}>
              <div className={styles.checklistAndTitle}>
                <div className={styles.checklist} onClick={handleStartDateChecked}>
                  {isStartChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </div>
                <DisplayTextBody>Tanggal Mulai</DisplayTextBody>
              </div>
              {isStartChecked && (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  onOpen={handleSetDatePickerOpen}
                  onClose={handleUnsetDatePickerOpen}
                  inputFormat="yyyy/MM/dd"
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  renderInput={(pickerParams) => <TextField variant="standard" {...pickerParams} />}
                />
              </LocalizationProvider>
              )}
              {!isStartChecked && <DisplayTextBody mode="16" color={Color.gray5}>YYYY/MM/DD</DisplayTextBody>}
            </div>
            <div className={styles.datePicker}>
              <div className={styles.checklistAndTitle}>
                <div className={styles.checklist}>
                  <CheckBoxIcon />
                </div>
                <DisplayTextBody>Tenggat</DisplayTextBody>
              </div>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  onOpen={handleSetDatePickerOpen}
                  onClose={handleUnsetDatePickerOpen}
                  inputFormat="yyyy/MM/dd"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(pickerParams) => <TextField variant="standard" {...pickerParams} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className={styles.footer}>
            <InputButtonMain handleClick={handlePostSelectedDate} variant="success" wait="changeDateItem" loadings={loadings}>Simpan</InputButtonMain>
            <InputButtonMain handleClick={handleDeletedDate} variant="danger" wait="deleteDateItem" loadings={loadings}>Hapus</InputButtonMain>
          </div>
        </div>
      </NavigationMenuBasic>
      )}
    </>
  );
};

ManageDueDateContainer.propTypes = {
  card: PropTypes.object.isRequired,
  checklistItem: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  modalVersion: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['card', 'checklistItem']),
  position: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'centerRight', 'centerLeft']),
};

ManageDueDateContainer.defaultProps = {
  position: 'topRight',
  type: 'card',
};

export default ManageDueDateContainer;
