import React, {
  memo, useContext, useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import SortIcon from '@mui/icons-material/Sort';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import DescIcon from '../../../UI/DescIcon/DescIcon';
import CommentIcon from '../../../UI/CommentIcon/CommentIcon';
import AttachmentIcon from '../../../UI/AttachmentIcon/AttachmentIcon';
import PrivateIcon from '../../../UI/PrivateIcon/PrivateIcon';
import styles from './CardListItemName.module.css';
import Color from '../../../../themes/colors';
import { DisplayTextBody } from '../../../UI_V2/Display/Text';
import CardListColumnBox from '../../CardListColumnBox/CardListColumnBox';
import { limitChar } from '../../../../utilities/stringUtil';
import { useDelayShowHideHandler } from '../../../../hooks/HelperHooks';
import ModalLinkNoDecor from '../../../ModalLinkNoDecor/ModalLinkNoDecor';
import handleLoadings from '../../../../utilities/handleLoadings';
import { RoleActions } from '../../../../actions';
import { typeRef } from '../../../../constants/RoleConstants';
import { GlobalContext } from '../../../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../../../utilities/handleStatusMsg';
import ChecklistCountIcon from '../../../../pages/ListContainer/SmallCard/ChecklistCountIcon/ChecklistCountIcon';

const CardListItemName = ({
  item,
  widthPercentage,
  variantBorder,
  setSelected,
  triggerScrollIntoView,
  showEditMenu,
  handleShowEditMenu,
  handleHideEditMenu,
  featureType,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const [{ user, isSidebarOpen }, dispatch] = useContext(GlobalContext);
  const params = useParams();
  const { companyId } = params;
  const [loadings, setLoadings] = useState([]);
  const [isLoadingCurrentRoleUser, setIsLoadingCurrentRoleUser] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState();
  const handleShowEditIcon = () => {
    setShowEditIcon(true);
  };

  const handleHideEditIcon = () => {
    setShowEditIcon(false);
  };

  useEffect(() => {
    if (showEditMenu !== item._id) return;
    triggerScrollIntoView();
  }, [showEditMenu]);

  const checkShouldGetCurrentRoleUserOrNot = () => {
    setIsLoadingCurrentRoleUser(true);
  };

  const initiateCurrentRoleUser = async () => {
    try {
      const result = await RoleActions.getAndSetRoleUser(
        {
          companyId,
          userId: user?._id,
          teamId: item.team._id,
          featureType: typeRef.card,
          featureId: item._id,
        }, dispatch,
      );
    } catch (error) {
      const status = handleStatusMsg(error, 'error');

      enqueueSnackbar(status.message, {
        variant: 'error',
      });
    } finally {
      setIsLoadingCurrentRoleUser(false);
      handleShowEditMenu(item._id);
    }
  };

  const handleHideEditMenuWhenDifferentItemId = (itemId) => {
    if (itemId !== showEditMenu) {
      handleHideEditMenu();
    }
  };

  useEffect(() => {
    if (!isLoadingCurrentRoleUser) return;

    setSelected(item._id);

    initiateCurrentRoleUser();
  }, [isLoadingCurrentRoleUser]);

  const isMyTaskFeatureType = featureType === 'myTask';

  const getNameLimit = () => {
    if (isMobile) return 15;
    if (isSidebarOpen) return 105;
    return 130;
  };

  return (
    <CardListColumnBox
      variantBorder={variantBorder}
      widthPercentage={widthPercentage}
      variantCursor="pointer"
      onClick={showEditMenu === item._id ? () => null : () => setSelected(item._id)}
    >
      <div
        className={styles.container}
        onMouseEnter={handleShowEditIcon}
        onTouchStart={handleShowEditIcon}
        onMouseLeave={handleHideEditIcon}
      >
        <ModalLinkNoDecor to={
          isMyTaskFeatureType
            ? `/companies/${companyId}/my-tasks/list/all/teams/${item.team._id}/cards/${item._id}`
            : `/companies/${companyId}/teams/${item.team._id}/boards/${item.board}/list/all/cards/${item._id}`
          }
        >
          <div
            className={styles.link}
            onClick={() => handleHideEditMenuWhenDifferentItemId(item._id)}
          />
        </ModalLinkNoDecor>
        {!isLoadingCurrentRoleUser && (
        <div className={styles.titleAndIcon}>
          <PrivateIcon data={{ isPublic: item.isPublic }} size="small" />
          {' '}
          {' '}
          <DisplayTextBody
            decoration="bold"
          >
            {limitChar(item.name, getNameLimit())}
          </DisplayTextBody>
          <div className={styles.othersPropertiesSection}>
            <DescIcon desc={item.desc} />
            <CommentIcon comments={item.comments} />
            <AttachmentIcon attachments={item.attachments} />
            <ChecklistCountIcon
              completed={item.allChecklistItemsCount?.completed}
              total={item.allChecklistItemsCount?.total}
            />
          </div>
        </div>
        )}
        {isLoadingCurrentRoleUser && <Skeleton width={80} />}
        {(showEditIcon || showEditMenu === item._id) && (
        <div
          className={styles.launchIcon}
          onClick={
          showEditMenu === item._id
            ? () => handleHideEditMenu() : checkShouldGetCurrentRoleUserOrNot
}
        >
          <EditIcon />
        </div>
        )}
      </div>
    </CardListColumnBox>
  );
};

CardListItemName.propTypes = {
  item: PropTypes.object,
  widthPercentage: PropTypes.number,
  variantBorder: PropTypes.string.isRequired,
  setSelected: PropTypes.func,
  triggerScrollIntoView: PropTypes.func,
  showEditMenu: PropTypes.string.isRequired,
  handleShowEditMenu: PropTypes.func.isRequired,
  handleHideEditMenu: PropTypes.func.isRequired,
  featureType: PropTypes.oneOf(['board', 'myTask']),
};

CardListItemName.defaultProps = {
  item: {},
  widthPercentage: 100,
  setSelected: () => null,
  triggerScrollIntoView: () => null,
  featureType: 'myTask',
};

export default memo(CardListItemName);
