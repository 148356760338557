/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, memo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import styles from './Box.module.css';
import CustomTimeAgo from '../../../../UI/TimeAgo/TimeAgo';
import Color from '../../../../../themes/colors';
import { DisplayTextBody } from '../../Text';

// TimeAgo.addDefaultLocale(en);

const DisplayDateBox = (
  {
    date,
    startDate,
    clicked,
    expand,
    icon,
    detail,
    relativeTime,
    dateStyle, // only can be used with relativeTime
    exactTime,
    enableAlsoExactTimeWhenYesterday, // only can be used with relativeTime
    changeColorDate,
    complete,
    customBackgroundColor,
    customTextColor,
  },
) => {
  const [dateFinal, setDateFinal] = useState();
  const [statusDate, setStatusDate] = useState('');

  // function utk mendapatkan kode warna,
  // sesuai status date apakah overdue atau soon
  const statusDateChecker = (inputDate) => {
    const compareDate = new Date(inputDate);
    compareDate.setHours(0, 0, 0, 0);

    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0); // set today last midnight

    let soonDate = new Date();
    soonDate.setHours(0, 0, 0, 0);
    soonDate = soonDate.setHours(soonDate.getHours() + 72); // set next 3 days

    // jika item complete done kirim warna hijau + icon putih check
    if (complete && complete.status && complete.type === 'done') {
      return Color.tosca;
    }

    if (complete && complete.status && complete.type === 'blocked') {
      return Color.tosca;
    }
    // jika item complete blocked kirim warna hijau + icon merah cancel

    // jika soon maka kirim warna kuning
    if (compareDate >= todayDate && compareDate <= soonDate) {
      return Color.yellowAccentCicle;
    // jika overdue maka kirim warna merah
    } if (compareDate < todayDate) {
      return Color.deleteColor;
    }
    return '';
  };

  useEffect(() => {
    const formatDateString = (valueDate) => {
      if (valueDate === undefined) {
        return undefined;
      }
      const options = {
        weekday: 'long', // Full weekday name (Senin)
        year: 'numeric', // Numeric year (2024)
        month: 'short', // Abbreviated month name (Feb)
        day: 'numeric', // Day of the month (7)
      };
      let dateString;
      dateString = new Date(valueDate);
      dateString = dateString.toLocaleString('id-ID', options);

      if (exactTime) {
        const baseDate = new Date(valueDate);
        const minute = baseDate.getMinutes();
        let hour = baseDate.getHours();
        const ampm = hour >= 12 ? 'PM' : 'AM';
        // eslint-disable-next-line no-mixed-operators
        hour = ((hour + 11) % 12 + 1);
        const prefixHour = hour >= 10 ? '' : '0';
        const prefixMinute = minute >= 10 ? '' : '0';

        dateString = `${prefixHour + hour}:${prefixMinute}${minute} ${ampm}`;
      } else if (detail) {
        dateString = dateString.split(' ').slice(0, 5).join(' ');
      } else {
        dateString = dateString.split(' ').slice(1, 3).join(' ');
      }

      return dateString;
    };

    const dateString = formatDateString(date);
    const startDateString = formatDateString(startDate);

    if (startDateString) {
      setDateFinal(`${startDateString} - ${dateString}`);
    } else {
      setDateFinal(dateString);
    }
    // jika ada props utk background color, maka gunakan background color
    if (customBackgroundColor) setStatusDate(customBackgroundColor);

    // jika ada props utk ganti warna berdasarkan status overdue/soon, maka eksekusi function ini,
    // agar mendapatkan kode warna yang sesuai
    if (changeColorDate) setStatusDate(statusDateChecker(date));
  }, [date, complete]);

  return (
    <>
      {dateFinal && changeColorDate
        ? (
          <div
            onClick={clicked}
            style={{ backgroundColor: statusDate }}
            className={expand ? styles.DateBox : `${styles.DateBox} ${styles.noClick}`}
          >
            {icon ? (
              <>
                {complete
                && complete.status
                && complete.type === 'done'
                  ? <CheckCircleIcon className={styles.whiteStyle} />
                  : (
                    <>
                      {complete
                            && complete.status
                            && complete.type === 'blocked' ? <CancelIcon className={styles.redStyle} /> : <AccessTimeIcon className={statusDate === Color.deleteColor || statusDate === Color.tosca ? styles.whiteStyle : null} /> }
                    </>
                  ) }
              </>
            ) : null}
            {relativeTime ? (
              <DisplayTextBody
                color={customTextColor}
                mode="11"
              >
                <CustomTimeAgo
                  date={date}
                  dateStyle={dateStyle}
                  withoutOn
                  enableAlsoExactTimeWhenYesterday={enableAlsoExactTimeWhenYesterday}
                />
              </DisplayTextBody>
            )
              : (
                <DisplayTextBody
                  color={statusDate === Color.deleteColor || statusDate === Color.tosca
                    ? Color.white
                    : Color.fontColor}
                >
                  {dateFinal}
                </DisplayTextBody>
              )}
            {expand ? <ExpandMoreIcon className={statusDate === Color.deleteColor || statusDate === Color.tosca ? `${styles.expandIcon} ${styles.whiteStyle}` : styles.expandIcon} /> : null}
          </div>
        )
        : (
          <div
            onClick={clicked}
            className={expand ? styles.DateBox : `${styles.DateBox} ${styles.noClick}`}
          >
            {icon ? <AccessTimeIcon /> : null}
            {relativeTime
              ? (
                <DisplayTextBody
                  color={customTextColor}
                  mode="11"
                >
                  <CustomTimeAgo
                    date={date}
                    dateStyle={dateStyle}
                    withoutOn
                    enableAlsoExactTimeWhenYesterday={enableAlsoExactTimeWhenYesterday}
                  />
                </DisplayTextBody>
              )
              : (
                <DisplayTextBody
                  color={customTextColor}
                  mode="11"
                >
                  {dateFinal}
                </DisplayTextBody>
              )}
            {expand ? <ExpandMoreIcon className={styles.expandIcon} /> : null}
          </div>
        )}
    </>
  );
};

DisplayDateBox.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  clicked: PropTypes.func.isRequired,
  expand: PropTypes.bool.isRequired,
  icon: PropTypes.bool.isRequired,
  detail: PropTypes.string.isRequired,
  relativeTime: PropTypes.bool.isRequired,
  dateStyle: PropTypes.string.isRequired, // only can be used with relativeTime
  exactTime: PropTypes.bool.isRequired,
  changeColorDate: PropTypes.bool.isRequired,
  complete: PropTypes.object.isRequired,
  customBackgroundColor: PropTypes.string,
  customTextColor: PropTypes.string,
  enableAlsoExactTimeWhenYesterday: PropTypes.bool,
};

DisplayDateBox.defaultProps = {
  customBackgroundColor: null,
  customTextColor: Color.fontColor,
  enableAlsoExactTimeWhenYesterday: false,
};

export default memo(DisplayDateBox);
